import React from 'react';
import {css} from '@emotion/css';
const watermark = require('../../content/images/mark.svg');

const containerStyle = css`
width: 100%;
height: auto;
background: #0c6085;
color: white;
background: -moz-linear-gradient(45deg, #0c6085 0%, #0074a6 50%, #249fd4 100%);
background: -webkit-linear-gradient(45deg, #0c6085 0%,#0074a6 50%,#249fd4 100%);
background: linear-gradient(45deg, #0c6085 0%,#0074a6 50%,#249fd4 100%);
padding: 32px 100px;

.watermark {
    position: absolute;
    right: 64px;
    top: 200px;
}
label {
    color: white;
}

p {
    color: white;
    font-family: Muli;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 32px 0;
}

h2 {
    color: white;
    font-family: Muli;
    font-size: 32px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 32px 0;
}
`
const wrapperStyle = css`
width: 50%;
height: auto;
`
const FrontContainer: React.SFC = (props) => (
    <div className={containerStyle}>
        <div className={wrapperStyle}>
          {props.children}
        </div>
          <img className="watermark" src={watermark}/>
    </div>
)

export default FrontContainer;