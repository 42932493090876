import { withFormik } from "formik";
import * as yup from 'yup';
import SparkLocationsForm, { ManageSparkLocationsFormData, ManageSparkLocationsFormProps } from "./SparkLocationsForm";
import { createEmptyApplication, toggleAllowsTemplating, updateApplication } from "../../../../../shared/api/controllers/applications";
import { addAppliedApplicationTemplate, getAppliedApplicationTemplatesForApp, updateAppliedApplicationTemplate } from "../../../../../shared/api/controllers/appliedApplicationTemplates";
import UpdateAppliedApplicationTemplateFormRequest from "../../../../../shared/models/templating/api/UpdateAppliedApplicationTemplateFormRequest";
import { createTemplateConfiguration, getAvailableTemplateConfigurations, getOrganizationTemplateConfigurations, updateTemplateConfiguration } from "../../../../../shared/api/controllers/templateConfigurations";
import TemplateConfigurationModel from "../../../../../shared/models/templating/api/TemplateConfigurationModel";
import TemplateFormSectionModel from "../../../../../shared/models/templating/api/TemplateFormSectionModel";
import { v4 as uuidv4 } from 'uuid';
import { getAIAnsweringModuleId } from "../../../../../shared/constants/ModuleConsts";
import { queueUpdateApplicationFromTemplateFormsSync } from "../../../../../shared/api/controllers/appliedTemplateSync";
import TemplateFormFieldValueModel from "../../../../../shared/models/templating/api/TemplateFormFieldValueModel";
import { AIAnsweringFeatureFlagId } from "../../../../../shared/constants/featureFlagIds";
import UpdateApplicationRequest from "../../../../../shared/models/applications/api/UpdateApplicationRequest";
import { aiAnsweringCommonAppName, aiAnsweringCustomModuleName } from "../../../../../shared/constants/sparkConstants/moduleConsts";

const ManageSparkLocationsForm = withFormik<ManageSparkLocationsFormProps, ManageSparkLocationsFormData>({
    mapPropsToValues: props => (
        {
            commonAppName: aiAnsweringCommonAppName,
            locationNames: [""],
            selectedFormFields: props.locationAppFormFields ? props.locationAppFormFields : [],
            deselectedFormFields: []
        }
    ),
    validationSchema: yup.object().shape(
        {
            locationNames: yup.array().of(yup.string().required('Location name is required'))
        }
    ),
    handleSubmit: async (values, { props }) => {

        props.setIsSubmittingForm(true);

        const aiAnsweringTemplateConfigId = getAIAnsweringModuleId();
        let commonAppId = "";
        let commonAppTemplateConfigId = "";

        const updateTemplateFormSection = (orgTemplateConfig: TemplateConfigurationModel, aiAnsweringTemplateConfig: TemplateConfigurationModel, values: ManageSparkLocationsFormData) => {

            const updatedSections: any[] = [];

            for (const commonAppSection of [...aiAnsweringTemplateConfig.templateForm.templateFormSections]) {

                if (commonAppSection.templateFormFields.some((field: any) => values.selectedFormFields.some((selectedField: any) => selectedField.id === field.id))) {

                    let newSection = orgTemplateConfig.templateForm.templateFormSections.find((templateSection: any) => templateSection.title === commonAppSection.title);

                    if (!newSection) {
                        newSection = {
                            ...commonAppSection,
                            templateFormFields: [],
                            templateFormId: orgTemplateConfig.templateForm.id,
                            id: uuidv4()
                        };
                    } else {
                        newSection =
                        {
                            ...commonAppSection,
                            templateFormFields: newSection.templateFormFields,
                            id: newSection.id,
                            templateFormId: newSection.templateFormId,
                        }
                    };

                    const updatedFields: any[] = [];

                    for (const commonAppField of commonAppSection.templateFormFields) {
                        if (values.selectedFormFields.some((selectedField: any) => selectedField.id === commonAppField.id)) {
                            let newField = newSection.templateFormFields.find((templateField: any) => templateField.title === commonAppField.title);
                            if (!newField) {
                                newField = {
                                    ...commonAppField,
                                    templateFormSectionId: newSection.id,
                                    id: uuidv4()
                                };
                            } else {
                                newField = {
                                    ...commonAppField,
                                    id: newField.id,
                                    templateFormSectionId: newSection.id,
                                }
                            }
                            updatedFields.push(newField);
                        }
                    };

                    newSection.templateFormFields = updatedFields;
                    updatedSections.push(newSection);

                }
            }

            return updatedSections;
        };

        const updateTemplateFormFieldValues = (formFieldValues: TemplateFormFieldValueModel[], commonAppSections: TemplateFormSectionModel[], isUpdate?: boolean) => {
            const templateValues = [];

            for (const section of commonAppSections) {

                section.templateFormFields.forEach((formField) => {

                    let formFieldValueModel = formFieldValues?.find(fv => fv.templateFormFieldId === formField.id);
                    let formFieldValue = formField.defaultValue;

                    if (formFieldValueModel) {
                        formFieldValue = formFieldValueModel.value;
                    };

                    if (values.selectedFormFields.some(sf => sf.id === formField.id) && formField.associatedVariable) {
                        formFieldValue = formField.associatedVariable;
                    };

                    if (isUpdate) {
                        if (values.deselectedFormFields.some(df => df.id === formField.id)) {
                            if (formField.defaultValue) {
                                formFieldValue = formField.defaultValue;
                            } else {
                                formFieldValue = undefined;
                            }
                        };
                    };

                    const fieldValue = {
                        value: formFieldValue,
                        fieldType: formField.fieldType,
                        tip: formField.tip,
                        title: formField.title,
                        label: formField.label,
                        defaultValue: formField.defaultValue,
                        associatedVariable: formField.associatedVariable,
                        placeholder: formField.placeholder,
                        templateFormFieldId: formField.id
                    };

                    templateValues.push(fieldValue);

                });
            }

            return templateValues;

        };

        const createCommonAppAndAppliedAppTemplate = async () => {

            // create common app
            const createCommonAppResponse = await createEmptyApplication(props.orgContainer.state.currentOrganization.id, {
                name: values.commonAppName,
                shortDescription: values.commonAppName,
                description: values.commonAppName,
                invocationPhrase: values.commonAppName
            });

            commonAppId = createCommonAppResponse.data.id

            // update common app to allow templating
            await toggleAllowsTemplating(createCommonAppResponse.data.id, true);

            // add applied app template referencing ai answering template configuration to common app
            const applyAIAnsweringTemplateToCommonAppResponse = await addAppliedApplicationTemplate(commonAppId, aiAnsweringTemplateConfigId);

            // update the common app applied application template with the latest template form field values
            const updatedCommonAppAppliedTemplateRequest: UpdateAppliedApplicationTemplateFormRequest = {
                templateFormFieldValues: updateTemplateFormFieldValues(applyAIAnsweringTemplateToCommonAppResponse.data.templateFormFieldValues, props.commonAppSections),
                dynamicFormSections: applyAIAnsweringTemplateToCommonAppResponse.data.dynamicFormSections,
                name: applyAIAnsweringTemplateToCommonAppResponse.data.name,
            };

            const commonAppAppliedAppId = applyAIAnsweringTemplateToCommonAppResponse.data.id;

            await updateAppliedApplicationTemplate(commonAppAppliedAppId, updatedCommonAppAppliedTemplateRequest);

            const updateApplicationRequest: UpdateApplicationRequest = {
                name: createCommonAppResponse.data.name,
                shortDescription: createCommonAppResponse.data.shortDescription,
                description: createCommonAppResponse.data.description,
                keywords: createCommonAppResponse.data.keywords,
                invocationPhrase: createCommonAppResponse.data.invocationPhrase,
                imageItemId: createCommonAppResponse.data.imageItemId,
                featureFlags: [{ id: AIAnsweringFeatureFlagId }]
            };

            await updateApplication(commonAppId, updateApplicationRequest);

            // sync common app template
            await getAppliedApplicationTemplatesForApp(commonAppId);
            await queueUpdateApplicationFromTemplateFormsSync(commonAppId, commonAppAppliedAppId, updatedCommonAppAppliedTemplateRequest);

        };

        const updateCommonAppAndAppliedAppTemplate = async () => {

            commonAppId = props.commonApp.id;

            // update the common app applied application template with the latest configuration
            const appliedAppTemplatesResponse = await getAppliedApplicationTemplatesForApp(commonAppId);
            const appliedApplicationTemplateIndex = appliedAppTemplatesResponse.data.findIndex(template => template.templateForm.templateConfiguration.id === aiAnsweringTemplateConfigId);
            const commonAppAppliedAppTemplate = appliedAppTemplatesResponse.data[appliedApplicationTemplateIndex]
            const commonAppAppliedAppTemplateId = commonAppAppliedAppTemplate.id;

            const updateAppliedAppTemplateRequest: UpdateAppliedApplicationTemplateFormRequest = {
                templateFormFieldValues: updateTemplateFormFieldValues(commonAppAppliedAppTemplate.templateFormFieldValues, props.commonAppSections, true),
                dynamicFormSections: appliedAppTemplatesResponse.data[appliedApplicationTemplateIndex].dynamicFormSections,
                name: appliedAppTemplatesResponse.data[appliedApplicationTemplateIndex].name,
            };

            await updateAppliedApplicationTemplate(commonAppAppliedAppTemplateId, updateAppliedAppTemplateRequest);
            // sync common app template
            await queueUpdateApplicationFromTemplateFormsSync(commonAppId, commonAppAppliedAppTemplateId, updateAppliedAppTemplateRequest);

        };

        const createCommonAppTemplateConfiguration = async () => {

            const commonAppTemplateFormId = uuidv4();

            const commonAppTemplateConfigurationRequest: TemplateConfigurationModel = {
                name: aiAnsweringCustomModuleName,
                description: `${props.orgContainer.state.currentOrganization.name} ${aiAnsweringCustomModuleName}`,
                organizationId: props.orgContainer.state.currentOrganization.id,
                templateApplicationId: commonAppId,
                finalizeFormUrl: null,
                templateForm: {
                    id: commonAppTemplateFormId,
                    templateFormSections: props.commonAppSections.filter(section => {
                        section.templateFormFields = section.templateFormFields.filter(field => {
                            return values.selectedFormFields.includes(field);
                        });
                        return section.templateFormFields.length > 0;
                    })
                },
                currentVersion: { "versionName": "1.0", "releaseNotes": "initial template" }
            };

            for (const commonAppTemplateFormSection of commonAppTemplateConfigurationRequest.templateForm.templateFormSections) {
                commonAppTemplateFormSection.templateFormId = commonAppTemplateFormId;
                commonAppTemplateFormSection.id = uuidv4();
                for (const commonAppTemplateFormField of commonAppTemplateFormSection.templateFormFields) {
                    commonAppTemplateFormField.id = uuidv4();
                    commonAppTemplateFormField.templateFormSectionId = commonAppTemplateFormSection.id;
                }
            }

            const createTemplateConfigurationResponse = await createTemplateConfiguration(commonAppTemplateConfigurationRequest);
            commonAppTemplateConfigId = createTemplateConfigurationResponse.data.id;

        };

        const updateCommonAppTemplateConfiguration = async () => {

            commonAppTemplateConfigId = orgTemplateConfig.id;

            const availableTemplateConfigsResponse = await getAvailableTemplateConfigurations(props.orgContainer.state.currentOrganization.id);

            const aiAnsweringTemplateConfig = availableTemplateConfigsResponse.data.find(templateConfig => templateConfig.id === aiAnsweringTemplateConfigId);

            const commonAppUpdatedTemplateForm = {
                ...orgTemplateConfig.templateForm,
                templateFormSections: updateTemplateFormSection(orgTemplateConfig, aiAnsweringTemplateConfig, values)
            }

            const commonAppUpdatedTemplateConfiguration: TemplateConfigurationModel = {
                ...orgTemplateConfig,
                templateForm: commonAppUpdatedTemplateForm
            }

            // update common app template configuration with latest form from ai answering template configuration
            await updateTemplateConfiguration(commonAppTemplateConfigId, commonAppUpdatedTemplateConfiguration);
        };

        const createLocationAppAndApplyAppTemplate = async (locationName: string) => {

            const getNewLocationAppName = () => {
                if (locationName.includes(props.orgContainer.state.currentOrganization.name)) {
                    return locationName;
                } else {
                    return `${props.orgContainer.state.currentOrganization.name} ${locationName}`;
                }
            };

            const createLocationAppResponse = await createEmptyApplication(props.orgContainer.state.currentOrganization.id, {
                name: `${getNewLocationAppName()}`,
                shortDescription: `${props.orgContainer.state.currentOrganization.name} ${locationName}`,
                description: `${props.orgContainer.state.currentOrganization.name} ${locationName}`,
                invocationPhrase: `${props.orgContainer.state.currentOrganization.name} ${locationName}`
            });

            const locationAppId = createLocationAppResponse.data.id;

            const updateApplicationRequest: UpdateApplicationRequest = {
                name: createLocationAppResponse.data.name,
                shortDescription: createLocationAppResponse.data.shortDescription,
                description: createLocationAppResponse.data.description,
                keywords: createLocationAppResponse.data.keywords,
                invocationPhrase: createLocationAppResponse.data.invocationPhrase,
                imageItemId: createLocationAppResponse.data.imageItemId,
                featureFlags: [{ id: AIAnsweringFeatureFlagId }]
            };

            await updateApplication(locationAppId, updateApplicationRequest);

            await addAppliedApplicationTemplate(locationAppId, commonAppTemplateConfigId);

        };

        // create or update common app
        if (!props.commonApp) {
            await createCommonAppAndAppliedAppTemplate();
        } else {
            await updateCommonAppAndAppliedAppTemplate();
        };

        const orgTemplateConfigsResponse = await getOrganizationTemplateConfigurations(props.orgContainer.state.currentOrganization.id);
        let orgTemplateConfig = orgTemplateConfigsResponse.data[0];
        if (orgTemplateConfigsResponse.data.length > 1) {
            orgTemplateConfig = orgTemplateConfigsResponse.data.find(templateConfig => templateConfig.name === aiAnsweringCustomModuleName);
        }

        if (!orgTemplateConfig) {
            await createCommonAppTemplateConfiguration()
        } else {
            await updateCommonAppTemplateConfiguration()
        };

        // create or update location apps
        for (const locationName of values.locationNames) {
            if (props.locationApps.some(app => app.name === locationName)) {
                // do nothing, assume template configuration is already applied
            } else {
                await createLocationAppAndApplyAppTemplate(locationName);
            }
        };

        props.setIsSubmittingForm(false);
        props.history.push("/");

    }
})(SparkLocationsForm);

export default ManageSparkLocationsForm;
