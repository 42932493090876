import React from 'react';
import { css } from '@emotion/css';
import MenuCollapseToggle from './MenuCollapseToggle';
import { silver_four, text_blue } from '../../constants/colors';
import SlideInSlideOutComponent from './SlideInSlideOutComponent';
const menuIcon = require('../../content/images/menu-dark.svg');

interface CollapsedMenuPanelProps {
    onExpand: (e) => void
    expandedViewRender: () => JSX.Element
}

interface CollapsedMenuPanelState {
    contentExpanded: boolean
}

class CollapsedMenuPanel extends React.Component<CollapsedMenuPanelProps, CollapsedMenuPanelState> {
    constructor(props) {
        super(props)
        this.state = {
            contentExpanded: false
        }
        this.handleMinimize = this.handleMinimize.bind(this);
    }
    handleExpand() {
        this.setState({
            ...this.state,
            contentExpanded: true
        }, this.addLeaveListener);
    }
    addLeaveListener() {
        // we need to wait till the panel is expanded to start listening for mouse leave. 
        setTimeout(
            () => {
                const element = document.getElementById("expanded-view");
                element.addEventListener("mouseleave", () => {
                    this.handleMinimize()
                })
            },
            300
        );
    }
    handleMinimize() {
        this.setState({
            ...this.state,
            contentExpanded: false
        });
    }
    render() {
        return (
            <div className={containerStyle}>
                {
                    !this.state.contentExpanded &&
                    <div className="header-container">
                        <MenuCollapseToggle isMinified={true} onToggle={this.props.onExpand} />
                    </div>
                }

                <div className="menu-container">
                    <div className="menu-toggle" onClick={this.handleExpand.bind(this)}>
                        <img src={menuIcon} />
                    </div>
                    <SlideInSlideOutComponent direction="right" showChildren={this.state.contentExpanded} styleName={"collapsedMenuSlide"}>
                        <div id="expanded-view" className="floating-tree-container">
                            {this.props.expandedViewRender()}
                        </div>
                    </SlideInSlideOutComponent>
                </div>

            </div>
        )
    }
}

const containerStyle = css`

    .header-container {
        position: relative;
        width:80px;
        display: flex;
        align-items: center;
    }
    .menu-toggle {
        width: 80px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
            width: 24px;
            height: 24px;
        }
    }
    .highlighted {
        .inner-toggle {
            background: ${text_blue};
        }
    }

    .floating-tree-container {
        width: 288px;
        position: absolute;
        top: 0;
        bottom: 0;
        background: white;
        border-right: 1px solid ${silver_four};

    }
`

export default CollapsedMenuPanel;