import { Container } from 'unstated';
import * as voicifyApi from '../../api';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import IResult from '../../models/result/IResult';
import ApplicationTemplateModel from '../../models/applications/api/ApplicationTemplateModel';
import CreateApplicationWithTemplatesModel from '../../models/applications/CreateApplicationWithTemplatesModel';
import NewModuleRequest from '../../models/features/api/Modules/NewModuleRequest';
import CopyApplicationTemplateRequest from '../../models/applications/api/CopyApplicationTemplateRequest';
import ApplicationFulfillmentEndpoints from '../../models/applications/api/ApplicationFulfillmentEndpoints';
import UpdateApplicationRequest from '../../models/applications/api/UpdateApplicationRequest';
import LanguageModel from '../../models/features/api/LanguageModel';
import _ from 'lodash';
import CreateApplicationFromCompletedFormsRequest from '../../models/templating/api/CreateApplicationFromCompletedFormsRequest';
import ApplicationWithMembers from '../../models/applications/api/ApplicationWithMembers';
import ApplicationCountsModel from '../../models/applications/api/ApplicationCountsModel';
import ApplicationListPreferencesModel from '../../models/applications/ApplicationListPreferencesModel';
import ApplicationsSearchResult from '../../models/applications/api/ApplicationsSearchResult';
import AssistantApplicationModel from '../../models/applications/api/AssistantApplicationModel';
import NewApplicationRequest from '../../models/applications/api/NewApplicationRequest';
import MediaItemModel from '../../models/media/MediaItemModel';

export type ApplicationSortOptions = "NameAscending" | "NameDescending" |"CreatedDateAscending" | "CreatedDateDescending";

type ApplicationsState = {
    cachedApplications: ApplicationModel[]
    orgApplicationsList: ApplicationWithMembers[]
    applicationListPreferences: ApplicationListPreferencesModel
    currentApp: ApplicationModel
    templates: ApplicationTemplateModel[] // TODO: remove - this is being deprecated
    conversationCounts: ApplicationCountsModel[]
    folderAndItemCounts: ApplicationCountsModel[]
    applicationEndpoints: ApplicationFulfillmentEndpoints[]
    isLoadingApps: boolean
    errors: string[]
    createErrors: string[]
    organizationId: string
    isLoadingTemplates: boolean
    isCreatingApplication: boolean
    isAddingModules: boolean
    isLoadingEndpoints: boolean
    isSaving: boolean
    isLoadingCurrentApp: boolean
    applicationsCount: number
    assistantApplication: AssistantApplicationModel
    featureFlags: FeatureFlagModel[]
}

export default class ApplicationContainer extends Container<ApplicationsState> {
    // default state
    state = this.getInitialState()

    getInitialState(): ApplicationsState {
        // check local storage for initial state
        var state = {
            orgApplicationsList: [] as ApplicationWithMembers[],
            cachedApplications: [] as ApplicationModel[],
            applicationsCount: null,
            applicationEndpoints: [] as ApplicationFulfillmentEndpoints[],
            currentApp: null as ApplicationModel,
            applicationListPreferences: null as ApplicationListPreferencesModel,
            isLoadingApps: false,
            errors: [],
            createErrors: [],
            templates: [],
            conversationCounts: [] as ApplicationCountsModel[],
            folderAndItemCounts: [] as ApplicationCountsModel[],
            organizationId: '',
            isLoadingTemplates: false,
            isCreatingApplication: false,
            isAddingModules: false,
            isLoadingEndpoints: false,
            isSaving: false,
            isLoadingCurrentApp: false,
            assistantApplication: null as AssistantApplicationModel,
            featureFlags: [] as FeatureFlagModel[]
        };
        const storedPreferences = localStorage.getItem("APP_LIST_PREFERENCES");
        if(storedPreferences != null)
            state.applicationListPreferences = JSON.parse(storedPreferences);
        
        return state;
    }
    
    updateApplicationListPreferences(preferences: ApplicationListPreferencesModel) {
        this.setState({
            ...this.state,
            applicationListPreferences: preferences    
        });
        localStorage.setItem("APP_LIST_PREFERENCES", JSON.stringify(preferences));
    }

    async setCurrentAppFromId(applicationId: string) {
        const app = this.state.cachedApplications?.find(a => a.id == applicationId);
        this.setState({
            ...this.state,
            currentApp: app
        });
    }

    updateWebhookInstancesForApplication(appId, webhookInstances) {
        const searchedApps = [... this.state.orgApplicationsList];
        const idx = searchedApps.findIndex(a => a.id == appId);
        const app = searchedApps[idx];
        app.webhookInstances = webhookInstances;
        searchedApps[idx] = app;

        this.setState({
            ...this.state,
            orgApplicationsList: searchedApps
        });
    }

    async loadFeatureFlags() {
        try {
            const result = await voicifyApi.getFeatureFlags();
            if(result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    featureFlags: result.data
                });
            }
        } catch (error) {
            this.setState({
                ...this.state,
                errors: [error.toString()]
            })
        }
    }

    async loadCurrentAppById(applicationId: string): Promise<IResult<ApplicationModel>> {
        try {
            while(this.state.isLoadingCurrentApp) {                
                await new Promise(r => setTimeout(r, 400));
            }
            await this.setState({
                ...this.state,
                isLoadingCurrentApp: true
            });
            let app = this.state.currentApp;
            if(app?.id != applicationId) // if we're switching apps then clear it out and force a reload
                app = null;

            if(app == null) // is it an initial load or are we switching apps?
                app = this.state.cachedApplications?.find(a => a?.id == applicationId);

            if(app == null) { // no app in cache lets load it
                var appResult = await voicifyApi.findApplication(applicationId);
                if(appResult.resultType == "Ok") {
                    app = appResult.data;
                    this.setState({
                        ...this.state,
                        cachedApplications: [...this.state.cachedApplications, { ...app }]
                    })
                } else {      
                    this.setState({
                        ...this.state,
                        errors: appResult.errors,
                        isLoadingCurrentApp: false,
                    })
                }
            }
            if(this.state?.assistantApplication?.id != applicationId || this.state?.assistantApplication == null) {
                const assistantAppResult = await voicifyApi.getAssistantApplication(app.id);
    
                if(assistantAppResult.resultType == "Ok")
                {
                    await this.setState({
                        ...this.state, 
                        assistantApplication: assistantAppResult.data
                    });
                }
                else if(assistantAppResult.resultType == "NotFound")
                {
                    await this.setState({
                        ...this.state, 
                        assistantApplication: null
                    })
                }
                else{
                    await this.setState({
                        ...this.state, 
                        errors: assistantAppResult.errors, 
                        isLoadingCurrentApp: false
                    })
                }
            }

            await this.setState({
                ...this.state,
                currentApp: app,
                isLoadingCurrentApp: false
            });
            return { 
                data: app,
                resultType: "Ok",
                errors: []
            }
        } catch (error) {            
            this.setState({
                ...this.state,
                errors: [error.toString()],
                isLoadingCurrentApp: false
            })
        }
    }

    copyApplication(applicationId: string) {
        this.setState({
            ...this.state,
            isLoadingApps: true
        });
        const promise = voicifyApi.copyApplication(this.state.organizationId, applicationId);
        promise.then(result => {
            if (result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    errors: [],
                    cachedApplications: [...this.state.cachedApplications, result.data],
                    isLoadingApps: false
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: result.errors,
                    isLoadingApps: false
                })
            }
        }).catch(error => {
            console.log(error);
        });
    }

    getConversationCounts(organizationId: string) {
        const promise = voicifyApi.getApplicationConversationCounts(organizationId);
        promise.then(result => {
            if (result.resultType == "Ok") {
                const counts = this.state.conversationCounts;
                result.data.forEach(count => {
                    const existingCount = counts.find(c => c.applicationId == count.applicationId);
                    if (existingCount == null) {
                        counts.push(count);
                    }
                    else {
                        counts[counts.indexOf(existingCount)] = count;
                    }
                });

                this.setState({
                    ...this.state,
                    conversationCounts: counts
                })
            }
        })
        return promise;
    }

    getFolderAndItemCountsForApplications(organizationId: string, appIds: string[]) {
        if(appIds == null || appIds.length < 1)
            return;
        var promise = voicifyApi.getApplicationCounts(organizationId, appIds);
        promise.then(result => {
            if (result.resultType == "Ok") {
                var counts = this.state.folderAndItemCounts;
                result.data.forEach(count => {
                    var existingCount = counts.find(c => c.applicationId == count.applicationId);
                    if (existingCount == null) {
                        counts.push(count);
                    }
                    else {
                        counts[counts.indexOf(existingCount)] = count;
                    }
                });

                this.setState({
                    ...this.state,
                    conversationCounts: counts
                })
            }
        })
        return promise;
    }

    getFolderAndItemCount(applicationId: string): ApplicationCountsModel {
        var count = this.state.folderAndItemCounts.find(a => a.applicationId == applicationId);
        if (count === null || count === undefined) {
            return null;
        }
        return count;
    }

    getConversationCount(applicationId: string): number {
        var appCount = this.state.conversationCounts.find(a => a.applicationId == applicationId);
        if (appCount === null || appCount === undefined) {
            return null;
        }

        return appCount.conversationCount;
    }

    async getApplicationsForOrg(organizationId: string, forceRefresh?: boolean): Promise<IResult<ApplicationModel[]>> {
        try {
            if (this.state.organizationId == organizationId && this.state.cachedApplications?.length > 0 && !forceRefresh) {
                return new Promise<IResult<ApplicationModel[]>>((resolve, reject) => {
                    resolve({
                        data: _.sortBy(this.state.cachedApplications, a => a.name),
                        errors: [],
                        resultType: "Ok"
                    });
                });
            }

            if(organizationId != this.state.organizationId) {
                await this.setState({
                    ...this.state,
                    cachedApplications: []
                });
            }
            await this.setState({
                ...this.state,
                isLoadingApps: true,
                organizationId: organizationId
            });
            var applicationsResult = await voicifyApi.searchForApplications(organizationId, 1, 30, "CreatedDateDescending", "");
            if (applicationsResult.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    errors: [],
                    cachedApplications: this.state.cachedApplications.map(a =>applicationsResult.data.applications.some(ra => ra?.id == a?.id) ? applicationsResult.data.applications.find(ra => ra?.id == a?.id) : a),
                    applicationsCount: applicationsResult.data.total,
                    isLoadingApps: false
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: applicationsResult.errors,
                    isLoadingApps: false
                })
            }
            return {
                data: applicationsResult.data.applications,
                resultType: "Ok",
                errors: null
            };
        } catch (error) {
            this.setState({
                ...this.state,
                errors: [error.toString()],
                isLoadingApps: false
            })

        }        

    }

    async searchForApplications(organizationId: string, page: number = 1, take: number = 30, sortBy: ApplicationSortOptions = "CreatedDateDescending", search: string = "" ): Promise<IResult<ApplicationsSearchResult>> {
        try {
            if(organizationId != this.state.organizationId) {
                await this.setState({
                    ...this.state,
                    cachedApplications: []
                });
            }
            await this.setState({
                ...this.state,
                organizationId: organizationId
            });
            var applicationsResult = await voicifyApi.searchForApplications(organizationId, (page - 1) * take, take, sortBy, search);
            if (applicationsResult.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    errors: [],  
                    cachedApplications: this.state.cachedApplications.map(a => applicationsResult.data.applications.some(ra => ra?.id == a?.id) ? applicationsResult.data.applications.find(ra => ra?.id == a?.id) : a),
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: applicationsResult.errors,
                })
            }
            return applicationsResult;
        } catch (error) {
            this.setState({
                ...this.state,
                errors: [error.toString()],
                isLoadingApps: false
            })

        }        
    }

    async searchForOrgApplications(organizationId: string, page: number = 1, take: number = 30, sortBy: string = "CreatedDateDescending", search: string = "", loadAll: boolean = false): Promise<IResult<ApplicationsSearchResult>> {
        try {
            if(organizationId != this.state.organizationId) {
                await this.setState({
                    ...this.state,
                    cachedApplications: []
                });
            }
            await this.setState({
                ...this.state,
                isLoadingApps: true,
                organizationId: organizationId
            });
            let applicationsResult: IResult<ApplicationsSearchResult> = {
                resultType: "Ok",
                errors: [],
                data: {
                    applications: []
                }
            };
            if (loadAll) {
                let latestLoaded = take;
                while (latestLoaded === take) {
                    const newAppsResult = await voicifyApi.searchForApplications(organizationId, (page - 1) * take, take, sortBy, search);  
                    if(newAppsResult.resultType == "Ok") {
                        applicationsResult.data.applications.push(...newAppsResult.data.applications);
                        latestLoaded = newAppsResult.data.applications.length;
                    } else {
                        applicationsResult = newAppsResult;
                        break;
                    }
                    page = page + 1;
                }                
            } else {
                applicationsResult = await voicifyApi.searchForApplications(organizationId, (page - 1) * take, take, sortBy, search);
            }
            if (applicationsResult.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    errors: [],
                    orgApplicationsList: applicationsResult.data.applications,  
                    cachedApplications: this.state.cachedApplications.map(a =>applicationsResult.data.applications.some(ra => ra?.id == a?.id) ? applicationsResult.data.applications.find(ra => ra?.id == a?.id) : a),
                    applicationsCount: applicationsResult.data.total,
                    isLoadingApps: false
                })
            } else {
                this.setState({
                    ...this.state,
                    errors: applicationsResult.errors,
                    isLoadingApps: false
                })
            }
            return applicationsResult;
        } catch (error) {
            this.setState({
                ...this.state,
                errors: [error.toString()],
                isLoadingApps: false
            })

        }        
    }

    createApplication(organizationId: string, model: CreateApplicationWithTemplatesModel): Promise<IResult<ApplicationModel>> {
        this.setState({
            ...this.state,
            isCreatingApplication: true
        })
        return new Promise(async (resolve) => {
            try {
                var requestModel: NewApplicationRequest = {
                    name: model.name,
                    description: model.description,
                    shortDescription: model.shortDescription,
                    invocationPhrase: model.invocationPhrase,
                    keywords: model.keywords,
                    defaultLanguageId: model.defaultLanguageId
                }
                const promise = model.withSamples ? voicifyApi.createApplicationWithSamples(organizationId, requestModel) : voicifyApi.createEmptyApplication(organizationId, requestModel);
    
                const result = await promise;
                if (result.resultType != "Ok") {
                    this.setState({
                        ...this.state,
                        isCreatingApplication: false,
                        createErrors: result.errors
                    })
                    resolve(result);
                    return;
                }
    
                // successfully created, kick off image upload and set as well as template creation   
                if (model.imageFile) {
                    const urlResult = await voicifyApi.getUploadUrl(result.data.id, model.imageFile.name, model.imageFile.name);
                    if (urlResult.resultType == "Ok") {
                        // we have the url, upload to s3
                        const uploadResult = await voicifyApi.uploadDirect(urlResult.data, model.imageFile, () => { })
                        if (uploadResult.resultType == "Ok") {
                            // upload is good, let's add the media item
                            const createResult = await voicifyApi.createMediaItem(result.data.id, {
                                name: model.imageFile.name,
                                fileName: model.imageFile.name,
                                url: urlResult.data.split("?")[0],
                                caption: "Application Avatar"
                            })
                            if (createResult.resultType == "Ok") {
                                // media item creation is good, lets set it as the application image. 
                                await voicifyApi.setApplicationImage(result.data.id, createResult.data.id)
                            }                            
                        }                        
                    }                        
                }

                if (model.templates.length > 0) {
                    const templateResult = await voicifyApi.addTemplateContentToApplication(result.data.id, model.templates);
                    if (templateResult.resultType == "Ok") {
                        this.setState({
                            ...this.state,
                            isCreatingApplication: false
                        })
                        resolve(templateResult);
                    }
                    
                }
                else {
                    this.setState({
                        ...this.state,
                        isCreatingApplication: false
                    })
                    resolve(result);
                }
            }
            catch {            
                this.setState({
                    ...this.state,
                    isCreatingApplication: false
                })
            }                  
        })      
    }

    disableApplication(applicationId: string): Promise<IResult<ApplicationModel>> {
        this.setState({
            ...this.state,
            isLoadingApps: true
        })
        var promise = voicifyApi.disableApplication(applicationId);

        promise.then(result => {
            if (result.resultType != "Ok") {
                this.setState({
                    ...this.state,
                    isCreatingApplication: false,
                    errors: result.errors
                })
            } else {
                this.setState({
                    ...this.state,
                    isLoadingApps: false,
                    errors: []
                })

            }
        }).catch(error => {
            this.setState({
                ...this.state,
                isLoadingApps: false,
                errors: [error.toString()]
            })
        });
        return promise;
    }

    createApplicationWithTemplates(model: CreateApplicationFromCompletedFormsRequest, imageFile?: File): Promise<IResult<ApplicationModel>> {
        this.setState({
            ...this.state,
            isCreatingApplication: true
        })
        return new Promise((resolve) => {

            const promise = voicifyApi.createApplicationFromTemplateForms(model);

            promise.then(result => {
                if (result.resultType != "Ok") {
                    this.setState({
                        ...this.state,
                        isCreatingApplication: false,
                        createErrors: result.errors
                    })
                    resolve(result);
                    return;
                }

                // successfully created, kick off image upload and set as well as template creation
                if (imageFile) {
                    voicifyApi.getUploadUrl(result.data.id, imageFile.name, imageFile.name).then(urlResult => {
                        if (urlResult.resultType == "Ok") {
                            // we have the url, upload to s3
                            voicifyApi.uploadDirect(urlResult.data, imageFile, () => { }).then(uploadResult => {
                                if (uploadResult.resultType == "Ok") {
                                    // upload is good, let's add the media item
                                    voicifyApi.createMediaItem(result.data.id, {
                                        name: imageFile.name,
                                        fileName: imageFile.name,
                                        url: urlResult.data.split("?")[0],
                                        caption: "Application Avatar"
                                    }).then(createResult => {
                                        if (createResult.resultType == "Ok") {
                                            voicifyApi.setApplicationImage(result.data.id, createResult.data.id);
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
                else {
                    this.setState({
                        ...this.state,
                        errors: result.errors,
                        isCreatingApplication: false
                    })
                    resolve(result);
                }
            }).catch(e => {
                this.setState({
                    ...this.state,
                    isCreatingApplication: false,
                    errors: [e?.toString()]
                })

            })
        })
    }

    updateApplication(applicationId: string, model: UpdateApplicationRequest): Promise<IResult<ApplicationModel>> {
        this.setState({
            ...this.state,
            isCreatingApplication: true
        })
        const promise = voicifyApi.updateApplication(applicationId, model);

        promise.then(result => {
            if (result.resultType != "Ok") {
                this.setState({
                    ...this.state,
                    isCreatingApplication: false,
                    errors: result.errors
                })
            } else {
                // update the app locally
                const app = this.state.currentApp;
                if (app) {
                    app.name = result.data.name;
                    app.invocationPhrase = result.data.invocationPhrase;
                    app.description = result.data.description;
                    app.shortDescription = result.data.shortDescription;
                    app.keywords = result.data.keywords;
                    app.featureFlags = result.data.featureFlags;
                }

                this.setState({
                    ...this.state,
                    isCreatingApplication: false,
                    errors: [],
                    currentApp: app
                })

            }
        }).catch(error => {
            this.setState({
                ...this.state,
                isCreatingApplication: false,
                errors: [error.toString()]
            })
        });
        return promise;
    }
    async updateDetailedAnalytics(applicationId: string, enabled: boolean) {
        try {
            if (enabled)
                await voicifyApi.enableDetailedAnalytics(applicationId);
            else
                await voicifyApi.disableDetailedAnalytics(applicationId);
        } catch (error) {
            this.setState({
                ...this.state,
                errors: [error.toString()]
            })
        }
    }

    updateApplicationImage(applicationId: string, imageFile: File): Promise<IResult<MediaItemModel>> {
        const promise = voicifyApi.uploadMediaItem(applicationId, "applicationAvatar", imageFile);

        promise.then(imgResult => {
            if (imgResult.resultType == "Ok") {
                const imageItemId = imgResult.data.id;
                
                voicifyApi.setApplicationImage(applicationId, imageItemId).then(result => {
                    if (result.resultType != "Ok") {
                        this.setState({
                            ...this.state,
                            isCreatingApplication: false,
                            errors: result.errors
                        })
                    } else {
                        const imageUrl: string = result.data.imageUrl;
                        // update the app locally
                        let apps = [...this.state.cachedApplications];
                        var app = this.state.currentApp;
                        
                        if (app) {
                            app.imageItemId = imageItemId;
                            app.imageUrl = imageUrl;
                            apps[apps.findIndex(a => a.id == applicationId)] = app;
                        }

                        this.setState({
                            ...this.state,
                            isCreatingApplication: false,
                            errors: [],
                            cachedApplications: apps,
                            currentApp: app
                        })
                    }
                }).catch(error => {
                    this.setState({
                        ...this.state,
                        isCreatingApplication: false,
                        errors: [error.toString()]
                    })
                });
            } else {
                this.setState({
                    ...this.state,
                    isCreatingApplication: false,
                    errors: imgResult.errors
                })

            }
        }).catch(error => {
            this.setState({
                ...this.state,
                isCreatingApplication: false,
                errors: [error.toString()]
            })
        });
        return promise;
    }

    addModulesAndTemplatesToApplication(applicationId: string,
        templates: CopyApplicationTemplateRequest[],
        modules: NewModuleRequest[]): Promise<IResult<any>> {

        this.setState({
            ...this.state,
            isAddingModules: true
        })
        return new Promise((resolve, reject) => {

            if (templates.length == 0 && modules.length == 0) {
                resolve({
                    data: null,
                    resultType: "Ok",
                    errors: null
                })
                this.setState({
                    ...this.state,
                    isAddingModules: false
                })
            }

            // if we have both, chain them
            else if (templates.length > 0 && modules.length > 0) {
                voicifyApi.addTemplateContentToApplication(applicationId, templates).then(result => {
                    if (result.resultType != "Ok") {
                        this.setState({
                            ...this.state,
                            isAddingModules: false
                        })
                        reject(result.errors);
                    }
                    else {
                        voicifyApi.addModulesToApp(applicationId, modules).then(modulesResult => {
                            this.setState({
                                ...this.state,
                                isAddingModules: false
                            })
                            resolve(modulesResult);
                        });
                    }
                }).catch(error => {
                    this.setState({
                        ...this.state,
                        isAddingModules: false
                    })
                    resolve(error);
                })
            }
            else if (templates.length > 0) {
                voicifyApi.addTemplateContentToApplication(applicationId, templates).then(result => {
                    if (result.resultType != "Ok") {
                        this.setState({
                            ...this.state,
                            isAddingModules: false
                        })
                        reject(result.errors);
                    }
                    else {
                        resolve(result);
                    }
                }).catch(error => {
                    this.setState({
                        ...this.state,
                        isAddingModules: false
                    })
                    resolve(error);
                })
            }
            else {
                voicifyApi.addModulesToApp(applicationId, modules).then(result => {
                    if (result.resultType != "Ok") {
                        this.setState({
                            ...this.state,
                            isAddingModules: false
                        })
                        reject(result.errors);
                    }
                    else {
                        resolve(result);
                    }
                }).catch(error => {
                    this.setState({
                        ...this.state,
                        isAddingModules: false
                    })
                    resolve(error);
                })
            }
        })
    }

    getEndpoints(applicationId: string): Promise<IResult<ApplicationFulfillmentEndpoints>> {
        if (this.state.applicationEndpoints.some(a => a.applicationId == applicationId)) {
            return new Promise((resolve) => {
                resolve({
                    errors: [],
                    resultType: "Ok",
                    data: this.state.applicationEndpoints.find(a => a.applicationId == applicationId)
                });
            })
        }

        this.setState({
            ...this.state,
            isLoadingEndpoints: true
        })
        var promise = voicifyApi.getApplicationFulfillmentEndpoints(applicationId);
        promise.then(result => {
            if (result.resultType == "Ok") {
                const appEndpoints = this.state.applicationEndpoints;
                appEndpoints.push(result.data);
                this.setState({
                    ...this.state,
                    isLoadingEndpoints: false,
                    applicationEndpoints: appEndpoints
                });
            }
            else {
                this.setState({
                    ...this.state,
                    isLoadingEndpoints: false,
                    errors: result.errors
                });
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                isLoadingEndpoints: false,
                errors: [error.toString()]
            });
        })
        return promise;
    }

    updateApplicationLanguages(applicationId: string, languages: LanguageModel[]): Promise<IResult<ApplicationModel>> {
        const apps = [...this.state.cachedApplications];
        const app = this.state.currentApp;
        app.languages = languages;
        this.setState({
            ...this.state,
            cachedApplications: [...apps],
            currentApp: app
        })
        var promise = voicifyApi.updateApplicationLanguages(applicationId, languages.map(l => (l.id)));
        promise.then(result => {
            if (result.resultType != "Ok") {
                // revert local change
                this.setState({
                    ...this.state,
                    errors: result.errors
                })
            }
        }).catch(err => {
            this.setState({
                ...this.state,
                errors: [err.toString()]
            })
        })
        return promise;
    }

    updateApplicationDefaultLanguage(applicationId: string, languageId: string): Promise<IResult<ApplicationModel>> {
        const apps = [...this.state.cachedApplications];
        const app = this.state.currentApp;
        app.defaultLanguageId = languageId;
        this.setState({
            ...this.state,
            cachedApplications: apps,
            currentApp: app
        })
        var promise = voicifyApi.updateApplicationDefaultLanguage(applicationId, languageId);
        promise.then(result => {
            if (result.resultType != "Ok") {
                // revert local change
                this.setState({
                    ...this.state,
                    errors: result.errors
                })
            }
        }).catch(err => {
            this.setState({
                ...this.state,
                errors: [err.toString()]
            })
        })
        return promise;
    }


    toggleTemplating(applicationId: string, allowsTemplating: boolean): Promise<IResult<ApplicationModel>> {
        this.setState({
            ...this.state,
            isSaving: true
        });
        var promise = voicifyApi.toggleAllowsTemplating(applicationId, allowsTemplating);
        promise.then(result => {
            if (result.resultType != "Ok") {
                this.setState({
                    ...this.state,
                    errors: result.errors,
                    isSaving: false
                })
            } else {
                this.setState({
                    ...this.state,
                    isSaving: false
                })
            }
        }).catch(err => {
            this.setState({
                ...this.state,
                errors: [err.toString()],
                isSaving: false
            })
        })
        return promise;
    }

    private setLoading(isLoading: boolean) {
        this.setState({
            ...this.state,
            isLoadingApps: isLoading
        });
    }
    
    async clearErrors() {
        await this.setState({ ...this.state, errors: [] });
    }
}