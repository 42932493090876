import React from "react";
import { Subscribe } from 'unstated';
import AuthContainer from '../../state/containers/AuthContainer';
import LoginForm from './components/LoginForm';
import { Redirect, RouteComponentProps } from "react-router";
import FrontTabbedLayout from "../../components/structure/FrontTabbedLayout";
import { TabPanel } from "react-tabs";
import TabPanelBody from "../../components/structure/TabPanelBody";
import RegisterForm from "./components/RegisterForm";
import UserContainer from "../../state/containers/UserContainer";
import LandingContainer from "../../components/structure/LandingContainer";
import SingleSignOnForm from "./components/SingleSignOnForm";
import CreatePasswordForm from "./components/CreatePasswordForm";

interface LoginRouteProps {
    invitationCode?: string
}

interface LoginProps extends RouteComponentProps<LoginRouteProps> { }

const Login: React.FC<LoginProps> = (props) => {

    return (
        <LandingContainer>
            <Subscribe to={[AuthContainer, UserContainer]}>
                {(authContainer: AuthContainer, userContainer: UserContainer) => {
                    // if we are logged in, go to root
                    if (authContainer.state.isAuthenticated === true) {
                        return <Redirect to="/v" />
                    }
                    const enteringPassword = userContainer.state.userRegistrationInformation.enteringPassword;
                    return (
                        <FrontTabbedLayout tabs={["Login", "Login with SSO", "First time here?"]}>
                            <TabPanel>
                                <TabPanelBody>
                                    <LoginForm
                                        stateContainer={authContainer}
                                        userContainer={userContainer}
                                        invitationCode={props.match.params.invitationCode}
                                    />
                                </TabPanelBody>
                            </TabPanel>
                            <TabPanel>
                                <TabPanelBody>
                                    <SingleSignOnForm
                                        stateContainer={authContainer}
                                        userContainer={userContainer}
                                        invitationCode={props.match.params.invitationCode}
                                    />
                                </TabPanelBody>
                            </TabPanel>
                            <TabPanel>
                                <TabPanelBody>
                                    {
                                        enteringPassword ?
                                            <CreatePasswordForm
                                                stateContainer={userContainer}
                                                authContainer={authContainer}
                                                invitationCode={props.match.params.invitationCode}
                                            />
                                            :
                                            <RegisterForm
                                                stateContainer={userContainer}
                                                authContainer={authContainer}
                                                invitationCode={props.match.params.invitationCode}
                                            />
                                    }
                                </TabPanelBody>
                            </TabPanel>
                        </FrontTabbedLayout>
                    );
                }}
            </Subscribe>
        </LandingContainer>
    );

};

export default Login;