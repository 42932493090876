import React from 'react';
import { css } from '@emotion/css';
import { dark_grey_blue } from '../../constants/colors';

const containerStyle = css`
    margin-top: 24px;
    width: 100%;

`

interface FormFieldsProps {
    className?: string
}

const FormFieldsContainer: React.SFC<FormFieldsProps> = (props) => (
    <div className={containerStyle + " " + props.className}>
        {props.children}
    </div>
);

export default FormFieldsContainer;