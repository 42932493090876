import { useState } from 'react';
import Papa from 'papaparse';

const useCSVHandler = () => {
    const [importedData, setImportedData] = useState<any[]>([]);
    const [isLoadingImport, setIsLoadingImport] = useState(false);

    const csvToArray = (text: string): string[][] => {
        const parseResult = Papa.parse(text);
        return parseResult.data;
    };

    const importCSV = (file: File, processCSVData: (csvArray: string[][]) => any[]) => {

        const reader = new FileReader();
        reader.onloadend = () => {
            const csvText = reader.result?.toString();
            if (csvText) {
                const csvArray = csvToArray(csvText);
                const importedFields = processCSVData(csvArray);
                setImportedData(importedFields);
            }
        };

        reader.readAsText(file);
    };

    const convertToCSVFriendly = (text: string) => {
        if (!text?.length) return "";
        if (text.includes(',')) {
            // Wrap the entire text in double quotes to escape commas
            return `"${text.replace(/"/g, '""')}"`; // Escape any existing double quotes within the text
        }
        // If no commas, return the text as-is
        return text;
    };

    const exportCSV = (data: any[], headers: string[], filename: string) => {
        let csvContent = `${headers.map(header => convertToCSVFriendly(header)).join(',')}\n`;

        data.forEach(item => {
            if (item?.associatedVariable?.length && item?.associatedVariable === item?.value) {
                return; // don't want shared topics fields that are marked as location specific in export
            }

            const row = `${convertToCSVFriendly(item.title)},${convertToCSVFriendly(item.value)}\n`;
            csvContent += row;
        });

        // Add BOM to ensure correct encoding
        const bom = '\uFEFF';
        const blob = new Blob([bom + csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', `${filename}.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();

    };


    return {
        importedData,
        isLoadingImport,
        setIsLoadingImport,
        importCSV,
        exportCSV
    };
};

export default useCSVHandler;
