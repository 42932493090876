import React from 'react';
import { css } from '@emotion/css';
import { color_shades_darkest, color_shades_dark } from '../../constants/colors';
import ToggleIndicator from '../general/ToggleIndicator';

interface MenuCollapseToggleProps {
    isMinified: boolean
    className?: string
    onToggle: (e) => void
}

const MenuCollapseToggle: React.FC<MenuCollapseToggleProps> = (props) => (
    <div className={`${containerStyle} ${props.className ?? ''}`} onClick={props.onToggle}>
        <div className={`border-container ${props.isMinified ? 'collapsed-border' : 'expanded-border'}`}>
            <div className="inner-toggle ac-menu-toggle">
                <ToggleIndicator direction={'left'} />
                <div className="separator" />
            </div>
        </div>
    </div>
)

const containerStyle = css`
    cursor: pointer;
    position: absolute;
    right: 0; 
    top: 14px;
    z-index: 3;
    .toggle-indicator {
        border-color: ${color_shades_darkest};
    }

    .border-container {
        border: 1px solid ${color_shades_dark};
        height: 24px;
        width: 9px;
    }
    .collapsed-border {
        transform: scaleX(-1);
        margin-right: -9px;
        border-radius: 4px;
    }
    .expanded-border {
        border-radius: 4px;
    }

    .inner-toggle {
        background: #FFFFFF;
        box-sizing: content-box;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 0 4px 0 6px;
        border-radius: 4px;
        width: 12px;

        .separator {
            height: 16px;
            width: 1px;
            background: ${color_shades_dark};
            margin-right: 0;
            margin-left: auto;
        }

        .separator-left {
            height: 16px;
            width: 1px;
            background: ${color_shades_dark};
            margin-left: 0;
            margin-right: auto;
        }
    }
`

export default MenuCollapseToggle;