import React, { useState } from 'react';
import { css } from '@emotion/css';
import { color_shades_dark, color_shades_darkest, color_shades_lighter, color_shades_medium, dark_grey_blue, ocean_blue } from '../../../constants/colors';
import { OverflowOption } from './OverflowMenu';
import MenuSubItem from './MenuSubItem';
import ToggleIndicator from '../ToggleIndicator';
import ConditionalTooltipWrapper from '../ConditionalTooltipWrapper';

interface MenuItemProps {
    isDisabled?: boolean
    option: OverflowOption
    className?: string
    onClick?: (event: React.MouseEvent<HTMLElement>) => void
    onSubClick?: (label) => void
}

const MenuItem: React.FC<MenuItemProps> = (props) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (props.option.isDisabled)
            return;
        if (props.option.subOptions) {
            setIsExpanded(!isExpanded)
        } else {
            props.onClick(event);
        }
    }

    return (
        <ConditionalTooltipWrapper showTooltip={!!props.option.tooltip} text={props.option.tooltip} place="left">
            <div>
                <div className={`${menuItemStyle} ${props.option.isDestructive ? destructiveStyle : defaultStyle} ${props.option.isDisabled ? disabledStyle : ''} ${props.className}`} onClick={handleClick.bind(this)}>
                    {props.option.icon ? <img className="icon" src={props.option.icon} /> : null}
                    {props.option.hoverIcon ? <img className="icon-hover" src={props.option.hoverIcon} /> : null}
                    <p>
                        {props.option.label}
                    </p>
                    {props.option.subOptions && <ToggleIndicator direction={isExpanded ? 'down' : 'up'} className="toggle-indicator" />}
                </div>
                {props.option.subOptions && isExpanded && props.option.subOptions.map((c, i) => (<MenuSubItem key={i} option={c} onClick={() => props.onSubClick(c.label)} />))}
            </div>
        </ConditionalTooltipWrapper>
    )

}

const menuItemStyle = css`
    height: 64px;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    z-index: 99;
    color: ${dark_grey_blue};
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid transparent;
    position: relative;
    width: 100%;
    .toggle-indicator {
        margin-right: 16px;
        margin-left: auto;
    }
    p {
        color: ${color_shades_darkest};
        margin: 0 12px;
    }
    img {
        width: 24px;
        height: 24px;
        margin-left: 16px;
    }

    .icon-hover {
        display: none;
    }

    &:hover {
        .icon-hover {
            display: block;
        }
        .icon {
            display: none;
        }
    }
`

const destructiveStyle = css`
    &:hover {
        background-color: rgba(221, 64, 74, 0.1);
        border: solid 1px #eb5862;
    }
`;

const disabledStyle = css`
    background: ${color_shades_medium};
    cursor: not-allowed;
    border-color: ${color_shades_dark};
    background-color: ${color_shades_lighter};
    &:hover {
         border-color: ${color_shades_dark};
         background-color: ${color_shades_lighter};
    }
    p{
        color: ${color_shades_dark};
    }
`;

const defaultStyle = css`
    &:hover {
        background-color: #E5F1F6;
        border: 1px solid ${ocean_blue};
    }
`;
export default MenuItem;