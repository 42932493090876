import React from 'react';
import styled from '@emotion/styled';
import RadioGroup from '../../../../shared/components/forms/RadioGroup';
import { color_gradients_green_shift, color_shades_darker, color_shades_darkest } from '../../../../shared/constants/colors';
import { css } from '@emotion/css';
import { style_border_default } from '../../../../shared/constants/stylesValues';
import RadioCardGroupWithAudio from '../../../../shared/components/forms/RadioCardGroupWithAudio';
import { SparkTelephonyDetailsFormData, SparkTelephonyDetailsProps } from './SparkTelephonyDetailsForm';
import { FormikProps } from 'formik';
import Button from '../../../../shared/components/general/Button';
import { voiceOptions } from '../../../../shared/constants/sparkConstants/voiceOptions';
import TextField from '../../../../shared/components/forms/TextField';

const maleIcon = require('../../../../shared/content/images/spark/male.svg');

const SparkTelephonyDetails: React.FC<SparkTelephonyDetailsProps & FormikProps<SparkTelephonyDetailsFormData>> = (props) => {

    const customVoiceOption = { value: "custom", label: "Custom", icon: maleIcon, description: "Custom voice" };

    const voiceOptionsWithCustom = [...voiceOptions, customVoiceOption];

    return (
        <form onSubmit={props.handleSubmit}>
            {props.selectedTelephonyDeployment.virtualNumber &&
                <>
                    <RadioGroupLabel>Virtual Number</RadioGroupLabel>
                    <StyledTextField
                        name="virtualNumber"
                        value={props.values.virtualNumber}
                        onChange={(e) => props.setFieldValue("virtualNumber", e.target.value)}
                        placeholder="Update your virtual number here."
                        labelStyle={textFieldLabelStyle}
                    />
                </>
            }
            <RadioGroupLabel>Record Calls</RadioGroupLabel>
            <Description>Call recordings are available in session analytics.</Description>
            <RadioGroup
                checkContainerStyle={checkContainerStyle}
                selectedOptionStyle={selectedOptionStyle}
                onChange={(e) => props.setFieldValue("callRecordingEnabled", e.value)}
                value={props.values.callRecordingEnabled}
                options={[
                    { label: "Call Recording Enabled", value: true },
                    { label: "Call Recording Disabled", value: false },
                ]}
            />
            <RadioGroupLabel>Voice</RadioGroupLabel>
            <Description>Select your preferred voice from the list below.</Description>
            <VoiceCardsWrapper>
                <RadioCardGroupWithAudio
                    onChange={(e) => props.setFieldValue("ttsVoice", e.value)}
                    options={props.getVoice(props.selectedTelephonyDeployment.ttsVoice) === "custom" ? voiceOptionsWithCustom : voiceOptions}
                    value={props.values.ttsVoice}
                />
            </VoiceCardsWrapper>
            <Button
                disabled={!props.selectedTelephonyDeployment}
                loading={props.isLoadingTelephonyDeployments}
                themes={["primary"]}
                text="Save"
                type="submit"
            />
        </form>
    );
};

const textFieldLabelStyle = css`
    margin: 16px 0 0 0;
`;

const StyledTextField = styled(TextField)``;

const Description = styled.p`
    font-size: 14px;
    color: ${color_shades_darker};
    margin-top: 4px;
`;

const VoiceCardsWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;  
`;

const RadioGroupLabel = styled.p`
    font-weight: 400;
    font-size: 18px;
    color: ${color_shades_darkest};
    margin-top: 24px;
    &:first-of-type {
        margin-top: 0;
    }
`;

const checkContainerStyle = css`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ffffff;
    border: ${style_border_default};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const selectedOptionStyle = css`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-image: ${color_gradients_green_shift};
`;

export default SparkTelephonyDetails;

