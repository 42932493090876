import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { style_border_default } from '../../../../../shared/constants/stylesValues';
import AppliedApplicationTemplateContainer from '../../../../../shared/state/containers/AppliedApplicationTemplateContainer';
import { breakpoint_small } from '../../../../../shared/constants/breakpoints';
import { ApplicationModelWithPhoneNumberAndStatus, ApplicationType } from './ApplicationDashboard';
import ApplicationAnalyticsFacetContainer from '../../../../../shared/state/containers/ApplicationAnalyticsFacetContainer';
import ConditionalTooltipWrapper from '../../../../../shared/components/general/ConditionalTooltipWrapper';
import TooltipWrapper from '../../../../../shared/components/general/TooltipWrapper';
import { color_colors_decline, color_colors_growth, color_shades_light, dark_grey_blue } from '../../../../../shared/constants/colors';
import SparkDeploymentContainer from '../../../../../shared/hooks/SparkDeploymentContainer';
import { aiAnsweringCommonAppName } from '../../../../../shared/constants/sparkConstants/moduleConsts';

const assistantImage = require('../../../../../shared/content/images/assistant-borderless.svg');
const sharedImage = require('../../../../../shared/content/images/spark/shared.svg');
const formCompleteImage = require('../../../../../shared/content/images/task-checklist-check.svg');
const formIncompleteImage = require('../../../../../shared/content/images/task-checklist-remove.svg');
const editImage = require("../../../../../shared/content/images/custom-assistant-deployment/edit.svg");
const analyticsIcon = require('../../../../../shared/content/images/tab-icons/analytics-blue.svg');

interface ApplicationItemProps {
    app: ApplicationModelWithPhoneNumberAndStatus
    organizationId: string
    appliedAppTemplateContainer: AppliedApplicationTemplateContainer
    facetContainer: ApplicationAnalyticsFacetContainer
    history: any
    applicationType: ApplicationType
    tooltip?: string
    disabled?: boolean
}
export const formatPhoneNumber = (phoneNumber: string, virtual: boolean = false) => {
    if(virtual)
        return `${phoneNumber} (Virtual)`;  
    // Remove any non-digit characters from the input number
    const cleanedNumber = phoneNumber?.replace(/\D/g, '');
    const formattedNumber = `+${cleanedNumber?.substring(0, 1)} (${cleanedNumber?.substring(1, 4)}) ${cleanedNumber?.substring(4, 7)}-${cleanedNumber?.substring(7)}`;
    return formattedNumber;
};
const ApplicationItem: React.FC<ApplicationItemProps> = (props) => {

    const trainingContainer = SparkDeploymentContainer.useContainer();

    const renderTrainingState = () => {
        if (props.applicationType === "common") return null;
        switch (trainingContainer.getAppDeploymentState(props.app?.id)) {
            case "training":
                return (
                    <TrainingStatus className="publishing">Publishing</TrainingStatus>
                );
            case "trained":
                return (
                    <>
                        {
                            props.app.virtualNumber ? 
                                <PhoneNumberLink to={`/v/apps/${props.app.id}`}>
                                    <PhoneNumber>{`${props.app.virtualNumber} (Virtual)`}</PhoneNumber>
                                </PhoneNumberLink>
                                :                                
                            props.app.phoneNumber ?
                                <PhoneNumberLink to={`/v/apps/${props.app.id}`}>
                                    <PhoneNumber>{formatPhoneNumber(props.app.phoneNumber)}</PhoneNumber>
                                </PhoneNumberLink>
                                :
                                null
                        }
                    </>
                );
            case "error":
                return (
                    <TooltipWrapper text="Try publishing again or contact support.">
                        <TrainingStatus className="error">Publish Error</TrainingStatus>
                    </TooltipWrapper>
                );
            default:
                return null;
        }
    };

    return (
        <>
            {
                props.disabled || (trainingContainer.getAppDeploymentState(props.app?.id) === "training") ?
                    <TooltipWrapper text={props.disabled ? "You must fill out shared topics forms before you can edit location forms." : "Hang tight! You'll be able to edit once published."}>
                        <DisabledAppListItem>
                            <AppListItemSide>
                                <AssistantIcon src={props.applicationType === "common" ? sharedImage : assistantImage} />
                                <AssistantName>{props.applicationType === "common" ? aiAnsweringCommonAppName : props.app.name}</AssistantName>
                            </AppListItemSide>
                            <AppListItemSide>
                                {renderTrainingState()}
                                {
                                    props.app.hasIncompleteFormFields === true &&
                                    <ConditionalTooltipWrapper showTooltip={!!props.tooltip?.length} text={props.tooltip}>
                                        <FormIncompleteWrapper>
                                            <IncompleteText>Form Incomplete</IncompleteText>
                                            <ActionIcon src={formIncompleteImage} />
                                        </FormIncompleteWrapper>
                                    </ConditionalTooltipWrapper>
                                }
                                {
                                    props.app.hasIncompleteFormFields === false &&
                                    props.appliedAppTemplateContainer?.state?.appliedTemplates?.length > 0 &&
                                    <ConditionalTooltipWrapper showTooltip={!!props.tooltip?.length} text={props.tooltip}>
                                        <ActionIcon src={formCompleteImage} />
                                    </ConditionalTooltipWrapper>
                                }
                                {
                                    props.applicationType === "location" &&
                                    <ActionIcon src={analyticsIcon} />
                                }
                                <ActionIcon src={editImage} />
                            </AppListItemSide>
                        </DisabledAppListItem>
                    </TooltipWrapper>
                    :
                    <AppListItem>
                        <StyledLink to={`/v/apps/${props.app.id}`}>
                            <AppListItemSide>
                                <AssistantIcon src={props.applicationType === "common" ? sharedImage : assistantImage} />
                                <AssistantName>{props.applicationType === "common" ? aiAnsweringCommonAppName : props.app.name}</AssistantName>
                            </AppListItemSide>
                        </StyledLink>
                        <StyledLink to={`/v/apps/${props.app.id}`}>
                            <AppListItemSide>
                                {renderTrainingState()}
                                {
                                    props.app.hasIncompleteFormFields === true &&
                                    <ConditionalTooltipWrapper showTooltip={!!props.tooltip?.length} text={props.tooltip}>
                                        <Link to={`/v/apps/${props.app?.id}`}>
                                            <FormIncompleteWrapper>
                                                <IncompleteText>Form Incomplete</IncompleteText>
                                                <ActionIcon src={formIncompleteImage} />
                                            </FormIncompleteWrapper>
                                        </Link>
                                    </ConditionalTooltipWrapper>
                                }
                                {
                                    props.app.hasIncompleteFormFields === false &&
                                    props.appliedAppTemplateContainer?.state?.appliedTemplates?.length > 0 &&
                                    <ConditionalTooltipWrapper showTooltip={!!props.tooltip?.length} text={props.tooltip}>
                                        <Link to={`/v/apps/${props.app?.id}`}>
                                            <ActionIcon src={formCompleteImage} />
                                        </Link>
                                    </ConditionalTooltipWrapper>
                                }
                                {
                                    props.applicationType === "location" &&
                                    <Link to={`/v/apps/${props.app.id}/analytics`}>
                                        <ActionIcon src={analyticsIcon} />
                                    </Link>
                                }
                                <Link to={`/v/apps/${props.app?.id}`}>
                                    <ActionIcon src={editImage} />
                                </Link>
                            </AppListItemSide>
                        </StyledLink>
                    </AppListItem>

            }
        </>
    );

};

const AssistantName = styled.p`
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: ${dark_grey_blue};
    flex: 1;
`;

const PhoneNumberLink = styled(Link)`
    text-decoration: none;
    color: ${dark_grey_blue};
`;

const TrainingStatus = styled.div`
    font-size: 12px;
    font-weight: bold;
    margin-right: 24px;
    &.publishing {
        color: ${color_colors_growth};
        position: relative;
    }
    &.publishing:after {
        content: '';
        animation: dots 1.5s steps(1, end) infinite;
        position: absolute;
        margin-left: 2px;
    }
    &.published {
        color: ${color_colors_growth};
    }
    &.error {
        color: ${color_colors_decline};
    }

    @keyframes dots {
        0%, 20% {
            content: '.';
        }
        40% {
            content: '..';
        }
        60%, 80% {
            content: '...';
        }
        100% {
            content: '';
        }
    }
`;

const FormIncompleteWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const AppListItem = styled.div`
    text-decoration: none;
    color: ${dark_grey_blue};
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: ${style_border_default};
    border-top: none;
    border-radius: 8px;
    height: 56px;
    padding: 0 16px;
    ${breakpoint_small} {
        font-size: 12px;
    }
`;

const DisabledAppListItem = styled.div`
    text-decoration: none;
    color: ${dark_grey_blue};
    background-color: ${color_shades_light};
    cursor: not-allowed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: ${style_border_default};
    border-top: none;
    border-radius: 8px;
    height: 56px;
    padding: 0 16px;
    ${breakpoint_small} {
        font-size: 12px;
    }
`;

const AssistantIcon = styled.img`
    margin-right: 8px;
`;

const AppListItemSide = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const ActionIcon = styled.img`
    margin: 12px 24px 12px 12px;
    ${breakpoint_small} {
        margin: 8px;
        height: 18px;
        width: 18px;
    }
`;

const IncompleteText = styled.div`
    font-size: 10px;
    margin: 12px 0 12px 12px;
    color: ${color_colors_decline};
    ${breakpoint_small} {
        font-size: 8px;
        margin: 0px;
    }
`;

const PhoneNumber = styled.div`
    margin-right: 16px;
`;

export default ApplicationItem;