import React from 'react';
import {Tabs, Tab, TabPanel, TabList} from 'react-tabs';
import TabIndicator from './TabIndicator';
import {css} from '@emotion/css';
import { dark_sky_blue, ocean_blue, silver_two, dark_grey_blue_10, blue_white, dark_grey_blue } from '../../constants/colors';


interface TabbedLayoutProps {
    tabs: string[]
    top?: boolean
    selectedIndex?: number
    forceRenderTabPanel?: boolean
    onTabSelected?: (index: number, last: number) => void
}
const tabStripStyle = css`
    display: flex;
    justify-content: center;
    flex-direction: row;
    height: 80px;
    list-style: none;
    border-bottom: solid 1px ${silver_two};
`;

const tabStyle = css`
display: flex;
flex-direction: column;
width: 22%;
color: black;
text-align: center;
cursor: pointer;
span {
    flex-grow: 1;
    line-height: 80px;
    font-family: Muli;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0.5px;
    color: ${dark_grey_blue};
}
div {
    display: none;
    flex-grow: 0;
    flex-shrink: 0;
}
`;

const activeTabStyle = css`
color: ${ocean_blue};
div {
    display: block;
}
`;

const tablayoutStyle = css`
margin-top: 80px;
background: ${blue_white};
`

const topTabLayoutStyle = css`
    margin-top: 0;
    background: ${blue_white};
`

const indicatorGradient = `linear-gradient(267deg, ${dark_sky_blue}, ${ocean_blue});`;
const tabIndicatorStyle = css`
    margin: -2px auto 0 auto;
`
const TabbedLayout: React.FC<TabbedLayoutProps> = (props) => {

    function renderTab(name: string) {
        return (
            <Tab selectedClassName={activeTabStyle} className={`${tabStyle} ac-tab-${name}`} key={name}>
                <span>{name}</span>
                <TabIndicator color={indicatorGradient} className={tabIndicatorStyle}/>
            </Tab>
        );
    }
    return (
        <Tabs forceRenderTabPanel={props.forceRenderTabPanel} className={props.top ? topTabLayoutStyle : tablayoutStyle} defaultIndex={props.selectedIndex} onSelect={props.onTabSelected}>
            <TabList className={tabStripStyle}>
                {props.tabs.map(renderTab)}
            </TabList>
            {props.children}
        </Tabs>
    );
}

export default TabbedLayout;
