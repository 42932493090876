// contains constants for urls for voicify
// the base url is handled in the api calls from the env variable API_URL

export const getBaseUrl = (): string => {
    if (window.location.href.includes("localhost:3000")) {
        return "http://localhost:3000";
    } else if (window.location.href.includes("localhost:1234")) {
        return "http://localhost:1234";
    } else if (process.env.VOICIFY_ENVIRONMENT == "dev") {
        return "https://dev-app.voicify.com";
    } else if (process.env.VOICIFY_ENVIRONMENT == "staging") {
        return "https://dev-app.voicify.com";
    } else if (process.env.VOICIFY_ENVIRONMENT == "production") {
        return "https://app.voicify.com";
    }
    return null;
};

export const getSparkBaseUrl = (): string => {
    if (window.location.href.includes("localhost:4321")) {
        return "http://localhost:4321";
    } else if (window.location.href.includes("dev-spark")) {
        return "https://dev-spark.voicify.com";
    } else if (window.location.href.includes("spark.voicify.com")) {
        return "https://spark.voicify.com";
    }
    return null;
}

export const getBaseApiUrl = (): string => {
    if (process.env.VOICIFY_ENVIRONMENT == "dev") {
        return "http://localhost:5002/api";
    }
    else if (process.env.VOICIFY_ENVIRONMENT == "staging") {
        return "https://dev-cms.voicify.com/api";
    }
    else if (process.env.VOICIFY_ENVIRONMENT == "production") {
        return "https://cms.voicify.com/api";
    }

    return null;
};

export const getAnalyticsApiUrl = (): string => {
    if (process.env.VOICIFY_ENVIRONMENT == "dev") {
        return "http://localhost:5005/api";
    }
    else if (process.env.VOICIFY_ENVIRONMENT == "staging") {
        return "https://dev-analytics.voicify.com/api";
    }
    else if (process.env.VOICIFY_ENVIRONMENT == "production") {
        return "https://analytics.voicify.com/api";
    }
    return null;
};

export const getAnalyticsWebsocketApiUrl = (): string => {
    if (process.env.VOICIFY_ENVIRONMENT == "dev") {
        return "ws://localhost:5005/api";
    }
    else if (process.env.VOICIFY_ENVIRONMENT == "staging") {
        return "wss://dev-analytics.voicify.com/api";
    }
    else if (process.env.VOICIFY_ENVIRONMENT == "production") {
        return "wss://analytics.voicify.com/api";
    }
    return null;
};

export const getAssistantApiUrl = (): string => {
    if (process.env.VOICIFY_ENVIRONMENT == "dev") {
        return "http://localhost:5001/api";
    }
    else if (process.env.VOICIFY_ENVIRONMENT == "staging") {
        return "https://dev-assistant.voicify.com/api";
    }
    else if (process.env.VOICIFY_ENVIRONMENT == "production") {
        return "https://assistant.voicify.com/api";
    }
    return null;
};

export const getAssistantPreviewApiUrl = (): string => {
    if (process.env.VOICIFY_ENVIRONMENT == "dev") {
        return "http://localhost:5001";
    }
    else if (process.env.VOICIFY_ENVIRONMENT == "staging") {
        return "https://dev-assistant.voicify.com";
    }
    else if (process.env.VOICIFY_ENVIRONMENT == "production") {
        return "https://assistant.voicify.com";
    }
    return null;
};


export const getSupportAssistantBaseUrl = (): string => {
    return "https://assistant.voicify.com";
};

export const getIntegrationsApiUrl = (): string => {
    if (process.env.VOICIFY_ENVIRONMENT == "dev") {
        return "http://localhost:5003/api";
    }
    else if (process.env.VOICIFY_ENVIRONMENT == "staging") {
        return "https://dev-integrations.voicify.com/api";
    }
    else if (process.env.VOICIFY_ENVIRONMENT == "production") {
        return "https://integrations.voicify.com/api";
    }
    return null;
};

export const getNlpApiUrl = (): string => {
    if (process.env.VOICIFY_ENVIRONMENT == "dev") {
        return "http://localhost:5004/api";
    }
    else if (process.env.VOICIFY_ENVIRONMENT == "staging") {
        return "https://dev-nlp.voicify.com/api";
    }
    else if (process.env.VOICIFY_ENVIRONMENT == "production") {
        return "https://nlp.voicify.com/api";
    }
    return null;
};

export const SIGN_IN = '/Authentication';
export const SINGLE_SIGN_ON = '/SingleSignOn';
export const GET_ORGANIZATIONS = '/Organization';
export const GET_SUBSCRIPTIONS = '/Subscription';
export const GET_APPLICATIONS = '/Application';
export const GET_FEATURES = '/Feature';
export const GET_WELCOMEMESSAGES = '/WelcomeMessage';
export const ADD_WELCOMEMESSAGE = '/WelcomeMessage';
export const GET_EXITMESSAGES = '/ExitMessage';
export const ADD_EXITMESSAGES = '/ExitMessage';
export const GET_HELPMESSAGES = '/HelpMessage';
export const ADD_HELPMESSAGE = '/HelpMessage';
export const GET_LATESTMESSAGES = '/LatestMessage';
export const GET_QUESTIONANSWER = '/QuestionAnswer';
export const ADD_QUESTIONANSWER = '/QuestionAnswer';
export const GET_LATESTMESSAGES_FOR_MODULE = '/LatestMessage/module';
export const CREATE_LATESTMESSAGE = '/LatestMessage';
export const CREATE_USER = '/User';
export const ACCEPT_INVITATION = '/Invitation/accept';
export const RESET_PASSWORD = '/User/ResetPassword';
export const SET_PASSWORD = '/User/SetPassword';
export const GET_MEDIAITEMS = '/MediaItem';
export const GET_UPLOAD_URL = '/MediaItem';
export const GET_CURRENT_USER = '/User/me';
export const GET_USER_TERMS = '/User/terms';
export const GET_NOTIFICATIONS = '/VoicifyNotification';
export const GET_SYSTEM_FEATURES = '/Feature/BuiltIn';
export const UPDATE_USER_DETAILS = '/User/UpdateDetails';
export const UPDATE_USER_IMAGE = '/user/UpdateImage';
export const GET_MEDIARESPONSES = '/MediaResponse';
export const MEDIAVERIFICATION = '/MediaVerification';
export const COOKIE_TERMS_URL = `https://voicify.com/about-us/cookie-policy/`;
export const UPDATE_ENVIRONMENT = "/ApplicationEnvironment";
export const PLATFORM_ALEXA_SKILL = "/AlexaSkill";
export const PLATFORM_AZURE = "/AzureApplication";
export const PLATFORM_DIALOGFLOW = "/DialogflowAgent";
export const APPLICATION_NLP_ENTITY = "/ApplicationNlpEntity";
export const APPLICATION_MENU_ITEM = "/VoicifyMenuItem";
export const APPLICATION_TELEPHONY_DEPLOYMENT = "/TelephonyDeployment";
export const SETUP_ORG_FOR_FEATURE = "/Setup/setupOrgForFeature";
export const MANUAL_UPDATES = "/ManualUpdates";