import React from 'react';
import {Tabs, Tab, TabPanel, TabList} from 'react-tabs';
import TabIndicator from './TabIndicator';
import {css} from '@emotion/css';
import { dark_sky_blue, ocean_blue, silver_two, dark_grey_blue_10, cool_grey, pale_grey } from '../../constants/colors';
const watermark = require('../../content/images/mark.svg');


interface TabbedLayoutProps {
    tabs: string[]
}
const tabStripStyle = css`
    display: flex;
    flex-direction: row;
    height: 65px;
    list-style: none;
`;

const tabStyle = css`
display: flex;
flex-direction: column;
width: 22%;
color: ${cool_grey};
background: ${pale_grey};
text-align: center;
cursor: pointer;
justify-content: center;
span {
    font-family: Muli;
    font-size: 20px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
}
`;

const activeTabStyle = css`
color: white;
background: transparent;

`;

const tablayoutStyle = css`
background: #0c6085;
background: -moz-linear-gradient(45deg, #0c6085 0%, #0074a6 50%, #249fd4 100%);
background: -webkit-linear-gradient(45deg, #0c6085 0%,#0074a6 50%,#249fd4 100%);
background: linear-gradient(45deg, #0c6085 0%,#0074a6 50%,#249fd4 100%);
margin-bottom: 120px;
label {
    color: white;
}
.watermark {
    position: absolute;
    right: 64px;
    top: 264px;
}
.forgot-password {
    color: white;
    text-decoration: none;
    font-family: Muli;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    float: right;
    margin-bottom: 64px;
    &:hover {
        font-weight: 700;
    }
}
`
const tabSpacerStyle = css`
    flex-grow: 2;
    background: ${pale_grey};
`
const tabSpacerFront = css`
    width: 120px;
    background: ${pale_grey};
`
const tabBodyContainer = css`
    padding: 40px 120px 0 120px;
    width: 50%;
    div {
        height: auto;
    }
`

const FrontTabbedLayout: React.SFC<TabbedLayoutProps> = (props) => {

    function renderTab(name: string) {
        return (
            <Tab selectedClassName={activeTabStyle} className={tabStyle} key={name}>
                <span>{name}</span>
            </Tab>
        );
    }
    return (
        <Tabs className={tablayoutStyle}>
            <TabList className={tabStripStyle}>
                <div className={tabSpacerFront}></div>
                {props.tabs.map(renderTab)}
                <div className={tabSpacerStyle}></div>
            </TabList>
            <div className={tabBodyContainer}>
              {props.children}
            </div>
          <img className="watermark" src={watermark}/>
        </Tabs>
    );
}

export default FrontTabbedLayout;
