import React, { useEffect } from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import AuthContainer from "../../../../shared/state/containers/AuthContainer";
import { Subscribe } from "unstated";
import { css } from '@emotion/css';
import OrganizationContainer from "../../../../shared/state/containers/OrganizationContainer";
import Splash from "../../../../shared/scenes/splash";
import ApplicationContainer from "../../../../shared/state/containers/ApplicationContainer";
import OrganizationMemberInvite from "../../../../shared/scenes/organizationMemberInvite";
import UserContainer from "../../../../shared/state/containers/UserContainer";
import FeatureContainer from "../../../../shared/state/containers/FeatureContainer";
import BasicOrganizationDashboard from "../basicOrganizationDashboard";
import Logout from "../../../../shared/scenes/logout";
import User from "../../../../shared/models/user/User";
import ApplicationManager from "./ApplicationManager";
import Loader from "../../../../shared/components/general/Loader";
import ApplicationAnalyticsFacetContainer from "../../../../shared/state/containers/ApplicationAnalyticsFacetContainer";

const Dashboard: React.FC<RouteComponentProps> = (props) => (

    <Subscribe to={[AuthContainer,
        UserContainer,
        OrganizationContainer,
        ApplicationContainer,
        FeatureContainer,
        ApplicationAnalyticsFacetContainer
    ]}>
        {(auth: AuthContainer,
            userContainer: UserContainer,
            orgs: OrganizationContainer,
            appContainer: ApplicationContainer,
            featureContainer: FeatureContainer,
        ) => {
            // if we are logged in, go to root
            if (auth.state.isAuthenticated !== true || auth.state.expirationDate === undefined) {
                return <Redirect to="/login" />
            }
            // if we have no current org, go to orgs page
            if (orgs.state.currentOrganization === null || auth.state.expirationDate < new Date()) {
                return <Splash
                    authContainer={auth}
                    orgContainer={orgs}
                    appContainer={appContainer}
                    featureContainer={featureContainer} />
            }
            return <InnerDashboard
                authContainer={auth}
                userContainer={userContainer}
            />
        }
        }
    </Subscribe>
);

interface InnerDashboardProps {
    userContainer: UserContainer
    authContainer: AuthContainer
}

const InnerDashboard: React.FC<InnerDashboardProps> = ({ userContainer, authContainer }) => {

    useEffect(() => {
        if (authContainer.state.isAuthenticated && !authContainer.state.accessToken) {
            authContainer.refreshToken();
        }
    }, []);

    if (authContainer.state.accessToken == null) {
        return <Loader />
    };

    const currentUser = userContainer.state.currentUser as User;

    if (currentUser) {
        window.analytics.identify(currentUser.id, {
            name: `${currentUser.firstName} ${currentUser.lastName}`,
            email: currentUser.email
        });
    };

    return (
        <div className={wrapperStyle}>
            <div className={pageStyle}>
                <div className={containerStyle}>
                    <Route path="/v" exact component={BasicOrganizationDashboard} />
                    <Route path="/" exact component={BasicOrganizationDashboard} />
                    <Route path="/v/members" component={BasicOrganizationDashboard} />
                    <Route path="/v/members/invite" exact component={OrganizationMemberInvite} />
                    <Route path="/v/orgSettings" component={BasicOrganizationDashboard} />
                    <Route path="/v/orgSettings/applications" exact component={BasicOrganizationDashboard} />
                    <Route path="/v/apps/:applicationId" exact component={ApplicationManager} />
                    <Route path="/v/apps/:applicationId/analytics" component={ApplicationManager} />
                    <Route path="/v/apps/:applicationId/analytics/track" component={ApplicationManager} />
                    <Route path="/v/apps/:applicationId/analytics/users" component={ApplicationManager} />
                    <Route path="/v/apps/:applicationId/analytics/sessions" component={ApplicationManager} />
                    <Route path="/v/apps/:applicationId/analytics/misses" component={ApplicationManager} />
                    <Route path="/v/apps/:applicationId/analytics/live" component={ApplicationManager} />
                    <Route path="/v/apps/:applicationId/analytics/report" component={ApplicationManager} />
                    <Route path="/v/apps/:applicationId/analytics/topic-hits" component={ApplicationManager} />
                    <Route path="/v/apps/:applicationId/analytics/topic-misses" component={ApplicationManager} />
                    <Route path="/v/apps/:applicationId/voice" component={ApplicationManager} />
                </div>
            </div>
            <Route path="/v/logout" exact component={Logout} />
        </div>
    );
};

const containerStyle = css`
    display:flex;
    flex-direction:row;
    max-height: 100%;
    height: 100%;
`;

const pageStyle = css`
    position:fixed;
    width: 100%;
    height: 100%;
`;

const wrapperStyle = css`
    height: 100%;
`;

export default Dashboard;
