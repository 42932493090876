import React from 'react';
import { css } from '@emotion/css';
import { color_shades_dark, color_text_default, color_colors_decline, color_text_light, color_shades_darkest, color_colors_gold, color_colors_gold_background } from '../../constants/colors';
import TooltipWrapper from '../general/TooltipWrapper';
import styled from '@emotion/styled';

const alertIcon = require('../../content/images/alert-circle-yellow.svg');

interface FieldCardProps {
    required?: boolean
    tip?: string
    title?: string
    isRecentlyAddedModuleFormField?: boolean
}

const FormFieldCard: React.FC<FieldCardProps> = (props) => {
    return (
        <div className={containerStyle}>
            <div className="card-header-wrapper">
                <p className="card-title">{props.title}</p>
                {props.required && <TooltipWrapper text="Required field"><p className="required-indicator-label"><span className="required-indicator">*</span></p></TooltipWrapper>}
            </div>
            <div className="card-body-wrapper">
                <p className="card-tip">{props.tip}</p>
                {props.children}
                {props.isRecentlyAddedModuleFormField &&
                    <ErrorStyle>
                        <AlertIconStyle src={alertIcon} />
                        Please review this recently added or updated form field. Edit the the value or to accept the default, simply select the 'Save & Next' button above.
                    </ErrorStyle>
                }
            </div>
        </div>
    );
};

const ErrorStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: Muli, "sans-serif";
    font-weight: normal;
    color: ${color_shades_darkest};
    background-color: ${color_colors_gold_background};
    border: 1px solid ${color_colors_gold};
    border-radius: 8px;
    flex-grow: 1;
    padding: 8px 16px;
    margin: 8px 0;
    font-size: 14px;
`;

const AlertIconStyle = styled.img`
    margin-right: 8px;
`;

const containerStyle = css`
    background: white;
    border: 1px solid ${color_shades_dark};
    box-sizing: border-box;
    border-radius: 8px;
    padding: 32px;
    .card-header-wrapper {
        display: flex;
        align-items: center;
        color: ${color_text_default};
        padding-bottom: 16px;
        border-bottom: 1px solid ${color_shades_dark};
        .card-title {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
        }
        .required-indicator-label {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            margin-right: 0;
            margin-left: auto;
            .required-indicator {
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                color: ${color_colors_decline};
            }
        }
    }
    .card-tip {
        margin: 16px 0;
        color: ${color_text_light};
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
    }
`;

export default FormFieldCard;