import React from 'react';
import MultiFileSelector from '../forms/MultiFileSelector';
import Button from './Button';
import ModalTitle from './ModalTitle';
import PageError from './PageError';
import FooterBar from '../structure/FooterBar';
import HorizontalContainer from '../structure/HorizontalContainer';
import HorizontalSeparator from '../structure/HorizontalSeparator';
import ModalLayout from '../structure/ModalLayout';
import PageContainer from '../structure/PageContainer';
import FullScreenLayout from '../../scenes/fullScreenLayout';
import { color_text_default } from '../../constants/colors';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { style_border_default } from '../../constants/stylesValues';

const closeIcon = require('../../content/images/close_icon.svg');

interface ImporterProps {
    onClose: () => void;
    completeImport: (file) => void;
    title: string;
    acceptFileTypes: string;
    acceptFileTypesDisplayText: string;
    isLoading: boolean;
    errors: string[];
}
interface ImporterState {
    file: File;
}

class ImportFileDialog extends React.Component<ImporterProps, ImporterState> {

    constructor(props) {
        super(props)
        this.state = {
            file: null,
        }
    };

    handleFilesSelected(files: File[]) {
        this.setState({
            ...this.state,
            file: files[0]
        })
    };

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.file) {
            this.props.completeImport(this.state.file);
        }
    };

    render() {
        return (
            <ImportFileDialogStyled
                isVisible={true}
                onClose={() => this.props.onClose()}>
                <FullScreenLayout >
                    <PageContainer fill>
                        <TitleWrapper>
                            <ModalTitle>{this.props.title}</ModalTitle>
                            <ButtonWrapper>
                                <Button
                                    themes={['end-tight', 'icon']}
                                    icon={closeIcon}
                                    onClick={() => this.props.onClose()}
                                />
                            </ButtonWrapper>
                        </TitleWrapper>
                        <HorizontalSeparator />
                        <form onSubmit={this.handleSubmit.bind(this)}>

                            <div className="fileSelector">
                                <MultiFileSelector
                                    onSelected={this.handleFilesSelected.bind(this)}
                                    accept={this.props.acceptFileTypes}
                                    uploadButtonText={"Select a local file"}
                                    uploadLabel={"Drag a file here"} />
                                <div className="fileNameRow">
                                    <div className="fileName">{this.state.file?.name}</div>
                                    <div className="fileTypes">{this.props.acceptFileTypesDisplayText}</div>
                                </div>
                            </div>

                            <CustomFooter className={"import-footer"}>
                                <SubmitButton
                                    className="submitButton"
                                    type="submit"
                                    themes={['primary']}
                                    text="Import"
                                    disabled={this.props.isLoading || !this.state.file}
                                    loading={this.props.isLoading} />
                                <div className="selected-count"></div>
                                <Button
                                    type="button"
                                    themes={['secondary']}
                                    text="Cancel"
                                    onClick={() => this.props.onClose()}
                                    disabled={this.props.isLoading}
                                    loading={this.props.isLoading} />
                            </CustomFooter>
                        </form>
                        <PageError errors={this.props.errors} />
                    </PageContainer>
                </FullScreenLayout>
            </ImportFileDialogStyled>
        )
    }
}

const ButtonWrapper = styled.div`
    border-left: ${style_border_default};
    padding: 0 0 0 32px;
    margin: 8px 0;
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 32px;
    justify-content: space-between;
`;

const ImportFileDialogStyled = styled(ModalLayout)`
    color: ${color_text_default};
    height: 590px;
    width: 750px;
    min-width: 30%;
    min-height: 30%;

    .fileSelector {
        margin: 32px;
    }
    .fileNameRow {
        margin: 8px;
        margin-bottom: 0px;
        display: flex;
        flex-direction: row;
        .fileTypes {
            font-style: italic;
            margin-left: auto;
        }
        .fileName {
            font-weight: bold;
        }
    }

    .selected-count {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-right: auto;
    }

`;

const CustomFooter = styled(FooterBar)`
    z-index: auto;
`;

const SubmitButton = styled(Button)`
    margin-right: auto;
`;

export default ImportFileDialog;
