import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Subscribe } from 'unstated';
import ApplicationContainer from '../../../../shared/state/containers/ApplicationContainer';
import AppliedApplicationTemplateContainer from '../../../../shared/state/containers/AppliedApplicationTemplateContainer';
import OrganizationContainer from '../../../../shared/state/containers/OrganizationContainer';
import ApplicationManagerContent from './ApplicationManagerContent';
import ApplicationEnvironmentsContainer from '../../../../shared/state/containers/ApplicationEnvironmentsContainer';
import ApplicationAnalyticsFacetContainer from '../../../../shared/state/containers/ApplicationAnalyticsFacetContainer';
import { template } from 'lodash';
import TemplateConfigurationContainer from '../../../../shared/state/containers/TemplateConfigurationContainer';

interface AppliedAppTemplatesManagerProps {
    applicationId: string
    appliedAppTemplateId?: string
}

const ApplicationManager: React.FC<RouteComponentProps<AppliedAppTemplatesManagerProps>> = (props) => {

    return (
        <Subscribe to={[ApplicationContainer, AppliedApplicationTemplateContainer, OrganizationContainer, ApplicationEnvironmentsContainer, ApplicationAnalyticsFacetContainer, TemplateConfigurationContainer]}>
            {(appContainer: ApplicationContainer,
                appliedAppTemplateContainer: AppliedApplicationTemplateContainer,
                orgContainer: OrganizationContainer,
                applicationEnvironmentsContainer: ApplicationEnvironmentsContainer,
                facetContainer: ApplicationAnalyticsFacetContainer,
                templateConfigContainer: TemplateConfigurationContainer
            ) => (
                <ApplicationManagerContent
                    appContainer={appContainer}
                    appliedAppTemplateContainer={appliedAppTemplateContainer}
                    orgContainer={orgContainer}
                    applicationId={props.match.params.applicationId}
                    history={props.history}
                    facetContainer={facetContainer}
                    key={props.match.params.appliedAppTemplateId}
                    applicationEnvironmentsContainer={applicationEnvironmentsContainer}
                    templateConfigContainer={templateConfigContainer}
                    appliedAppTemplateId={props.match.params.appliedAppTemplateId}
                />
            )}
        </Subscribe>
    )
}

export default ApplicationManager;