import React from 'react';
import { css } from '@emotion/css';
import { color_text_light } from '../../constants/colors';
import TooltipWrapper from '../general/TooltipWrapper';
import styled from '@emotion/styled';
const deltaDown = require('../../content/images/analytics-icons/delta-down.svg');
const deltaUp = require('../../content/images/analytics-icons/delta-up.svg');

interface ReportTableHeaderProps {
    title: string
    value?: string | number
    deltaValue?: number
    deltaPercentage?: number
    showValue?: boolean
    showPercentage?: boolean
    tooltip?: string
}

const ReportTableHeader: React.SFC<ReportTableHeaderProps> = (props) => (
    <div className={containerStyle}>
        {props.tooltip ?
            <StyledTooltipWrapper place="topLeft" text={props.tooltip}>
                <p className="item-label ac-analytics-table-header">{props.title}</p>
            </StyledTooltipWrapper>
            :
            <p className="item-label ac-analytics-table-header">{props.title}</p>
        }
        {props.value && <div className="item-values-container">
            <p className="item-value">{props.value}</p>
            {props.deltaValue != null && <img src={props.deltaValue > 0 ? deltaUp : deltaDown} />}
            {props.showValue && <p className="item-delta">{Math.abs(props.deltaValue)}</p>}
            {props.showValue && props.showPercentage && <div className="item-separator" />}
            {props.showPercentage && <p className="item-delta">{Math.round(Math.abs(props.deltaPercentage))}%</p>}
        </div>}
    </div>
);

const StyledTooltipWrapper = styled(TooltipWrapper)`
    max-width: 200px;
`;

const containerStyle = css`
flex: 1; 
text-align: left;
padding: 16px 0;
.item-label {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: ${color_text_light};
    text-align: left;
}
.item-values-container {
    display: flex;
    align-items: center;
    img {
        margin-left: 8px;
        margin-right: 4px;
    }
    .item-value {
        font-size: 18px;
        line-height: 24px;
    }
    .item-delta {
        color: ${color_text_light};
        font-size: 14px;
        line-height: 20px;
    }
    .item-separator {
        height: 16px;
        max-height: 16px;
        width: 1px;
        background :${color_text_light};
        margin: 0 8px;
    }
}
`;

export default ReportTableHeader;