import IResult from "../../models/result/IResult";
import { sendDelete, sendGet, sendPost, sendPut } from "./generic";
import { APPLICATION_MENU_ITEM } from "../../constants/Urls";
import MenuItemModel from "../../models/nlp/menu/MenuItemModel";

export const addVoicifyMenuItem = (applicationId: string, request: MenuItemModel): Promise<IResult<MenuItemModel>> => {
    return sendPost(`${APPLICATION_MENU_ITEM}/${applicationId}`, request);
};

export const updateVoicifyMenuItem = (applicationId: string, request: MenuItemModel): Promise<IResult<MenuItemModel>> => {
    return sendPut(`${APPLICATION_MENU_ITEM}/${applicationId}`, request);
};

export const deleteVoicifyMenuItem = (applicationId: string, menuItemId: string): Promise<IResult<MenuItemModel>> => {
    return sendDelete(`${APPLICATION_MENU_ITEM}/${applicationId}?menuItemId=${menuItemId}`);
};

export const getVoicifyMenuItems = (applicationId: string): Promise<IResult<MenuItemModel[]>> => {
    return sendGet(`${APPLICATION_MENU_ITEM}/${applicationId}`);
};

export const updateVoicifyMenuItems = (applicationId: string, request: MenuItemModel[]): Promise<IResult<MenuItemModel[]>> => {
    return sendPut(`${APPLICATION_MENU_ITEM}/${applicationId}/setAll`, request);
};
