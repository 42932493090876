import { css } from '@emotion/css';
import React from 'react';
import { dark_grey_blue, ocean_blue, cool_grey, color_gradients_green_shift, color_gradients_knight_shift, color_gradients_green_shift_reverse, color_text_default, color_gradients_silver_shift, color_shades_dark, color_shades_light, color_text_light, color_gradients_silver_shift_reverse, color_shades_darkest, color_colors_growth, silver_four, color_highlights_blue, color_shades_darker } from '../../constants/colors';
import SecondaryLoader from './SecondaryLoader';

// primary button with different theme styles

type ButtonTheme = "primary" |
    "primary-small" |
    "secondary" |
    "secondary-small" |
    "secondary-medium" |
    "white" |
    "end" |
    "start" |
    "start-tight" |
    "hollow" |
    "light-text" |
    "end-tight" |
    "wide" |
    "medium-icon" |
    "icon" |
    "icon-small" |
    "white-small" |
    "black-small" |
    "icon-large" |
    "action-item" |
    "flat" |
    "destructive" |
    "fat" |
    "fill" |
    "small-flat" |
    "small-circle" |
    "blue-pill" |
    "no-shadow" |
    "inverse" |
    "white-pill" |
    "white-icon";

interface ButtonProps {
    themes: ButtonTheme[]
    icon?: string
    rightIcon?: string
    text?: string
    type?: "button" | "submit" | "reset"
    disabled?: boolean
    loading?: boolean
    onClick?: (e?: React.MouseEvent<HTMLElement>) => void
    additionalClasses?: string
    className?: string
    rightComponent?: () => JSX.Element
    leftComponent?: () => JSX.Element
}
const getClassName = (theme: ButtonTheme): string => {

    switch (theme) {
        case "primary": return primaryButton;
        case "primary-small": return primarySmallButton;
        case "secondary": return whiteButton;
        case "secondary-small": return whiteSmallButton;
        case "white-icon": return whiteIconButton;
        case "white": return whiteButton;
        case "end": return endButtonStyle;
        case "start": return startButtonStyle;
        case "start-tight": return startTightButtonStyle;
        case "hollow": return hollowBlueButton;
        case "light-text": return lightTextStyle;
        case "end-tight": return endTightStyle;
        case "wide": return wideStyle;
        case "secondary-medium": return secondaryMediumButton;
        case "medium-icon": return mediumIconStyle;
        case "icon": return iconOnlyStyle;
        case "icon-small": return iconSmallOnlyStyle;
        case "icon-large": return iconLargeOnlyStyle;
        case "white-small": return whiteSmallButton;
        case "black-small": return blackSmallButton;
        case "action-item": return actionItemStyle;
        case "flat": return flatStyle;
        case "destructive": return destructiveStyle;
        case "fat": return fatStyle;
        case "fill": return fillStyle;
        case "small-flat": return smallFlatStyle;
        case "small-circle": return smallCircle;
        case "blue-pill": return bluePill;
        case "no-shadow": return noShadow;
        case "inverse": return inverseStyle;
        case "white-pill": return whitePill;
    }

    return '';
}

const baseButtonStyle = css`
    position: relative;
    height: 50px;
    white-space: nowrap;
    border-radius: 40px;
    border: none;
    padding: 0 40px;
    margin: 16px;
    cursor: pointer;
    font-family: Muli;
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.4px;
    text-align: center;
    .left-img {
        margin-right: 8px;
    }
    .right-img {
        margin-left: 8px;
    }

    .spinner > div {
        background-color: ${dark_grey_blue};
    }
    .secondary-loader {        
        position: absolute;
        opacity: 1;
        top: 50%;
        left: 50%;
        margin-top: -20px;
        margin-left: -25px;
        .spinner {
            margin: 0px;
        }
    }
    &:disabled {
        cursor: not-allowed;
    }
`;

const fatStyle = css`
    height: 60px !important;
`

const fillStyle = css`
    width: 100%;
`
const noShadow = css`
    box-shadow: none;
`
const smallFlatStyle = css`
    margin: 0 32px 16px 40px;
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #007dbb;
    background: none;
    border: none;
    box-shadow: none;
    padding: 0;
    .right-img {
        margin-left: 4px;
    }
`
const bluePill = css`
    background: transparent;
    border: 1px solid #007dbb;
    color: #007dbb;
    border-radius: 15px;
    height: 32px;
    box-shadow: none;
    padding: 0 12px;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: center;
    color: #007dbb;
    img {
        width: 16px;
        height: 16px;
    }
`;
const lightTextStyle = css`
    font-weight: normal;
`
const endButtonStyle = css`
    margin-left: auto;
    margin-right: 50px;
`;
const endTightStyle = css`
    margin-left: auto;
    margin-right: 0;
`
const startButtonStyle = css`
    margin-left: 50px;
    width: auto;
`;
const startTightButtonStyle = css`
    margin-left: 0;
    width: auto;
`;
const whiteButton = css`
    background: ${color_gradients_silver_shift};
    color: ${color_text_default};
    border: solid 1px ${color_shades_dark};

    
    &:disabled {
        background: ${color_shades_light};
        color: ${color_text_light};
        &:hover, &:active {
            background: ${color_shades_light};
        }
    }
    &:hover {
        background: ${color_gradients_silver_shift_reverse};
    }
    &:active {
        box-shadow: inset 0px 0px 32px rgba(0, 0, 0, 0.12);
    }
`;
const destructiveStyle = css`
    background-image: linear-gradient(258deg, #ea727a, #e35760 63%, #c7565d);
    color: white;
    .spinner > div {
        background-color: white;
    }
`;
const whiteSmallButton = css`
    height: 32px;
    padding: 0 24px;
    font-size: 14px;
    font-weight: 600;
    
    background: ${color_gradients_silver_shift};
    color: ${color_text_default};
    border: solid 1px ${color_shades_dark};

    .secondary-loader {
        margin-top: -10px;
    }    

    &:disabled {
        background: ${color_shades_light};
        color: ${color_text_light};
        &:hover, &:active {
            background: ${color_shades_light};
        }
    }
    &:hover {
        background: ${color_gradients_silver_shift_reverse};
    }
    &:active {
        box-shadow: inset 0px 0px 32px rgba(0, 0, 0, 0.12);
    }
    .spinner {
        height: 20px;
    }
`;
const blackSmallButton = css`
    height: 32px;
    padding: 0 24px;
    font-size: 14px;
    font-weight: 600;
    
    background: ${color_shades_darkest};
    color: ${color_shades_light};
    border: solid 1px ${color_shades_dark};

    .secondary-loader {
        margin-top: -10px;
    }    

    &:disabled {
        background: ${color_shades_light};
        color: ${color_text_light};
        &:hover, &:active {
            background: ${color_shades_light};
        }
    }
    &:hover {    
        color: white;
    }
    &:active {
        box-shadow: inset 0px 0px 32px rgba(0, 0, 0, 0.12);
    }
    .spinner {
        height: 20px;
    }
`;
const whiteIconButton = css`
    height: 32px;
    width: 32px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    
    background: ${color_gradients_silver_shift};
    color: ${color_text_default};
    border: solid 1px ${color_shades_dark};

    .secondary-loader {
        margin-top: -10px;
    }    

    &:disabled {
        background: ${color_shades_light};
        color: ${color_text_light};
        &:hover, &:active {
            background: ${color_shades_light};
        }
    }
    &:hover {
        background: ${color_gradients_silver_shift_reverse};
    }
    &:active {
        box-shadow: inset 0px 0px 32px rgba(0, 0, 0, 0.12);
    }
    .spinner {
        height: 20px;
    }
    
    .left-img {
        margin: 0;
    }
    .right-img {
        margin: 0;
    }
`;

const smallCircle = css`
    width: 22px;
    height: 22px;
    box-shadow: none;
    background: white;
    color: ${cool_grey};
    padding: 0px;
    margin: 0px;
    font-size: 14px;
    font-weight: normal;
    border: solid 1px #cccccc;

`;

const primaryButton = css`
    color: white;
    background: ${color_gradients_green_shift};
    .loader .spinner > div {
        background-color: white;
    }

    &:disabled {
        background: ${color_gradients_knight_shift};
        &:hover, &:active {
            background: ${color_gradients_knight_shift};
        }
    }
    &:hover {
        background: ${color_gradients_green_shift_reverse};
    }
    &:active {
        box-shadow: inset 0px 0px 32px rgba(0, 0, 0, 0.25);
    }
`;
const inverseStyle = css`
    color: white;
    background: ${color_shades_darkest};
    border: 1px solid white;
    .loader .spinner > div {
        background-color: white;
    }

    &:disabled {
        opacity: 0.4;
        &:hover, &:active {
            background: ${color_shades_darkest};
        }
    }
    &:hover {
        background: rgba(255, 255, 255, 0.16);
    }
    &:active {
        box-shadow: inset 0px 0px 16px rgba(255, 255, 255, 0.5);
    }
`;

const primarySmallButton = css`
    color: white;
    background: ${color_gradients_green_shift};
    height: 32px;
    padding: 0 24px;
    font-size: 14px;
    font-weight: normal;
    .loader .spinner > div {
        background-color: white;
    }
    .spinner {
        height: 20px;
    }
    .secondary-loader {
        margin-top: -10px;
    }    
    &:disabled {
        background: ${color_gradients_knight_shift};
        &:hover, &:active {
            background: ${color_gradients_knight_shift};
        }
    }
    &:hover {
        background: ${color_gradients_green_shift_reverse};
    }
    &:active {
        box-shadow: inset 0px 0px 32px rgba(0, 0, 0, 0.25);
    }
`;

const secondaryMediumButton = css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 100%;
    height: 48px;
    border: solid 1px ${silver_four};
    border-radius: 24px;
    color: ${color_shades_darkest};
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    margin: 16px 0px;
    background: linear-gradient(224.91deg, #F5F9FC 3.06%, #FAFDFE 50.21%, #F4F5F5 100%);
`;

const hollowBlueButton = css`
    height: 40px;
    border-radius: 20px;
    border: dashed 1px ${ocean_blue};
    color: ${ocean_blue};
    font-size: 14px;
    background: transparent;
    box-shadow: none;
    margin-left: 0px;
    padding:0;
    align-items: center;
    span {
        flex-grow: 1;
        text-align: center;
    }
`;

const flatStyle = css`
    border: none;
    color: ${ocean_blue};
    background: transparent;
`

const disabledStyle = css`
    opacity: .9;
`

const wideStyle = css`
    padding: 14px 64px;
`

const mediumIconStyle = css`
    height: 25px;
    width: 25px;
    background: transparent;
    box-shadow: none;
    border: none;
    margin: 0 0 0 8px;
    padding: 0;
`

const iconOnlyStyle = css`
    background: transparent;
    box-shadow: none;
    border: none;
    width: auto;
    height: 24px;
    padding: 0;
    span {
        display: none;
    }
    img {
        margin-right: 0;
        height: 24px;
        width: auto;
    }
`;

const iconSmallOnlyStyle = css`
    background: transparent;
    box-shadow: none;
    border: none;
    width: 24px;
    height: 24px;
    padding: 0;
    span {
        display: none;
    }
    img {
        margin-right: 0;
        margin-top: -12px;
        height: 18px;
        width: 18px;
    }
`;

const iconLargeOnlyStyle = css`
    background: transparent;
    box-shadow: none;
    border: none;
    width: auto;
    height: 42px;
    padding: 0;
    span {
        display: none;
    }
    img {
        margin-right: 0;
        height: 42px;
        width: auto;
    }
`

const actionItemStyle = css`
width: 124px;
height: 50px;
border-radius: 30px;
border: solid 1px ${ocean_blue};
color: ${ocean_blue};
background: transparent;
font-family: Muli;
font-size: 12px;
font-weight: 600;
font-style: normal;
font-stretch: normal;
line-height: normal;
letter-spacing: normal;
text-align: right;
box-shadow: none;
align-items: center;
padding: 0 24px;
text-align: center;
margin: 0 16px;
right-img {
    margin-left: 0;
}
`

const whitePill = css`
    height: 32px;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    color: ${color_text_default};
    border: solid 1px ${color_shades_dark};
    background: white;

    .secondary-loader {
        margin-top: -10px;
    }    

    &:disabled {
        background: ${color_shades_light};
        color: ${color_text_light};
        &:hover, &:active {
            background: ${color_shades_light};
        }
    }
    &:hover {
        background: ${color_gradients_silver_shift_reverse};
    }
    .spinner {
        height: 20px;
    }
`;

const contentStyle = css`    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const invisible = css`
    opacity: 0;
`;

const Button: React.FC<ButtonProps> = (props) => (
    <button className={`${baseButtonStyle} ${props.themes.map(getClassName).join(' ')} ${props.additionalClasses} ${props.disabled ? disabledStyle : null} ${props.className}`}
        type={props.type}
        onClick={props.onClick}
        disabled={props.disabled}>
        <div className={`${contentStyle} ${props.loading ? invisible : null}`}>
            {props.icon !== undefined ? <img className="left-img" src={props.icon} /> : null}
            {props.leftComponent ? props.leftComponent() : null}
            {props.text != null && <span>{props.text}</span>}
            {props.rightComponent ? props.rightComponent() : null}
            {props.rightIcon !== undefined ? <img className="right-img" src={props.rightIcon} /> : null}
        </div>
        {props.loading && <SecondaryLoader className="secondary-loader" />}
    </button>
);

export default Button;