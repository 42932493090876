import React from 'react';
import { css } from '@emotion/css';
import ApplicationContainer from '../../../state/containers/ApplicationContainer';
import Loader from '../../../components/general/Loader';
import OrganizationContainer from '../../../state/containers/OrganizationContainer';
import MemberModel from "../../../models/organizations/api/OrganizationMemberModel";
import _ from 'lodash';
import RoundedTwoOptionButton from '../../../components/forms/RoundedTwoOptionButton';
import RoundedSelectField from '../../../components/forms/RoundedSelectField';
import NumberIncrementSelector from '../../../components/forms/NumberIncrementSelector';
import { Option } from 'react-select';
import { color_shades_dark, color_shades_darker, color_shades_darkest } from '../../../constants/colors';
import SearchField from '../../../components/forms/SearchField';
import Button from '../../../components/general/Button';
import { Link } from 'react-router-dom';
import MemberContainer from '../../../state/containers/MemberContainer';
import UserContainer from '../../../state/containers/UserContainer';
import AuthContainer from '../../../state/containers/AuthContainer';
import FlatRoundedSelectField from '../../../components/forms/FlatRoundedSelectField';
import HorizontalContainer from '../../../components/structure/HorizontalContainer';
import OrganizationMemberCard from './OrganizationMemberCard';
import InvitationModel from '../../../models/organizations/api/InvitationModel';
import UserRoleModel from '../../../models/user/UserRoleModel';
import MembershipModel from '../../../models/organizations/api/MembershipModel';
import { CardTableOption } from '../../../models/general/CardTableOption';
import ConfirmationDialog from '../../../components/structure/ConfirmationDialog';
import RemoveConfirmation from '../../../components/general/RemoveConfirmation';
import OrganizationMemberTableItem from './OrganizationMemberTableItem';
import ConversationFlowViewer from '../../conversationFlow/components/ConversationFlowViewer';

const addIcon = require("../../../content/images/content-explorer/add-white.svg");

interface OrganizationMemberListViewProps {
    memberContainer: MemberContainer
    appContainer: ApplicationContainer
    organizationContainer: OrganizationContainer
    userContainer: UserContainer
    authContainer: AuthContainer
    organizationId: string
    history: any
}

interface OrganizationMemberListViewState {
    isRemoveConfirmationVisible: boolean,
    selectedMember: MemberModel,
    displayedMembers: MemberModel[],
    view: CardTableOption,
    page: number,
    take: string,
    search: string,
    sortBy: string
    previousMembers: MemberModel[],
    currentMemberId: string,
}
const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;

class OrganizationMemberListView extends React.Component<OrganizationMemberListViewProps, OrganizationMemberListViewState, {}> {
    timer: NodeJS.Timer = null;

    constructor(props) {
        super(props);
        this.state = {
            selectedMember: null as MemberModel,
            isRemoveConfirmationVisible: false,
            view: "card",
            page: 1,
            take: "10",
            search: "",
            sortBy: "NameAscending",
            displayedMembers: [],
            previousMembers: [],
            currentMemberId: ""
        }
    }

    componentDidMount() {
        this.props.memberContainer.getInvitations(this.props.organizationId);
        this.props.organizationContainer.getSystemRoles();
        if (!(this.props.organizationContainer.state.organizations?.length)) {
            this.props.organizationContainer.getOrganizations();
        }
        if (this.props.userContainer.state.currentUser == null) {
            this.props.userContainer.getUser();
        }
        this.setState({
            ...this.state,
            sortBy: this.props.memberContainer.state.organizationMemberListPreferences?.sortBy ?? "NameAscending",
            take: this.props.memberContainer.state.organizationMemberListPreferences?.take ?? "10",
            view: this.props.memberContainer.state.organizationMemberListPreferences?.view ?? "card",
        }, this.reloadMembers);
    }

    componentDidUpdate() {
        if (this.props.memberContainer.state.members != null && this.state.previousMembers != this.props.memberContainer.state.members) {
            this.setState({
                ...this.state,
                previousMembers: this.props.memberContainer.state.members
            }, this.recalculateDefaultMembers);
        }
    }

    reloadMembers() {
        this.props.memberContainer.getMembers(this.props.organizationId).then((result) => this.recalculateMembers(result.data));
    }

    recalculateMembers(members: MembershipModel[]) {
        this.setState({
            ...this.state,
            displayedMembers: this.sortSearchAndPageMembers(members)
        });
    }

    toggleIsRemoveConfirmationVisible() {
        this.setState({
            ...this.state,
            isRemoveConfirmationVisible: !this.state.isRemoveConfirmationVisible
        });
    }

    recalculateDefaultMembers() {
        this.setState({
            ...this.state,
            displayedMembers: this.sortSearchAndPageMembers(this.props.memberContainer.state.members)
        });
    }

    handleChangeRole(memberId: string, roleName: string) {
        var role: UserRoleModel;
        if (roleName == "Administrator") {
            role = this.props.organizationContainer.state.systemRoles.find(r => r.isAdmin);
        }
        else {
            role = this.props.organizationContainer.state.systemRoles.find(r => !r.isAdmin && r.canEdit)
        }

        this.props.memberContainer.updateMemberRole(memberId, role.id).then((result) => this.reloadMembers());
    }

    renderCards(members: MemberModel[], invitations: InvitationModel[]) {
        var admins = this.props.memberContainer.state.members.filter(m => m.isAdmin);
        var currentMember = this.props.memberContainer.state.members.find(m => m.userId == this.props.userContainer.state.currentUser?.id);
        var oneAdmin = admins.length < 2;
        return (
            <div className={orgCardContainer}>
                {members.map(member =>
                    <OrganizationMemberCard
                        key={member.id}
                        remove={() => this.showRemoveMember(member, currentMember.id)}
                        allowRoleChange={((!member.isAdmin || !oneAdmin) && currentMember?.isAdmin)}
                        updateRole={this.handleChangeRole.bind(this)}
                        member={member}
                        allowRemove={member.id == currentMember?.id || (member.isAdmin && oneAdmin)} />)}
                {invitations.map(invitation =>
                    <OrganizationMemberCard
                        key={invitation.id}
                        invitation={invitation}
                        remove={() => this.props.memberContainer.revokeInvitation(invitation.id)}
                        resendInvitation={() => this.resendInvitation(invitation)} />)}
            </div>
        )
    }

    renderTable(members: MemberModel[], invitations: InvitationModel[]) {
        var admins = this.props.memberContainer.state.members.filter(m => m.isAdmin);
        var currentMember = this.props.memberContainer.state.members.find(m => m.userId == this.props.userContainer.state.currentUser?.id);
        var oneAdmin = admins.length < 2;
        return (
            <div className={tableContainer}>
                <div className="top-row">
                    <div className="user">USER</div>
                    <div className="email">EMAIL</div>
                    <div className="organization-role">ORGANIZATION ROLE</div>
                    <div className="participant-in">PARTICIPANT IN</div>
                </div>
                {members.map(member =>
                    <OrganizationMemberTableItem
                        remove={() => this.showRemoveMember(member, currentMember?.id)}
                        allowRoleChange={((!member.isAdmin || !oneAdmin) && currentMember?.isAdmin)}
                        updateRole={this.handleChangeRole.bind(this)} member={member}
                        allowRemove={currentMember?.id == member.id || (member.isAdmin && oneAdmin)} />)}
                {invitations.map(invitation =>
                    <OrganizationMemberTableItem
                        invitation={invitation}
                        remove={() => this.props.memberContainer.revokeInvitation(invitation.id)}
                        resendInvitation={() => this.resendInvitation(invitation)} />)}
            </div>
        )
    }

    incrementPage() {
        const total = this.props.memberContainer.state.members.length;
        const nextAppToLoadIndex = this.state.page * parseInt(this.state.take) + 1;
        if (nextAppToLoadIndex <= total) {
            this.setState(
                {
                    ...this.state,
                    page: this.state.page + 1
                }
                , this.recalculateDefaultMembers);
        }
    }

    decrementPage() {
        if (this.state.page > 1) {
            this.setState(
                {
                    ...this.state,
                    page: this.state.page - 1
                }
                , this.recalculateDefaultMembers);
        }
    }

    resendInvitation(invitation: InvitationModel) {
        this.props.memberContainer.resendInvitation(this.props.organizationId, invitation);
    }

    toggleView() {
        var newView: CardTableOption = this.state.view == "card" ? "table" : "card";
        this.setState(
            {
                ...this.state,
                view: newView
            }
        );
        this.props.memberContainer.updateOrganizationMemberListPreferences({
            take: this.state.take,
            sortBy: this.state.sortBy,
            view: newView
        });
    }

    handleSortByChange(o: Option) {
        const sortByString = o.value.toString();
        this.setState(
            {
                ...this.state,
                sortBy: sortByString,
                page: 1
            }
            , this.recalculateDefaultMembers);
        this.props.memberContainer.updateOrganizationMemberListPreferences({
            take: this.state.take,
            sortBy: sortByString,
            view: this.state.view
        });
    }

    handleTakeChange(o: Option) {
        const takeString = o.value.toString();
        this.setState(
            {
                ...this.state,
                take: takeString
            }
            , this.recalculateDefaultMembers);
        this.props.memberContainer.updateOrganizationMemberListPreferences({
            take: takeString,
            sortBy: this.state.sortBy,
            view: this.state.view
        });
    }

    handleSearch(searchEvent) {
        clearTimeout(this.timer);
        this.setState(
            {
                ...this.state,
                search: searchEvent.target.value
            }
        );

        this.timer = setTimeout(this.recalculateMembers.bind(this), WAIT_INTERVAL);
    }

    renderAppListDropdown(options: Option[], onChange, value, label: string, className: string) {
        return (
            <div className={`${pageAndSortDropdown} ${className}`}>
                <div className={leftSideLabel}>{label}</div>
                <RoundedSelectField
                    options={options}
                    onChange={onChange}
                    value={value} />
            </div>
        );
    }

    handleSearchKeyDown(e) {
        if (e.keyCode === ENTER_KEY) {
            this.recalculateDefaultMembers();
        }
    }

    renderAddNewButton() {
        var currentMember = this.props.memberContainer.state.members.find(m => m.userId == this.props.userContainer.state.currentUser?.id);

        if (currentMember?.isAdmin != true) {
            return <Button text="Add New" disabled themes={["primary-small"]} rightIcon={addIcon} className="add-new-button ac-members-add-button" />
        }
        return (
            <Link to='/v/members/invite'>
                <Button text="Add New" themes={["primary-small"]} rightIcon={addIcon} className="add-new-button ac-members-add-button" />
            </Link>
        )
    }

    showRemoveMember(member: MembershipModel, currentMemberId: string) {
        this.setState({
            ...this.state,
            selectedMember: member,
            isRemoveConfirmationVisible: true,
            currentMemberId: currentMemberId,
        });
    }

    removeMember() {
        if (this.state.selectedMember.id != this.state.currentMemberId)
            this.props.memberContainer.removeMemberFromOrg(this.state.selectedMember.id).then((result) => { this.toggleIsRemoveConfirmationVisible(); });
        else {
            this.props.memberContainer.leaveOrganization(this.state.selectedMember.id, this.props.organizationId).then((result) => {
                if (this.props.organizationContainer.state.organizations.length > 1) {
                    location.pathname = "/orgs";
                }
                else {
                    location.pathname = "/v/logout";
                }
            });
        }
    }

    sortSearchAndPageMembers(members: MembershipModel[]): MembershipModel[] {
        const search = this.state.search;
        const page = this.state.page;
        const take = parseInt(this.state.take);
        const sortBy = this.state.sortBy;

        if (members && search.toLowerCase()) {
            members = members.filter(m => `${m.firstName.toLowerCase()} ${m.lastName.toLowerCase()} ${m.email.toLowerCase}`.includes(search.toLowerCase())) ?? [];
        } else {
            members = this.props.memberContainer.state.members;
        }

        let compareFunction: (a: MembershipModel, b: MembershipModel) => any = null;
        switch (sortBy) {
            case ("NameAscending"):
                compareFunction = (a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)
                break;
            case ("NameDescending"):
                compareFunction = (a, b) => -1 * (`${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`))
                break;
            case ("CreatedDateAscending"):
                compareFunction = (a, b) => new Date(a.createdDate) > new Date(b.createdDate) ? -1 : new Date(a.createdDate) == new Date(b.createdDate) ? 0 : 1;
                break;
            case ("CreatedDateDescending"):
                compareFunction = (a, b) => new Date(a.createdDate) > new Date(b.createdDate) ? 1 : new Date(a.createdDate) == new Date(b.createdDate) ? 0 : -1;
                break;
        }

        members = members?.sort(compareFunction);
        if (members?.length > 0) {
            const startingIndex = 0 + ((page - 1) * take)
            if (startingIndex < members.length) {
                let endingIndex = startingIndex + take;
                if (endingIndex >= members.length)
                    endingIndex = members.length;
                members = members.slice(startingIndex, endingIndex);
            }
        }
        return members;
    }

    render() {
        const invitations = this.props.memberContainer.state.invitations ?? [];
        return (
            <div>
                <HorizontalContainer className={searchAndModify}>
                    <SearchField
                        id="1"
                        name={"searchField"}
                        placeholder={"Search"}
                        disabled={false}
                        onChange={this.handleSearch.bind(this)}
                        onKeyDown={this.handleSearchKeyDown.bind(this)}
                        className={searchField} />
                    <div className={"table-grid"}><RoundedTwoOptionButton updateViewValue={this.toggleView.bind(this)} selectedOption={this.state.view == "table"} /></div>
                    {this.renderAddNewButton()}
                </HorizontalContainer>
                <HorizontalContainer className={pageAndSort}>
                    <FlatRoundedSelectField
                        options={[{ label: "Name - Alphabetical", value: "NameAscending" },
                        { label: "Name - Reverse Alphabetical", value: "NameDescending" },
                        { label: "Date Added - Oldest First", value: "CreatedDateAscending" },
                        { label: "Date Added - Latest First", value: "CreatedDateDescending" }]}
                        onChange={this.handleSortByChange.bind(this)}
                        value={this.state.sortBy}
                        label="Arrange by"
                        className={arrangeBy}
                    />
                    <FlatRoundedSelectField
                        options={[{ label: "10", value: "10" },
                        { label: "25", value: "25" },
                        { label: "50", value: "50" }]}
                        onChange={this.handleTakeChange.bind(this)}
                        value={this.state.take}
                        label="Display at once"
                        className={take}
                    />
                    <div className={pageContainer}>
                        <div className={leftSideLabel}>Page</div>
                        <NumberIncrementSelector
                            selectedNumber={`${this.state.page}`}
                            updateValue={(b: boolean) => b ? this.incrementPage() : this.decrementPage()}
                            showNumber={true} />
                    </div>
                </HorizontalContainer>

                {this.state.isRemoveConfirmationVisible &&
                    <ConfirmationDialog
                        title="Remove"
                        deleteText="Yes, Remove"
                        isLoading={this.props.memberContainer.state.isUpdating}
                        onClose={this.toggleIsRemoveConfirmationVisible.bind(this)}
                        onConfirm={this.removeMember.bind(this)}>
                        <RemoveConfirmation memberName={this.state.selectedMember.firstName + " " + this.state.selectedMember.lastName}
                            customText={(this.state.selectedMember.id == this.state.currentMemberId && this.props.organizationContainer.state.organizations.length == 1) ? "Removing yourself from this organization will return you to the login screen." : undefined}
                        />
                    </ConfirmationDialog>}

                {
                    this.props.memberContainer.state.isLoadingMembers ?
                        <Loader />
                        :
                        this.state.view == "card" ? this.renderCards(this.state.displayedMembers, invitations) : this.renderTable(this.state.displayedMembers, invitations)
                }
            </div>
        );
    }
}

const searchAndModify = css`
    display: flex;
    flex-direction: row;
    margin: 12px 0 12px 32px;
    div {        
        box-shadow: none;
    }
    .centerer {
        box-shadow: none;
    }
    .field-container {
        height: 32px;
        border-radius: 16px;
        margin-top: 0px;
    }
    .table-grid {
        margin: 0px 16px;
    }
    .apps-name {
        font-size: 14px;
        line-height: 20px;
        height: 32px;
        font-style: normal;
        font-weight: normal;
        padding: 6px 16px 4px 8px;  
    }
    .add-new-button {
        margin: 0px;
        margin-right: 32px;
    }
    .separator {
        margin-right: 16px;
        height: 32px;
    }
`;
const searchField = css`
    margin-bottom: 0px;
`;
const pageAndSort = css`
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid ${color_shades_dark};
    border-top: 1px solid ${color_shades_dark};
`;

const pageAndSortDropdown = css`
    display: flex;
    flex-direction: row;
    .Select-control {
        box-shadow: none;
    }
`;

const pageContainer = css`
    display: flex;
    flex-direction: row;
    margin: 12px 32px 0px 0px; 
    .page-selector {
        box-shadow: none;
    }
`;

const leftSideLabel = css`    
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${color_shades_darkest};
    margin-right: 16px;
    margin-top: 8px;
`;

const arrangeBy = css`    
    margin: 12px auto 12px 32px; 
    .Select {
        width: 240px
    }
`;

const take = css`    
    margin: 12px 32px 12px 0px; 
    .Select {
        width: 80px
    }
`;

const orgCardContainer = css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
`
const tableContainer = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    .top-row {
        display: flex;
        flex-direction: row;
        border-bottom: solid 1px ${color_shades_dark};
        height: 40px;
        padding-left: 32px;
        padding-right: 32px;
        div {
            margin-top: auto;
            margin-bottom: auto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: ${color_shades_darker};
        }
        .user {
            width: 30%;
        }
        .email {
            width: 20%;
        }
        .organization-role {
            width: 20%;
        }
        .participant-in {
            width: 20%;
        }
    }
`
export default OrganizationMemberListView;