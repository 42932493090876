import React, { useEffect, useState, useRef } from 'react';
import { useSupportAssistant } from './hooks/SupportAssistantHook';
import styled from "@emotion/styled";
import { css } from '@emotion/css';
import { v4 as uuidv4 } from 'uuid';
import ConversationHistoryItem from './components/ConversationHistoryItem';
import CustomScrollbars from '../../components/structure/CustomScrollbars';
import UserContainer from '../../state/containers/UserContainer';
import { color_shades_dark, color_shades_darker, color_shades_darkest, color_shades_lighter } from '../../constants/colors';
import { style_border_default } from '../../constants/stylesValues';
import DeviceLoadingChatBubble from '../../components/messagePreviews/DeviceLoadingChatBubble';
import Button from '../../components/general/Button';

const sendIcon = require("../../content/images/support-assistant/send.svg");
const micIcon = require("../../content/images/support-assistant/microphone.svg");
const botAvatar = require("../../content/images/support-assistant/bot-avatar.svg");

interface SupportAssistantProps {
    userContainer: UserContainer
}

const SupportAssistant: React.FC<SupportAssistantProps> = ({ userContainer }) => {
    const [isMicOpen, setIsMicOpen] = useState<boolean>(false);
    const [allowMicInput, setAllowMicInput] = useState<boolean>(true);
    const [userInputText, setUserInputText] = useState<string>("");

    const scrollingRef = useRef(null);
    const voicify = useSupportAssistant();

    useEffect(() => {
        if (scrollingRef && scrollingRef.current) {
            scrollingRef.current.scrollIntoView({ block: 'end' })
        }
    });

    useEffect(() => {
        if (voicify.appId) {
            voicify.startSession();
        }
    }, [voicify.appId]);

    const user = userContainer.state.currentUser;
    const userImage = user?.imageUrl;
    const userFirstName = user?.firstName;
    const userLastName = user?.lastName;

    if (allowMicInput && !window['SpeechRecognition'] && !window["webkitSpeechRecognition"]) {
        setAllowMicInput(false);
    };

    const SpeechRecognition = window['SpeechRecognition'] || window["webkitSpeechRecognition"];

    let recognition = SpeechRecognition ? new SpeechRecognition() : null;

    if (recognition) {
        recognition.lang = "en-US";
        // This runs when the speech recognition service starts
        recognition.onstart = function () {
            // start recognition
            setIsMicOpen(true);
        };
        recognition.onerror = () => {
            setIsMicOpen(false);
        };
        recognition.onspeechend = function () {
            // when user is done speaking
            recognition.stop();
            setIsMicOpen(false);
        };
        // This runs when the speech recognition service returns result
        recognition.onresult = async function (e: any) {
            const transcript = e.results[0][0].transcript;
            const history = await voicify.addUserHistory(transcript);
            setUserInputText("");
            voicify.makeRequest(transcript, history);
        };
    }

    const handleStartListening = () => {
        recognition.start();
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setUserInputText(e.target.value);
    };

    const handleRestartClick = (e: any) => {
        e.stopPropagation();
        voicify.resetSession();
        voicify.startSession();
    }

    const handleSubmit = async () => {
        const history = await voicify.addUserHistory(userInputText);
        setUserInputText("");
        await voicify.makeRequest(userInputText, history);
    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            handleSubmit();
        }
    };

    const handleHintSubmit = async (hint: string, e: any) => {
        e.stopPropagation();
        const history = await voicify.addUserHistory(hint);
        setUserInputText("");
        await voicify.makeRequest(hint, history);
    };

    return (
        <>
            <ChatContainer>
                <ChatBody>
                    <CustomScrollbars >
                        <ConversationItemContainer>
                            <>
                                {voicify.conversationHistory.map((conversationHistoryItem) => {
                                    return <ConversationHistoryItem
                                        key={uuidv4()}
                                        conversationHistoryItem={conversationHistoryItem}
                                        userImage={userImage}
                                        userFirstName={userFirstName}
                                        userLastName={userLastName}
                                    />
                                })}
                            </>
                            {voicify.loading ? <DeviceLoadingChatBubble icon={botAvatar} iconClassName={BotAvatarStyle} /> : null}
                            <div ref={scrollingRef} />
                        </ConversationItemContainer>
                    </CustomScrollbars>
                </ChatBody>
                <ChatFooterWrapper>
                <ChatFooter>
                    {!voicify.loading &&
                        <HintButtonWrapper>
                            {voicify.hints?.map((hint) => {
                                return <Button
                                    className="hint-button"
                                    type="button"
                                    key={uuidv4()}
                                    themes={['white-small']}
                                    onClick={(e) => handleHintSubmit(hint, e)}
                                    text={hint} />
                            })}
                            <Button
                                className="hint-button"
                                type="button"
                                themes={["white-small"]}
                                onClick={(e) => voicify.loading ? null : handleRestartClick(e)}
                                text="Restart"
                            />
                        </HintButtonWrapper>
                        }
                    <ChatInputContainer>
                        {recognition && allowMicInput &&
                            <MicStyleContainer type="button" onClick={handleStartListening}>
                                <Mic src={micIcon} />
                                {isMicOpen ? <MicText>Listenting</MicText> : <MicText>Click to Speak</MicText>}
                            </MicStyleContainer>}
                        <ChatInput
                            onKeyDown={onKeyDown}
                            type="text"
                            placeholder="Type something here"
                            value={userInputText}
                            onChange={handleChange}
                        />
                        <SendButton type="submit" onClick={handleSubmit}>
                            <img src={sendIcon} />
                        </SendButton>
                    </ChatInputContainer>
                </ChatFooter>
                </ChatFooterWrapper>
            </ChatContainer>
        </>
    );
};

const BotAvatarStyle = css`
    border: 1px solid ${color_shades_dark};
    background: white;
    height: 24px;
    width: 24px;
    padding: 1px;
`

const HintButtonWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
    .hint-button {
        font-family: Muli;
        font-weight: 400;
        font-size: 14px;
        color: ${color_shades_darkest};
        margin: 0 8px 8px 0;
    }
`

const MicStyleContainer = styled.button`
    border: none;
    background-color: transparent;
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    border-right: ${style_border_default};
`

const Mic = styled.img`
    height: 32px;
    width: 32px;
`

const MicText = styled.p`
    color: ${color_shades_darkest};
    font-size: 10px;
    font-family: Muli;
    padding: 2px 0;
`

const ChatContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 200px);
`

const ChatBody = styled.div`
    color: ${color_shades_darkest};
    background-color: ${color_shades_lighter};
    font-family: Muli;
    width: 550px;
    height: calc(100vh - 400px);
    display: flex;
    flex-direction: column;
`

const ChatFooterWrapper = styled.div`
    display: flex;
    width: 550px;
    justify-content: center;
`

const ChatFooter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: ${color_shades_darkest};
    background-color: ${color_shades_lighter};
    font-family: Muli;
    width: 515px;
    height: 200px;
`

const ChatInputContainer = styled.div`
    border: ${style_border_default};
    box-shadow: 0px 4px 10px rgba(50, 70, 97, 0.1);
    border-radius: 12px;
    height: 72px;
    width: 515px;
    margin: 1rem 0;
    background-color: white;
    display: flex;
`

const ChatInput = styled.input`
    width: 70%;
    font-family: Muli;
    font-size: 16px;
    line-height: 24px;
    flex-grow: 1;
    margin: 1rem;
    border: none;
    outline: none;
    ::placeholder {
        color: ${color_shades_darker};
        font-style: italic;
    }
`

const SendButton = styled.button`
    margin: 1rem;
    border: none;
    outline: none;
    background: transparent;
    border-radius: 10%;
    width: 10%;
`

const ConversationItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 70px;
    font-family: Muli;
    font-weight: 400;
    font-size: 14px;
    color: ${color_shades_darkest};   
`

export default SupportAssistant;