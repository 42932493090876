import React from 'react';
import { css } from '@emotion/css';
import ReactMarkdown from 'react-markdown';
import { color_shades_dark, color_shades_lighter } from '../../../../shared/constants/colors';

interface TemplateInstructionsPanelProps {
    title: string
    instructionsMarkdown: string
    emptyText?: string
}

const linkRenderer = (props) => {
    return <a href={props.href} target="_blank">{props.children}</a>
};

const TemplateInstructionsPanel: React.FC<TemplateInstructionsPanelProps> = (props) => (
    <div className={containerStyle}>
        <h3>{props.title}</h3>
        <div className="instructions-wrapper">
            <ReactMarkdown
                className="instructions"
                source={props.instructionsMarkdown}
                renderers={{ link: linkRenderer }}
            />
        </div>
    </div >
);

const containerStyle = css`
    flex: 1;
    background: ${color_shades_lighter};
    padding: 32px 32px 164px 32px;
    border-left: 1px solid ${color_shades_dark};
    min-height: 100%;
    h3 {
        margin-bottom: 32px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
    }
    .instructions-wrapper {
        background: white;
        border: 1px solid ${color_shades_dark};
        border-radius: 8px;
        padding: 32px;
        margin-bottom: 32px;
    }
    .instructions {
        font-family: Muli;
        font-style: normal;
        font-stretch: normal;
        ol {
            margin-left: 16px;
        }
        ul {
            margin-left: 16px;
        }
        p {
            margin: 16px 0px;
        }
        h1 {
            margin-bottom: 16px;
            margin-top: 16px;  
            font-size: 24px;
            font-weight: bold;
        }
        h2 {
            margin-bottom: 16px;
            margin-top: 16px;  
            font-size: 20px;
            font-weight: bold;
        }
        h3 {
            margin-bottom: 16px;
            margin-top: 16px;        
            font-size: 18px;
            font-weight: bold;
        }
        h4 {
            margin-bottom: 16px;
            margin-top: 16px;  
            font-size: 16px;
            font-weight: bold;
        }
        h5 {
            margin-bottom: 16px;
            margin-top: 16px;  
            font-size: 14px;
            font-weight: bold italic;
        }
        h6 {
            margin-bottom: 16px;
            margin-top: 16px;  
            font-size: 14px;
            font-weight: bold;
        }
    }
`;

export default TemplateInstructionsPanel;