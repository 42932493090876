import { Container } from 'unstated';
import * as voicifyApi from '../../api';
import { isAbsolute } from 'path';
import AuthenticationResponse from '../../models/auth/api/AuthenticationResponse';
import { VOICIFY_ACCESS_TOKEN_STORE, VOICIFY_REFRESH_TOKEN_STORE } from '../../constants/Keys';
import MemberModel from "../../models/organizations/api/OrganizationMemberModel";
import BasicInvitationRequest from '../../models/organizations/api/BasicInvitationRequest';
import InvitationModel from '../../models/organizations/api/InvitationModel';
import IResult from '../../models/result/IResult';
import MembershipModel from '../../models/organizations/api/MembershipModel';
import OrganizationMemberListPreferences from '../../models/organizations/OrganizationMemberListPreferences';
import MembersSearchResult from '../../models/organizations/api/MembersSearchResult';

type MemberState = {
    members: MembershipModel[]
    isLoadingMembers: boolean
    isLoading: boolean
    isUpdating: boolean
    errors: string[]
    organizationMemberListPreferences: OrganizationMemberListPreferences
    invitations: InvitationModel[]
    isOrgAdmin: boolean
}

export default class MemberContainer extends Container<MemberState> {
    // default state
    state = this.getInitialState()

    getInitialState(): MemberState {
        // check local storage for initial state
        const state = {
            members: [],
            invitations: [],
            isLoadingMembers: false,
            isLoading: false,
            isUpdating: false,
            isOrgAdmin: false,
            errors: [],
            organizationMemberListPreferences: null as OrganizationMemberListPreferences
        }
        const storedPreferences = localStorage.getItem("ORG_MEMBER_LIST_PREFERENCES");
        if(storedPreferences != null)
            state.organizationMemberListPreferences = JSON.parse(storedPreferences);
         
        return state;
    }

    updateOrganizationMemberListPreferences(preferences: OrganizationMemberListPreferences) {
        this.setState({
            ...this.state,
            organizationMemberListPreferences: preferences    
        });
        localStorage.setItem("ORG_MEMBER_LIST_PREFERENCES", JSON.stringify(preferences));
    }

    getInvitations(organizationId: string) : Promise<IResult<InvitationModel[]>>{
        if(this.state.members.length > 0) {
            return new Promise(resolve => {
                resolve({
                    resultType: "Ok",
                    errors: [],
                    data: this.state.members
                });
            })
        }

        this.setLoadingMembers(true);
        const promise = voicifyApi.getInvitations(organizationId);
        promise.then(invitationsResult => {
            if (invitationsResult.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    errors: [],
                    invitations: invitationsResult.data,
                    isLoading: false
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: invitationsResult.errors,
                    isLoading: false
                })
            }
        }).catch(error => {
            console.log(error);
        })

        return promise;
    }

    async getMembers(organizationId: string) : Promise<IResult<MembershipModel[]>>{
        try {
            if(this.state.members.length > 0) {
                return new Promise(resolve => {
                    resolve({
                        resultType: "Ok",
                        errors: [],
                        data: this.state.members
                    });
                })
            }
            await this.setState({
                ...this.state,
                isLoadingMembers: false
            })
    
            this.setLoadingMembers(true);
            const membersResult = await voicifyApi.getDetailedMemberships(organizationId);
            if (membersResult.resultType == "Ok") {
                await this.setState({
                    ...this.state,
                    errors: [],                    
                    members: membersResult.data,
                    isLoadingMembers: false
                })
            }
            else {
                await this.setState({
                    ...this.state,
                    errors: membersResult.errors,
                    isLoadingMembers: false
                })
            }
    
            return membersResult;
        } catch (error) {
            console.log(error)         
        };
       
    }
    
    inviteMember(organizationId: string, request: BasicInvitationRequest) : Promise<IResult<InvitationModel>> {
        this.setState({
            ...this.state,
            isLoading: true
        })

        var promise = voicifyApi.sendInvitation(organizationId, request);
        promise.then(result => {            
            if(result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: [],
                    invitations: [...this.state.invitations, result.data]
                })
            }
            else {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: result.errors
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                isLoading: false,
                errors: [error]
            })
        })
        return promise;
    }

    async checkIsOrgAdmin(organizationId: string, userId: string) : Promise<boolean> {
        let members = this.state.members;
        if(members == null || members.length == 0) {
            const memberResult = await this.getMembers(organizationId);
            members = memberResult.data;
        }
        var currentMember = members?.find(m => m.userId == userId);
        const isOrgAdmin = currentMember?.isAdmin ?? false;
        this.setState({
            ...this.state,
            isOrgAdmin
        });
        return isOrgAdmin;
    }

    async revokeInvitation(invitationId): Promise<IResult<InvitationModel>> {
        try {
            await this.setState({
                ...this.state,
                isLoading: true
            })
            var result = await voicifyApi.revokeInvitation(invitationId);
            if(result.resultType == "Ok") {
                let invitations = [ ...this.state.invitations ];
                const idx = invitations.findIndex(i => i.id == invitationId);
                if(idx > -1) {
                    invitations.splice(idx, 1);
                    await this.setState({
                        ...this.state,
                        isLoading: false,
                        invitations: invitations
                    });

                }
            }
            else {
                await this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: result.errors
                })
            }
            return result;
        }
        catch (e) {
            await this.setState({
                ...this.state,
                isLoading: false,
                errors: [e?.toString()]
            });
            return {
                errors: ["Unexpected error"],
                data: null,
                resultType: "Unexpected"
            };
        }
    }

    resendInvitation(organizationid: string, invitation: InvitationModel) : Promise<IResult<InvitationModel>> {
        this.setState({
            ...this.state,
            isLoading: true
        })
        var promise = voicifyApi.revokeInvitation(invitation.id);
        promise.then(result => {            
            if(result.resultType == "Ok") {
                var newPromise = voicifyApi.sendInvitation(organizationid, {
                    email: invitation.email,
                    isAdmin: invitation.isAdmin,
                    isAddedToApplications: invitation.isAddedToApplications,
                    applicationRoleId: invitation.roleId
                });
                newPromise.then(result => {            
                    if(result.resultType == "Ok") {          
                        const index = this.state.invitations.findIndex(i => i.id == invitation.id);
                        const newInvitations = [...this.state.invitations]
                        newInvitations[index] = result.data;              
                        this.setState({
                            ...this.state,
                            isLoading: false,
                            errors: [],
                            invitations: newInvitations
                        })
                    }
                    else {
                        this.setState({
                            ...this.state,
                            isLoading: false,
                            errors: result.errors
                        })
                    }
                }).catch(error => {
                    this.setState({
                        ...this.state,
                        isLoading: false,
                        errors: [error]
                    })
                })
            }
            else {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    errors: result.errors
                })
            }
        }).catch(error => {
            this.setState({
                ...this.state,
                isLoading: false,
                errors: [error]
            })
        })
        return promise;
    }

    updateMemberRole(organizationMemberId: string, userRoleId: string) {
        var promise = voicifyApi.updateMemberRole(organizationMemberId, userRoleId);
        promise.then(result => {
            if(result.resultType == "Ok") {                 
                const index = this.state.members.findIndex(i => i.id == organizationMemberId);
                const newMembers = [...this.state.members]
                newMembers[index].isAdmin = result.data.isAdmin;              
                this.setState({
                    ...this.state,
                    errors: [],
                    members: newMembers
                })
            }
            else {
                this.setState({
                    ...this.state,
                    errors: result.errors
                })
            }
        }).catch(error =>{
            this.setState({
                ...this.state,
                errors: [error]
            })
        })
        return promise;
    }

    removeMemberFromOrg(organizationMemberId: string): Promise<IResult<any>> {
        this.setState({
            ...this.state,
            isUpdating: true
        })
        var promise = voicifyApi.removeMember(organizationMemberId);
        promise.then(result => {
            if(result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isUpdating: false,
                    members: this.state.members.filter(m => m.id != organizationMemberId)
                })                
            }
            else {
                this.setState({
                    ...this.state,
                    isUpdating: false,
                    errors: result.errors
                })
            }
        }).catch(error =>{
            this.setState({
                ...this.state,
                isUpdating: false,
                errors: [error]
            })
        })
        return promise;
    }

    leaveOrganization(organizationMemberId: string, organizationId: string): Promise<IResult<any>> {
        this.setState({
            ...this.state,
            isUpdating: true
        })
        var promise = voicifyApi.leaveOrganization(organizationId);
        promise.then(result => {
            if(result.resultType == "Ok") {
                this.setState({
                    ...this.state,
                    isUpdating: false,
                    members: this.state.members.filter(m => m.id != organizationMemberId)
                })                
            }
            else {
                this.setState({
                    ...this.state,
                    isUpdating: false,
                    errors: result.errors
                })
            }
        }).catch(error =>{
            this.setState({
                ...this.state,
                isUpdating: false,
                errors: [error]
            })
        })
        return promise;
    }

    private setLoadingMembers(isLoading: boolean) {
        this.setState({
            ...this.state,
            isLoadingMembers: isLoading
        });
    }

    private setLoading(isLoading: boolean) {
        this.setState({
            ...this.state,
            isLoading: isLoading
        });
    }
}