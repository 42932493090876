import React, { useEffect } from 'react';
import { css } from '@emotion/css';
import AuthContainer from '../../../state/containers/AuthContainer';
import { withFormik, FormikProps } from 'formik';
import * as yup from 'yup';
import TextField from '../../../components/forms/TextField';
import Button from '../../../components/general/Button';
import UserContainer from '../../../state/containers/UserContainer';
import CheckboxField from '../../../components/forms/CheckboxField';
import SingleSignOnRequest from '../../../models/auth/api/SingleSignOnRequest';
import PageError from '../../../components/general/PageError';
import TermsContainer from '../../../hooks/TermsContainer';
const expandIcon = require('../../../content/images/expand-white.svg');

interface RegisterFormData {
    firstName?: string;
    lastName?: string;
    email?: string;
    termsAgreed?: boolean;
}

interface RegisterFormProps {
    stateContainer: UserContainer;
    authContainer: AuthContainer;
    invitationCode?: string;
}

const InnerForm: React.FC<RegisterFormProps & FormikProps<RegisterFormData>> = ({ handleSubmit, isSubmitting, values, handleChange, handleBlur, stateContainer, authContainer }) => {
    const termsHook = TermsContainer.useContainer()
    useEffect(() => {
        termsHook.loadLatestTerm();
    }, []);

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div>
                    <TextField name="firstName" value={values.firstName} label="First Name" placeholder="What name do you go by?" onChange={handleChange} onBlur={handleBlur} disabled={isSubmitting} />
                    <TextField name="lastName" value={values.lastName} label="Last Name" placeholder="What's your family name?" onChange={handleChange} onBlur={handleBlur} disabled={isSubmitting} />
                    <TextField name="email" value={values.email} label="Email" placeholder="Enter the email the invitation was sent to" onChange={handleChange} onBlur={handleBlur} disabled={isSubmitting} />
                    <div className={termsContainer}>
                        <a target={"_blank"} href={termsHook.latestTerm.url} className={`row titleRow`}>
                            <span className="titleText">{`Terms & Conditions of use`}</span>
                            <img src={expandIcon} />
                        </a>
                        <div className={`row checkRow`}>
                            <CheckboxField name="termsAgreed" onChange={handleChange} onBlur={handleBlur} disabled={isSubmitting} checked={values.termsAgreed} required={true} />
                            <span className="acceptText">{`I accept and agree to the terms and conditions`}</span>
                        </div>
                    </div>
                </div>
                <Button themes={["primary", "wide"]} type="submit" disabled={isSubmitting} text="Continue" additionalClasses={buttonSplitStyle} />
            </form>
            <PageError errors={[...stateContainer.state.errors, ...authContainer.state.errors]} />
        </div>
    );
};

const RegisterForm = withFormik<RegisterFormProps, RegisterFormData>({
    mapPropsToValues: props => ({
        firstName: '',
        lastName: '',
        email: '',
        termsAgreed: false
    }),
    validationSchema: yup.object().shape({
        firstName: yup.string().required("You must enter your first name"),
        lastName: yup.string().required("You must enter your last name"),
        email: yup.string().email("Must be a valid email").required("You must enter your email"),
        termsAgreed: yup.bool().oneOf([true], "You must agree to the Terms & Conditions"),
    }),
    handleSubmit: async (values, { props, setSubmitting }) => {
        setSubmitting(true);
        if (props.invitationCode === undefined || props.invitationCode === null || props.invitationCode === '') {
            props.authContainer.addError("Invalid invitation code");
            setSubmitting(false);
            return;
        }
        const result = await props.stateContainer.checkIfUserUsesSso(values.email)
        if (result.data) { //this user uses sso                    
            const ssoRequest: SingleSignOnRequest = {
                email: values.email,
                loginCompleteUrl: `${window.location.origin.toString()}/singleSignOnSignUpRedirect/${props.invitationCode}`,
                agreedToTerms: values.termsAgreed,
                firstName: values.firstName,
                lastName: values.lastName
            }
            await props.authContainer.beginSingleSignOnSignUp(ssoRequest)
            setSubmitting(false);
        } else {
            props.stateContainer.promptUserToCreatePassword(values.email, values.firstName, values.lastName, values.termsAgreed);
            setSubmitting(false);
        }
    }
})(InnerForm);

const buttonSplitStyle = css`
    margin-bottom: -56px;
`;

const termsContainer = css`
    border: solid 1px white;
    border-radius: 8px;
    width: 100%;
    height: 100px;
    font-family: Muli;
    font-style: normal;
    font-stretch: normal;
    color: white;
    background: transparent;
    .row {
        display: flex;
        flex-direction: row;
        padding: 20px;
        .acceptText{
            padding-top: 4px;
            position: absolute;
            left: 180px;
        }
        .titleText{            
            padding-top: 4px;
            color: white;
            padding-right: 5px;
        }
    }
    .titleRow {
        font-size: 16px;    
        padding-bottom: 0px;
    }
    .checkRow {
        font-size: 14px;
    }
`

export default RegisterForm;