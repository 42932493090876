import React from 'react';
import { css } from '@emotion/css';
import AuthContainer from '../../../state/containers/AuthContainer';
import { withFormik, FormikProps } from 'formik';
import * as yup from 'yup';
import TextField from '../../../components/forms/TextField';
import Button from '../../../components/general/Button';
import UserContainer from '../../../state/containers/UserContainer';
import PageError from '../../../components/general/PageError';


interface CreatePasswordFormData {
    password?: string;
    confirmPassword?: string;
}

interface CreatePasswordFormProps {
    stateContainer: UserContainer;
    authContainer: AuthContainer;
    invitationCode?: string;
}

const InnerForm: React.FC<CreatePasswordFormProps & FormikProps<CreatePasswordFormData>> = ({ handleSubmit, isSubmitting, values, handleChange, handleBlur, stateContainer, authContainer }) => (
    <div>
        <form onSubmit={handleSubmit}>
            <TextField name="password" type="password" value={values.password} label="Password" placeholder="It's a secret to everybody" onChange={handleChange} onBlur={handleBlur} disabled={isSubmitting} />
            <TextField name="confirmPassword" type="password" value={values.confirmPassword} label="Confirm Password" placeholder="One more time" onChange={handleChange} onBlur={handleBlur} disabled={isSubmitting} />
            <Button themes={["primary", "wide"]} type="submit" disabled={isSubmitting} text="Let's Go!" additionalClasses={buttonSplitStyle} />
        </form>
        <PageError errors={[...stateContainer.state.errors, ...authContainer.state.errors]} />
    </div>
);

const CreatePasswordForm = withFormik<CreatePasswordFormProps, CreatePasswordFormData>({
    mapPropsToValues: props => ({
        password: '',
        confirmPassword: '',
    }),
    validationSchema: yup.object().shape({
        password: yup.string().required("You must enter your password"),
        confirmPassword: yup.string().required("You must confirm your password")
    }),
    handleSubmit: async (values, { props, setSubmitting }) => {
        if (props.invitationCode === undefined || props.invitationCode === null || props.invitationCode === '') {
            props.authContainer.addError("Invalid invitation code");
            setSubmitting(false);
            return;
        }
        const userState = props.stateContainer.state.userRegistrationInformation;
        const result = await props.stateContainer.signUp({
            firstName: userState.firstName,
            lastName: userState.lastName,
            email: userState.email,
            password: values.password,
            confirmPassword: values.confirmPassword,
            agreedToTerms: userState.termsAgreed
        })
        setSubmitting(false);
        if (result.resultType == "Ok") {
            const authResult = await props.authContainer.signIn(userState.email, values.password)
            if (authResult.resultType == "Ok") {
                props.stateContainer.acceptInvitation(props.invitationCode);
            }
        }
    }
})(InnerForm);

const buttonSplitStyle = css`
    margin-bottom: -56px;
`;

const termsContainer = css`
    border: solid 1px white;
    border-radius: 8px;
    width: 100%;
    height: 100px;
    font-family: Muli;
    font-style: normal;
    font-stretch: normal;
    color: white;
    background: transparent;
    .row {
        display: flex;
        flex-direction: row;
        padding: 20px;
        .acceptText{
            padding-top: 4px;
            position: absolute;
            left: 180px;
        }
        .titleText{            
            padding-top: 4px;
            color: white;
            padding-right: 5px;
        }
    }
    .titleRow {
        font-size: 16px;    
        padding-bottom: 0px;
    }
    .checkRow {
        font-size: 14px;
    }
`

export default CreatePasswordForm;