import * as yup from 'yup';
import { withFormik } from "formik";
import SparkTelephonyDetails from './SparkTelephonyDetails';
import { TelephonyDeploymentResponse } from '../../../../shared/models/telephonyDeployment/TelephonyDeploymentResponse';
import TelephonyDeploymentRequest from '../../../../shared/models/telephonyDeployment/TelephonyDeploymentRequest';
import { female1Voice } from '../../../../shared/constants/sparkConstants/voices';

export interface SparkTelephonyDetailsProps {
    selectedTelephonyDeployment: TelephonyDeploymentResponse
    handleSubmitTelephonyForm: (phoneNumber: string, telephonyDeploymentRequest: TelephonyDeploymentRequest) => void
    isLoadingTelephonyDeployments: boolean
    getVoice: (ttsVoice: string) => string
    loadTelephonyDeployments: () => void
}

export interface SparkTelephonyDetailsFormData {
    callRecordingEnabled: boolean
    ttsVoice: string
    virtualNumber?: string
}

const SparkTelephonyDetailsForm = withFormik<SparkTelephonyDetailsProps, SparkTelephonyDetailsFormData>({
    mapPropsToValues: (props) => ({
        callRecordingEnabled: props.selectedTelephonyDeployment?.callRecordingEnabled ?? false,
        ttsVoice: props.getVoice(props.selectedTelephonyDeployment?.ttsVoice) ?? female1Voice,
        virtualNumber: props.selectedTelephonyDeployment?.virtualNumber
    }),
    validationSchema: yup.object().shape({
        callRecordingEnabled: yup.boolean().required(),
        ttsVoice: yup.string().required(),
        virtualNumber: yup.string().required().max(50)
    }),
    handleSubmit: async (values, { props, setSubmitting }) => {
        setSubmitting(true);
        const telephonyDeploymentRequest: TelephonyDeploymentRequest = {
            noTracking: props.selectedTelephonyDeployment.noTracking,
            phoneNumberStartsWith: props.selectedTelephonyDeployment.phoneNumber[0] + props.selectedTelephonyDeployment.phoneNumber[1] + props.selectedTelephonyDeployment.phoneNumber[2],
            environmentId: props.selectedTelephonyDeployment.environmentId,
            ttsProvider: props.selectedTelephonyDeployment.ttsProvider,
            ttsVoice: values.ttsVoice,
            locale: props.selectedTelephonyDeployment.locale,
            asrEndpointing: props.selectedTelephonyDeployment.asrEndpointing,
            asrModel: props.selectedTelephonyDeployment.asrModel,
            asrTier: props.selectedTelephonyDeployment.asrTier,
            firstMessageDelayMS: props.selectedTelephonyDeployment.firstMessageDelayMS,
            mergeTurnsAfterPauses: props.selectedTelephonyDeployment.mergeTurnsAfterPauses,
            mergeTurnTimeoutMS: props.selectedTelephonyDeployment.mergeTurnTimeoutMS,
            disableBargeIn: props.selectedTelephonyDeployment.disableBargeIn,
            repromptAttempts: props.selectedTelephonyDeployment.repromptAttempts,
            repromptDelaySeconds: props.selectedTelephonyDeployment.repromptDelaySeconds,
            name: props.selectedTelephonyDeployment.name,
            proactiveResponseDelayMS: props.selectedTelephonyDeployment.proactiveResponseDelayMS,
            proactiveResponses: props.selectedTelephonyDeployment.proactiveResponses,
            useProactiveResponses: props.selectedTelephonyDeployment.useProactiveResponses,
            disableWelcomeMessageBargeIn: props.selectedTelephonyDeployment.disableWelcomeMessageBargeIn,
            disableSpeechStarted: props.selectedTelephonyDeployment.disableSpeechStarted,
            speechStartedUnconfirmedBargeInLimit: props.selectedTelephonyDeployment.speechStartedUnconfirmedBargeInLimit,
            speechStartedPauseMS: props.selectedTelephonyDeployment.speechStartedPauseMS,
            speechStartedRequiredBufferCount: props.selectedTelephonyDeployment.speechStartedRequiredBufferCount,
            callRecordingEnabled: values.callRecordingEnabled,
            speechStartedLevel: props.selectedTelephonyDeployment.speechStartedLevel,
            virtualNumber: values.virtualNumber
        }
        props.handleSubmitTelephonyForm(props.selectedTelephonyDeployment.phoneNumber, telephonyDeploymentRequest);
        setSubmitting(false)
    }
})(SparkTelephonyDetails);

export default SparkTelephonyDetailsForm;