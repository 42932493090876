import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/css';
import TableCell from '../../../components/general/TableCell';
import { color_colors_ocean, color_text_link } from '../../../constants/colors';
import TopContentMissModel from '../../../models/analytics/api/TopContentMissModel';
import FeatureContainer from '../../../state/containers/FeatureContainer';
import ReportTable from '../../../components/analytics/ReportTable';
import ReportTableHeader from '../../../components/analytics/ReportTableHeader';
import MissStatisticsModel from '../../../models/analytics/MissStatisticsModel';
import { getSmallFeatureTypeIcon } from '../../../models/extensions';
import TotalCard from '../../../components/charts/TotalCard';
import SparkContainer from '../../../hooks/SparkContainer';
import styled from '@emotion/styled';
import SessionAnalyticsContainerOld from '../../../hooks/SessionAnalyticsContainerOld';
import AnalyticsRequestFilter from '../../../models/analytics/AnalyticsRequestFilter';
import { FallbackFeatureTypeId } from '../../../constants/featureTypeIds';
import ContentHitEvent from '../../../models/analytics/api/ContentHitEvent';
import { CellInfo } from 'react-table';
import { getBaseUrl, getSparkBaseUrl } from '../../../constants/Urls';
import Loader from '../../../components/general/Loader';
import ApplicationStatisticsContainer from '../../../state/containers/ApplicationStatisticsContainer';
import { filterChanged } from './ApplicationStatistics';
import SecondaryLoader from '../../../components/general/SecondaryLoader';
import AnalyticsEmptyView from '../../../components/analytics/AnalyticsEmptyView';
import EmptyView from '../../applicationModules/components/EmptyView';
import ApplicationEnvironmentsContainer from '../../../state/containers/ApplicationEnvironmentsContainer';
import TooltipWrapper from '../../../components/general/TooltipWrapper';

const missIconBlue = require('../../../content/images/spark/miss.svg');

interface ApplicationTopMissesTableProps {
    data: TopContentMissModel
    totals: MissStatisticsModel
    previousTotals: MissStatisticsModel
    featureContainer: FeatureContainer
    isLoading: boolean
    startDate: string
    endDate: string
    showValue: boolean
    showPercentage: boolean
    filter: AnalyticsRequestFilter
    applicationId: string
    statisticsContainer: ApplicationStatisticsContainer
    interactionsCount: number
    aggregatedFeatureTypeInteractionsLoaded: boolean
    envContainer: ApplicationEnvironmentsContainer
}

interface MissRowItem {
    title: string,
    featureTypeId: string
    slots: any,
    count: number
}

interface SparkMissRowItem {
    title: string,
    link: string,
    count: number,
}

const ApplicationTopMissesTable: React.FC<ApplicationTopMissesTableProps> = (props) => {
    const [missesLoaded, setMissesLoaded] = useState(false);
    const [misses, setMisses] = useState<ContentHitEvent[]>([]);
    const [missesWithCountsAndLinks, setMissesWithCountsAndLinks] = useState({} as { [key: string]: { link: string, count: number } });
    const prevFilter = useRef<AnalyticsRequestFilter>();

    const sparkContainer = SparkContainer.useContainer();
    const sessionContainer = SessionAnalyticsContainerOld.useContainer();

    const percentMisses = (props.interactionsCount > 0 ? Math.round((misses.length / props.interactionsCount) * 100) : 0);

    const loadMoreMisses = async () => {
        await sessionContainer.loadMoreContentByFeatureType(props.applicationId, props.filter, FallbackFeatureTypeId);
    };

    useEffect(() => {
        if (!props.applicationId?.length || !props.filter?.startDate || !props.filter?.endDate) return;

        if (!prevFilter.current || filterChanged(prevFilter.current, props.filter)) {
            loadMoreMisses()
        }
        prevFilter.current = props.filter;
    }, [props.applicationId, props.filter])

    useEffect(() => {
        if (sessionContainer.moreHitsByFeatureTypeToLoad || !sessionContainer.featureTypeContentHits)
            return;
        const fallbacks: ContentHitEvent[] = sessionContainer.featureTypeContentHits;

        const uniqueFallbacks: ContentHitEvent[] = [];

        for (const fallback of fallbacks) {
            const fallbackEventDate = new Date(fallback.eventDate);        
            if (!uniqueFallbacks.find((f) => f.requestId === fallback.requestId)) {
                uniqueFallbacks.push(fallback);
            } else {
                const existingFallback = uniqueFallbacks.find((u) => u.requestId === fallback.requestId);
                const existingFallbackEventDate = new Date(existingFallback.eventDate);
                if (existingFallback && fallbackEventDate > existingFallbackEventDate) {
                    const index = uniqueFallbacks.indexOf(existingFallback);
                    uniqueFallbacks[index] = fallback;
                }
            }
        };

        setMissesLoaded(true);
        setMisses(uniqueFallbacks);
        const uniqueTopics = [];
        const uniqueTopicsWithCount = {};
        for (const fallback of uniqueFallbacks) {
            if (!fallback.originalRequest?.originalInput) continue;
            let baseUrl: string;
            sparkContainer.inSpark ? baseUrl = getBaseUrl() : baseUrl = getSparkBaseUrl();
            const sessionLink = `${baseUrl}/v/apps/${props.applicationId}/analytics/sessions?sessionId=${fallback.sessionId}&startDate=${props.startDate}&endDate=${props.endDate}`
            if (uniqueTopics.includes(fallback.originalRequest.originalInput)) {
                uniqueTopicsWithCount[fallback.originalRequest.originalInput] = { link: [...uniqueTopicsWithCount[fallback.originalRequest.originalInput].link, sessionLink], count: uniqueTopicsWithCount[fallback.originalRequest.originalInput].count + 1 };
            } else {
                uniqueTopics.push(fallback.originalRequest.originalInput);
                uniqueTopicsWithCount[fallback.originalRequest.originalInput] = { link: [sessionLink], count: 1 };
            }
        }
        setMissesWithCountsAndLinks(uniqueTopicsWithCount);
    }, [sessionContainer.featureTypeContentHits, sessionContainer.moreHitsByFeatureTypeToLoad])

    const getRowItems = () => {
        const items = [] as SparkMissRowItem[]
        const topics = Object.keys(missesWithCountsAndLinks);
        const countsAndLinks = Object.values(missesWithCountsAndLinks);
        topics.forEach((topic, index) => {
            items.push({
                title: topic,
                link: countsAndLinks[index].link,
                count: countsAndLinks[index].count
            })
        })
        items.sort((a, b) => {
            if (a.count === b.count) {
                return a.title.localeCompare(b.title);
            }
            return b.count - a.count;
        });
        return items;
    };

    const columns = [
        {
            id: "title",
            Header: <ReportTableHeader
                title="What Callers Said"
                tooltip="This could be a fragment of what the caller said if they paused in between words, and therefore may not be a miss. Review the linked transcripts to get the full context of the conversation."
            />,
            accessor: (item: MissRowItem) => item,
            Cell: (row: CellInfo) => (
                <TableCell>
                    <img className="feature-type-icon" src={getSmallFeatureTypeIcon(FallbackFeatureTypeId)} />
                    <p>{row.value.title ?? "Unknown"}</p>
                </TableCell>
            ),
            style: { 'whiteSpace': 'unset' },
            minWidth: "50%"
        },
        {
            id: "count",
            Header: <ReportTableHeader
                title='# Times'
                tooltip="If the total in this column doesn't match your miss count, it's because detailed analytics were disabled for a period of time."
            />,
            accessor: (item: MissRowItem) => item,
            Cell: (row: CellInfo) => {
                return (
                    <TableCell>
                        {row.value.count}
                    </TableCell>
                )
            },
            minWidth: "10%"
        },
        {
            id: "link",
            Header: <ReportTableHeader title='Full Conversation Transcript' />,
            accessor: (item: MissRowItem) => item,
            Cell: (row: CellInfo) => {
                const links = row.value.link || [];
                return (
                    <TranscriptCell>
                        {links.map((link: string, index: number) => (
                            <div key={index}>
                                <StyledLink href={link} target="_blank" key={index}>{links.length === 1 ? 'Transcript' : `Transcript ${index + 1}`}</StyledLink>
                                &nbsp;
                                &nbsp;
                            </div>
                        ))}
                    </TranscriptCell>
                )
            },
            style: { 'whiteSpace': 'unset' },
            minWidth: "40%"
        }
    ];

    return (
        <div className={containerStyle}>
            {
                props.isLoading || (!sparkContainer.inSpark && !props.data) || (!props.aggregatedFeatureTypeInteractionsLoaded || !missesLoaded) ?
                    sparkContainer.inSpark ? <div className="loader-container"><Loader /></div> : <div className="loader-container"><SecondaryLoader /></div>
                    :
                    <>
                        <div className="spark-summary-wrapper">
                            <StyledTotalCard title="Miss Count"
                                value={misses.length}
                                showValue={props.showValue}
                                showPercentage={false}
                                icon={missIconBlue}
                            />
                            <StyledTotalCard title="Miss %"
                                value={percentMisses}
                                showValueAsPercent={true}
                                showValue={props.showValue}
                                showPercentage={false}
                                icon={missIconBlue}
                            />
                        </div>
                        {
                            !props.envContainer.state.environments?.[0]?.detailedAnalyticsEnabled ?
                                <EmptyView
                                    wrapperStyle={emptyViewWrapperStyle}
                                    noImage={true}
                                    title="Miss Details Unavailable"
                                    description="Go to Settings and enable detailed analytics to see additional insights."
                                />
                                :
                                <ReportTable
                                    columns={columns}
                                    data={getRowItems()}
                                    showPagination={false}
                                />
                        }
                    </>
            }
        </div >
    );
}

const emptyViewWrapperStyle = css`
    padding: 32px;
`;

const StyledLink = styled.a`
    color: ${color_text_link};
`;

const TranscriptCell = styled(TableCell)`
    display: flex;
    flex-wrap: wrap;
    line-height: 3;
`;

const StyledTotalCard = styled(TotalCard)`
    padding: 32px;
    height: auto;
`;

const containerStyle = css`
.feature-type-icon {
    margin-right: 12px;
    width: 24px;
    height: 24px;
}
.loader-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    .spinner > div {
        background: ${color_colors_ocean};
    }
}
.spark-summary-wrapper {
    display: flex;
    flex: 1;
    margin: 0 16px;
    padding: 16px 0;
}
.totals-container{
    display: flex;
    flex: 1;
    margin: 0 16px;
    padding-bottom: 16px;
}
>h3 {
    margin: 16px 32px;
}
`

export default ApplicationTopMissesTable;