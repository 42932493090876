import React from 'react';
import { silver_four, text_blue } from '../../constants/colors';
import styled from '@emotion/styled';
import MenuCollapseToggle from './MenuCollapseToggle';

interface ExpandedMenuPanelProps {
    onCollapse: (e) => void
    expandedViewRender: () => JSX.Element
}

const ExpandedMenuPanel: React.FC<ExpandedMenuPanelProps> = (props) => {
    return (
        <ExpandedMenuPanelWrapper>
            <HeaderWrapper>
                <MenuCollapseToggle isMinified={false} onToggle={props.onCollapse} />
            </HeaderWrapper>
            <MenuWrapper>
                {props.expandedViewRender()}
            </MenuWrapper>
        </ExpandedMenuPanelWrapper>
    )
};

const HeaderWrapper = styled.div`
    position: relative;
    width:288px;
    display: flex;
    align-items: center;
`;

const MenuWrapper = styled.div``;

const ExpandedMenuPanelWrapper = styled.div`
    width: 288px;
    border-right: 1px solid ${silver_four};
    .panel-header {
        max-width:288px;
    }
    .menu-toggle {
        width: 80px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
            width: 24px;
            height: 24px;
        }
    }
    .highlighted {
        .inner-toggle {
            background: ${text_blue};
        }
    }
`;

export default ExpandedMenuPanel;