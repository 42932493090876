import React from 'react';
import { css } from '@emotion/css';
import { color_shades_dark, color_text_default } from '../../constants/colors';
import ToggleIndicator from '../general/ToggleIndicator';

const onHoverIcon = require('../../content/images/moveable-icon.svg');

// used for containing data in the form style card
const cardStyle = css`
    &.draggable {
        /* class Draggable is setting the whole component's cursor to drag, which is what we want for simple small size list elements
        However when expanded response's content is fairly complex and has some custom cursors on child elements
        Need to set cursor to auto otherwise Draggable component will overrride all content's cursor to drag */
        cursor: auto;
    }

    &.draggable:hover .card-hover-icon {
        visibility: visible;
    }

    &.draggable:hover .card-title-container {
        cursor: grab;
        >img {
            cursor: pointer;
        }
        >h4 {
            cursor: pointer;
        }
    }

    &.dragging {
        border: 1px solid ${color_shades_dark};
        .card-title-container .card-hover-icon {
            visibility: visible;
        }
    }

    border-top: solid 1px ${color_shades_dark};
    flex-grow: 0;

    .card-title-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: solid 1px ${color_shades_dark};
        padding: 24px 24px 24px 0;
        cursor: pointer;
        background: white;
        >img {
            width: 24px;
            height: 24px;
        }
        >h4 {
            font-family: Muli;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            color: ${color_text_default};
            margin: 0 10px;
        }

        .toggle-indicator {
            margin: 0 12px 0 0;
        }

        .card-hover-icon {
            width: 8px;
            height: 24px;
            margin: 0 2px;
            visibility: hidden;
        }
    }

    .card-body {
        padding: 32px;
        &.collapsed {
            padding: 0;
            display: none;
        }
    }
`;

interface ContentCollapsingProps {
    title?: string
    icon?: string
    id?: string,
    className?: string
    titleRightRowComponent?: React.ReactNode
    index?: number
    isCollapsedByDefault?: boolean
}
interface ContentCollapsingState {
    isCollapsed: boolean
}
class ContentCollapsingSection extends React.Component<ContentCollapsingProps, ContentCollapsingState> {
    constructor(props) {
        super(props)
        this.state = {
            isCollapsed: this.props.isCollapsedByDefault ?? false
        }
    }
    toggleCollapse() {
        this.setState({
            ...this.state,
            isCollapsed: !this.state.isCollapsed
        })
    }
    render() {
        const props = this.props;
        return (
            <div className={`${cardStyle} ${props.className ?? ''}`}>
                <div className='card-title-container' onClick={this.toggleCollapse.bind(this)}>
                    <img className='card-hover-icon' src={onHoverIcon} />
                    <ToggleIndicator direction={this.state.isCollapsed ? 'up' : 'down'} />
                    {props.icon && <img src={props.icon} />}
                    <h4><a id={props.id ?? props.title?.replace(' ', '')}>{props.title}</a></h4>
                    {
                        this.props.titleRightRowComponent ? this.props.titleRightRowComponent : null 
                    }
                </div>
                <div className={`card-body ${this.state.isCollapsed ? 'collapsed' : 'expanded'}`}>
                    {props.children}
                </div>
            </div>
        );
    }
}

export default ContentCollapsingSection;