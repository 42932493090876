import React from 'react';
import { css } from '@emotion/css';
import { Subscribe } from "unstated";
import TemplateFormSectionModel from '../../../../shared/models/templating/api/TemplateFormSectionModel';
import TemplateFormFieldModel from '../../../../shared/models/templating/api/TemplateFormFieldModel';
import TemplateFormFieldValueModel from '../../../../shared/models/templating/api/TemplateFormFieldValueModel';
import CheckboxField from '../../../../shared/components/forms/CheckboxField';
import TextField from '../../../../shared/components/forms/TextField';
import TextAreaField from '../../../../shared/components/forms/TextAreaField';
import SsmlField from '../../../../shared/components/forms/SsmlField/SsmlField';
import SelectField from '../../../../shared/components/forms/SelectField';
import CheckboxGroup from '../../../../shared/components/forms/CheckboxGroup';
import RadioGroup from '../../../../shared/components/forms/RadioGroup';
import MediaContainer from '../../../../shared/state/containers/MediaContainer';
import FileUploadField from '../../../../shared/components/forms/FileUploadField';
import FormFieldCard from '../../../../shared/components/forms/FormFieldCard';
import PanelContainer from '../../../../shared/components/structure/Panels/PanelContainer';
import ScrollablePanelBody from '../../../../shared/components/structure/ScrollablePanelBody';
import { color_shades_dark, color_shades_lighter } from '../../../../shared/constants/colors';
import Switch from '../../../../shared/components/forms/Switch';
import Button from '../../../../shared/components/general/Button';
import AppliedApplicationTemplateContainer from '../../../../shared/state/containers/AppliedApplicationTemplateContainer';
import AppliedApplicationTemplateModel from '../../../../shared/models/templating/api/AppliedApplicationTemplateModel';
import styled from '@emotion/styled';
import TemplateInstructionsPanel from './TemplateInstructionsPanel';
import { breakpoint_small } from '../../../../shared/constants/breakpoints';
import SparkContainer from '../../../../shared/hooks/SparkContainer';
import TemplateFieldType from '../../../../shared/models/templating/TemplateFieldType';
import { getAIAnsweringModuleId, getFoodOrderingModuleId } from '../../../../shared/constants/ModuleConsts';

interface AppliedApplicationTemplateFormProps {
    section: TemplateFormSectionModel
    onFieldValueChange: (field: TemplateFormFieldModel, value: string) => void
    applicationId: string
    values?: TemplateFormFieldValueModel[]
    fieldsOnly?: boolean
    preview?: boolean
    appliedAppTemplateContainer: AppliedApplicationTemplateContainer
    appliedAppTemplate: AppliedApplicationTemplateModel
    combinedRequiredFields: TemplateFormFieldModel[]
    completeFields: TemplateFormFieldModel[]
    submitForm: () => void
    onPrevious: () => void
    recentlyAddedModuleFormFields?: TemplateFormFieldModel[]
}

const AppliedApplicationTemplateSectionForm: React.FC<AppliedApplicationTemplateFormProps> = (props) => {

    const sparkContainer = SparkContainer.useContainer();

    const isRecentlyAddedModuleFormField = (field: TemplateFormFieldModel) => {
        return props.recentlyAddedModuleFormFields?.some(f => f.id == field.id);
    };

    const getValue = (field: TemplateFormFieldModel) => {
        const foundField = props.values?.find(v => (field?.name && v?.dynamicFormFieldName == field?.name) || (field?.id && v?.templateFormFieldId == field?.id));
        return foundField?.value;
    };

    const getFieldName = (field: TemplateFormFieldModel): string => {
        return field.name ?? field.id;
    };

    const handleChange = (field: TemplateFormFieldModel, value) => {
        props.onFieldValueChange(field, value);
    };

    const renderCheckbox = (field: TemplateFormFieldModel) => {
        return (
            <div>
                <div className={label}>{field.label}</div>
                <CheckboxField
                    key={field.id ?? field.name}
                    name={`fields.${getFieldName(field)}`}
                    checked={getValue(field) == 'true'}
                    onChange={(e) => handleChange(field, e.target.checked.toString())}
                    className={checkboxField} />
            </div>
        )
    };

    const renderSwitch = (field: TemplateFormFieldModel) => {
        return (
            <div>
                <div className={label}>{field.label}</div>
                <Switch
                    className={switchContainer}
                    key={field.id ?? field.name}
                    name={`fields.${getFieldName(field)}`}
                    onChange={(checked) => handleChange(field, checked.toString())}
                    checked={getValue(field) == 'true'}
                    label={field.label}
                />
            </div>
        )
    };

    const renderShortText = (field: TemplateFormFieldModel, type?: string) => {
        return (
            <TextField
                key={field.id ?? field.name}
                name={`fields.${getFieldName(field)}`}
                value={getValue(field) ?? field.defaultValue ?? ''}
                type={type}
                onChange={(e) => handleChange(field, e.target.value)}
                onBlur={() => { }}
                label={field.label}
                placeholder={field.placeholder} />
        )
    };

    const renderLongText = (field: TemplateFormFieldModel) => {
        return (
            <TextAreaField
                key={field.id ?? field.name}
                name={`fields.${getFieldName(field)}`}
                value={getValue(field) ?? field.defaultValue ?? ''}
                onChange={(e) => handleChange(field, e.target.value)}
                onBlur={() => { }}
                label={field.label}
                placeholder={field.placeholder} />
        )
    };

    const renderSsmlField = (field: TemplateFormFieldModel) => {
        return (
            <SsmlField
                key={field.id ?? field.name}
                name={`fields.${getFieldName(field)}`}
                value={getValue(field) ?? field.defaultValue ?? ''}
                onChange={(value) => handleChange(field, value)}
                onBlur={() => { }}
                label={field.label}
                placeholder={field.placeholder}
                applicationId={props.applicationId} />
        )
    };

    const renderSelect = (field: TemplateFormFieldModel) => {
        return (
            <SelectField
                key={field.id ?? field.name}
                className={selectField}
                onChange={(e: any) => handleChange(field, e.value)}
                onBlur={() => { }}
                name={`fields.${getFieldName(field)}`}
                value={getValue(field)}
                label={field.label}
                placeholder={field.placeholder}
                options={field.options.map(o => ({ value: o.value, label: o.label }))}
            />
        )
    };

    const renderCheckboxGroup = (field: TemplateFormFieldModel) => {
        // note: checkboxes use pipe delimited values from the core value
        return (
            <CheckboxGroup
                key={field.id ?? field.name}
                onChange={(e, c) => {
                    var val = getValue(field);
                    if (c) {
                        val += `|${e.value}`;
                    } else {
                        val = val.replace(e.value, '');
                    }
                    handleChange(field, val)
                }}
                values={getValue(field)?.split('|')}
                options={field.options.map(o => ({ value: o.value, label: o.label }))}
            />
        )
    };

    const renderRadioGroup = (field: TemplateFormFieldModel) => {
        return (
            <RadioGroup
                key={field.id ?? field.name}
                onChange={(e) => handleChange(field, e.value)}
                value={getValue(field)}
                options={field.options.map(o => ({ value: o.value, label: o.label }))}
            />
        )
    };

    const renderFileUpload = (field: TemplateFormFieldModel) => {
        return (
            <Subscribe to={[MediaContainer]}>
                {(mediaContainer: MediaContainer) => (
                    <FileUploadField
                        onChange={(e) => handleChange(field, e.url)}
                        disabled={false}
                        field={field}
                        mediaContainer={mediaContainer}
                        applicationId={props.applicationId}
                        value={getValue(field)}
                        preview={props.preview}
                    />
                )}
            </Subscribe>
        )
    };

    const renderSetupProperty = (field: TemplateFormFieldModel) => {
        switch (field.fieldType) {
            case "ShortTextField":
                return renderShortText(field);
            case "NumberTextField":
                return renderShortText(field, 'number');
            case "DateTextField":
                return renderShortText(field, 'date');
            case "LongTextField":
                return renderLongText(field);
            case "SwitchField":
                return renderSwitch(field);
            case "SelectField":
                return renderSelect(field);
            case "CheckboxGroupField":
                return renderCheckboxGroup(field);
            case "RadioGroupField":
                return renderRadioGroup(field);
            case "CheckboxField":
                return renderCheckbox(field);
            case "FileUploadField":
                return renderFileUpload(field);
            case "SSMLField":
            case "SSMLTextField":
                return renderSsmlField(field);
        }
    };

    const renderFields = () => {
        let fields = props.section?.templateFormFields ?? props.section?.dynamicFormFields ?? [];
        const fieldTypesToFilterOutInSpark: TemplateFieldType | string[] = [
            "ShortTextField", "LongTextField", "SSMLTextField", "NumberTextField", "DateTextField", "SelectField"
        ]
        if (sparkContainer.inSpark
            && (props.appliedAppTemplate?.templateForm?.templateConfigurationId === getAIAnsweringModuleId() || props.appliedAppTemplate?.templateForm?.templateConfigurationId === getFoodOrderingModuleId())) {
            fields = fields.filter(f => {
                // filter out fields that are associated with a variable and have the same value as the variable
                if (f.associatedVariable === getValue(f) && fieldTypesToFilterOutInSpark.includes(f.fieldType)) {
                    return false;
                }
                return true;
            })
        };

        return (
            <div className={setupSectionContainer}>
                <h3>{props.section.title}</h3>
                {fields.map((p, subIdx) => {
                    return (
                        <div key={`setupSection_${subIdx}`} className={setupPropertyContainer}>
                            <FormFieldCard
                                title={p.title}
                                tip={p.tip}
                                required={p.isRequired}
                                isRecentlyAddedModuleFormField={isRecentlyAddedModuleFormField(p)}
                            >
                                {renderSetupProperty(p)}
                            </FormFieldCard>
                        </div>
                    )
                })}
            </div>
        )
    };

    const isLoading = props.appliedAppTemplateContainer.state.isLoading || props.appliedAppTemplateContainer.state.isSaving;

    return (
        <ScrollablePanelBody autoHeight={true} autoHeightMax={"100vh - 136px"}>
            <SectionFormWrapper>
                <PanelContainer className={panelContainerStyle}>
                    <PanelWrapper>
                        {renderFields()}
                        <ButtonWrapper>
                            <CustomButton
                                disabled={
                                    props.appliedAppTemplate?.templateForm?.templateConfiguration
                                        ?.dynamicFormUrl != null &&
                                    props.combinedRequiredFields.length !=
                                    props.completeFields.length
                                }
                                loading={isLoading}
                                themes={["primary"]}
                                text={
                                    props.appliedAppTemplate?.templateForm?.templateConfiguration
                                        ?.dynamicFormUrl
                                        ? "Next"
                                        : "Save & Next"
                                }
                                type="button"
                                onClick={() => {
                                    props.submitForm();
                                }}
                            />
                        </ButtonWrapper>
                    </PanelWrapper>
                    {props.section.instructionsMarkdown && (
                        <PanelWrapper>
                            <TemplateInstructionsPanel
                                title="Additional Instructions"
                                instructionsMarkdown={props.section.instructionsMarkdown}
                            />
                        </PanelWrapper>
                    )}
                </PanelContainer>
            </SectionFormWrapper>
        </ScrollablePanelBody>
    );
};

const SectionFormWrapper = styled.div`
    display: flex;
  `;

const PanelWrapper = styled.div`
    flex: row;
    flex: 1;
  `;

const CustomButton = styled(Button)`
    margin-top: 0px;
  `;

const panelContainerStyle = css`
    display: flex;
  `;

const selectField = css`
    padding-top: 16px;
  `;

const checkboxField = css`
    padding-left: 16px;
    .tooltip {
      margin-top: 3px;
    }
  `;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    margin: 0 0 32px 18px;
    ${breakpoint_small} {
      justify-content: flex-end;
    }
  `;

const setupSectionContainer = css`
    padding: 32px;
    h3 {
      margin-bottom: 16px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
    }
    ${breakpoint_small} {
      padding: 16px;
      h3 {
        margin-bottom: 0px;
      }
    }
  `;

const label = css`
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    margin: 8px 16px;
  `;

const switchContainer = css`
    margin-bottom: 32px;
  `;

const setupPropertyContainer = css`
    padding: 16px 0px;
    position: relative;
    .field-triangle {
      position: absolute;
      width: 24px;
      height: 24px;
      background: ${color_shades_lighter};
      right: -44px;
      top: 64px;
      transform: rotate(45deg);
      border-left: 1px solid ${color_shades_dark};
      border-bottom: 1px solid ${color_shades_dark};
    }
  `;

export default AppliedApplicationTemplateSectionForm;

