import React from 'react';


interface CollapsingPanelProps {
    isCollapsed: boolean
    collapsedView: JSX.Element;
    expandedView: JSX.Element;
}

const CollapsingPanel: React.FC<CollapsingPanelProps> = (props) => (
    <div>
        {props.isCollapsed ? props.collapsedView : props.expandedView}
    </div>
);



export default CollapsingPanel;