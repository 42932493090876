import React from 'react';
import { css } from '@emotion/css';
import { Subscribe } from 'unstated';
import AuthContainer from '../../state/containers/AuthContainer';
import OrganizationContainer from '../../state/containers/OrganizationContainer';
import { Redirect } from 'react-router';

const logo = require('../../content/images/logo.svg');

const landingImage = css`
    height: 100px;
    margin: 42px 42px 56px 120px;
`
const containerStyle = css`
background: linear-gradient(to bottom, #f7f7f7, #eef0f1);
width: 100%;
height: 100%;
`
class LandingContainer extends React.Component {

    render() {
        return (

            <div className={containerStyle}>
                <img src={logo} className={landingImage} />
                {this.props.children}
            </div>
        )
    }
}

export default LandingContainer;