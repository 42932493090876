import React from 'react';
import { css } from '@emotion/css';
import Scrollbars, { ScrollbarProps } from 'react-custom-scrollbars';

const CustomScrollbars: React.FC<ScrollbarProps> = (props) => (
    <Scrollbars {...props} style={props.style} autoHide={false} className={`${scrollBarStyle} ${props.autoHide === true ? "auto-hide" : ""} ${props.className ?? ''}`}>
        {props.children}
    </Scrollbars>
);

const scrollBarStyle = css`   
    &.auto-hide > div:last-child {
        opacity: 0;
        transition: opacity 200ms ease;
    }                
    &.auto-hide:hover > div:last-child {
        opacity: 1;
    }
`;

export default CustomScrollbars;