import React from 'react';
import {css} from '@emotion/css';
import { color_shades_dark } from '../../constants/colors';
import Button from './Button';
const closeIcon = require('../../content/images/close_icon.svg');


interface ModalHeaderProps {
    title: string
    onClose: () => void
    className?: string
    
}

const ModalHeader: React.FC<ModalHeaderProps> = (props) => (
    <div className={props.className ? `${props.className} ${containerStyle}` : containerStyle}>
        <h2>{props.title}</h2>
        <Button themes={['end-tight', 'icon']} icon={closeIcon} onClick={props.onClose}/>
    </div>
);

const containerStyle = css`
    display: flex;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid ${color_shades_dark};
    h2 {
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        margin: 0 32px;
    }

    button {
        border-left: 1px solid ${color_shades_dark};
        border-radius: 0;
        padding: 0 32px;
        height: 48px;
        img {
            width: 24px;
            height: 24px;
        }
    }
`;

export default ModalHeader;