import React from 'react';
import { css } from '@emotion/css';
import { dark_grey_blue_10, cool_grey, silver_two, color_shades_darkest } from '../../constants/colors';



interface HeaderBarProps {

}
const HeaderBar: React.SFC<HeaderBarProps> = (props) => (
    <header className={headerStyle}>
        {props.children}
    </header>
);
const headerStyle = css`    
    display: flex;
    align-items: center;
    height: 80px;
    background: ${color_shades_darkest};
    color: white;
    top: 0;
    width: 100vw;
    
`;
export default HeaderBar; 