import React, { useState } from "react"
import { createContainer } from "unstated-next"
import IResult from "../models/result/IResult";
import * as voicifyApi from '../api';
import InteractionModel from "../models/interactionModel/api/InteractionModel";
import LanguageModel from "../models/features/api/LanguageModel";
import InteractionModelUpdateWithEntitiesRequest from "../models/interactionModel/api/InteractionModelUpdateWithEntitiesRequest";
import InteractionModelUpdate from "../models/interactionModel/api/InteractionModelUpdate";

function useInteractionModelContainer() {
    const [interactionModels, setInteractionModel] = useState([] as InteractionModel[]);
    const [errors, setErrors] = useState([] as string[]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentApplicationId, setCurrentApplicationId] = useState(null as string);
    
    const loadInteractionModels = async (applicationId: string, inLanguages: LanguageModel[]): Promise<IResult<InteractionModel[]>> => {
        try {
            setIsLoading(true);
            const promises = [];
            for (let i = 0; i < inLanguages.length; i++) {
                promises.push(voicifyApi.getInteractionModelByLocale(applicationId, inLanguages[i].shortCode));
            }
            const results = await Promise.all(promises);
            
            const newInteractionModels = [] as InteractionModel[];
            for (let i = 0; i < results.length; ++i) {
                const result = results[i];
                if(result.resultType != "Ok") {
                    setErrors([...errors, ...result.errors, "Unable to load Interaction Model"]);
                    setIsLoading(false);
                    return;
                }
                newInteractionModels.push(result.data);
            }

            setInteractionModel(newInteractionModels);
            setCurrentApplicationId(applicationId);
            setIsLoading(false);
            return {
                resultType: "Ok",
                data: newInteractionModels,
                errors: null
            };
        } catch (e) {
            setErrors([e?.toString(), "Unable to load Interaction Models"]);
            setIsLoading(false);
            return {
                resultType: "Invalid",
                data: null,
                errors: [e.toString()]
            };
        }
    }

    const updateNlpEntitiesAndInteractionModelForApplication = async (applicationId: string, request: InteractionModelUpdateWithEntitiesRequest): Promise<IResult<boolean>> => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.updateNlpEntitiesAndInteractionModelForApplication(applicationId, request);
            if (result?.resultType === "Ok") {
                setIsLoading(false);
                return result;
            } else {
                await setErrors([...errors, ...result.errors, "Unable to Update Application NLP Entities and Interaction Model"]);
            }
            setIsLoading(false);
        } catch (e) {
            setErrors([e?.toString(), "Unable to Update Application NLP Entities and Interaction Model"]);
            setIsLoading(false);
        }
    }

    const updateInteractionModel = async (applicationId: string, languageShortCode: string, request: InteractionModelUpdate): Promise<IResult<boolean>> => {
        try {
            setIsLoading(true);
            const result = await voicifyApi.commitUpdateToInteractionModel(applicationId, languageShortCode, request);
            if (result?.resultType === "Ok") {
                setIsLoading(false);
                return result;
            } else {
                await setErrors([...errors, ...result.errors, "Unable to Update Interaction Model"]);
            }
            setIsLoading(false);
        } catch (e) {
            setErrors([e?.toString(), "Unable to Update Interaction Model"]);
            setIsLoading(false);
        }
    }

    const clearErrors = () => {
        setErrors([]);
    }

    return {
        interactionModels,
        errors,
        isLoading,
        currentApplicationId,
        setErrors,
        clearErrors,
        loadInteractionModels,
        updateNlpEntitiesAndInteractionModelForApplication,
        updateInteractionModel
    };
}

const InteractionModelContainer = createContainer(useInteractionModelContainer);
export default InteractionModelContainer;
