import React from 'react';
import { css } from '@emotion/css'

const containerStyle = css`
display: flex;
flex-direction: row;
> div + * {
    margin-left: 16px;
}
.end {
    margin-right: 0;
    margin-left: auto;
}
&.center {
    align-items: center;
}
&.fill {
    width: 100%;
}
`

interface ContainerProps {
    className?: string
}
const HorizontalContainer: React.SFC<ContainerProps> = (props) => (
    <div className={`${containerStyle} ${props.className}`}>
        {props.children}
    </div>
)

export default HorizontalContainer;