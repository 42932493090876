import React from 'react';
import { color_colors_light_blue, color_colors_ocean, color_text_light } from '../../constants/colors';
import styled from '@emotion/styled';
import ListStat from '../../../shared/models/customAssistant/ListStat';
import SecondaryLoader from '../general/SecondaryLoader';
import { css } from '@emotion/css';
import TooltipWrapper, { TooltipWrapperProps } from '../general/TooltipWrapper';
import ConditionalTooltipWrapper from '../general/ConditionalTooltipWrapper';

interface DashboardCardProps {
    title: string
    titleIcon?: string
    description?: string
    taskView?: boolean
    taskComponent?: React.ReactNode
    numberStatView?: boolean
    listStatView?: boolean
    icon?: string
    numberStat?: number | string
    numberStatSignLeft?: string,
    numberStatSignRight?: string,
    listStat?: ListStat[]
    className?: string
    subtitle?: string
    singularCountType?: string
    pluralCountType?: string
    useBackgroundDesign?: boolean
    isLoading?: boolean
    month?: string
    noDataText?: string
    backgroundImage?: string
    backgroundImageStyle?: string
    toolTipText?: TooltipWrapperProps["text"]
    toolTipPlace?: TooltipWrapperProps["place"]
}

const DashboardCard: React.FC<DashboardCardProps> = (props) => {
    return (
        <DashboardCardWrapper className={props.className}>
            {props.isLoading ? (
                <CustomLoader className={customLoaderStyle} />
            ) : (
                <ConditionalTooltipWrapper
                    place={props.toolTipPlace}
                    text={props.toolTipText}
                    showTooltip={props.toolTipText ? true : false}
                >
                    <div>
                        {(props.useBackgroundDesign && props.backgroundImage) &&
                            <>
                                <ImageContainer>
                                    <DashboardCardImage className={props.backgroundImageStyle && props.backgroundImageStyle} src={props.backgroundImage} />
                                </ImageContainer>
                            </>
                        }

                        {props.taskView && (
                            <>
                                {props.titleIcon ? (
                                    <>
                                        <TitleWrapper>
                                            <TitleIcon src={props.titleIcon} />
                                            <ListViewTitle>{props.title}</ListViewTitle>
                                            <Description>{props.description}</Description>
                                        </TitleWrapper>
                                        {props.taskComponent}

                                    </>
                                ) : (
                                    <>
                                        <ListViewTitle>{props.title}</ListViewTitle>
                                        <Description>{props.description}</Description>
                                        {props.taskComponent}
                                    </>
                                )}
                            </>
                        )}

                        {props.numberStatView && (
                            <>
                                <StatViewTitleWrapper>
                                    <StatViewTitle>{props.title}</StatViewTitle>
                                    {props.subtitle && <StatViewSubtitle>{props.subtitle}</StatViewSubtitle>}
                                </StatViewTitleWrapper>
                                <NumberStat>{props.numberStatSignLeft}{props.numberStat}{props.numberStatSignRight}</NumberStat>
                            </>
                        )}

                        {props.listStatView && (
                            <>
                                <StatViewTitleWrapper>
                                    <StatViewTitle>{props.title}</StatViewTitle>
                                    {props.subtitle && <StatViewSubtitle>{props.subtitle}</StatViewSubtitle>}
                                </StatViewTitleWrapper>
                                <ListStatListWrapper>
                                    {props.listStat?.length > 0 ? (
                                        props.listStat.map((item: any, index: number) => {
                                            let countType = props.singularCountType;
                                            if (item?.count && item?.count > 1) {
                                                countType = props.pluralCountType;
                                            }
                                            return (
                                                <ListStatOuterWrapper key={index}>
                                                    <ListStatWrapper>
                                                        <TooltipWrapper
                                                            text={item?.name}
                                                        >
                                                            <Name>{item?.name}</Name>
                                                        </TooltipWrapper>
                                                        <Count>{item?.count}</Count>
                                                    </ListStatWrapper>
                                                    {countType ?
                                                        <CountType>{countType}</CountType> :
                                                        ''
                                                    }
                                                </ListStatOuterWrapper>
                                            )
                                        }
                                        )
                                    ) : (
                                        <NoDataText>
                                            {props.noDataText ? props.noDataText : "No data available."}
                                        </NoDataText>
                                    )}
                                </ListStatListWrapper>
                            </>
                        )}
                    </div>
                </ConditionalTooltipWrapper>
            )}
        </DashboardCardWrapper>
    )
}

const ImageContainer = styled.div`
    position: absolute;
    width: 25%;
    height: 100%;
    top: 0;
    left: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, ${color_colors_ocean}, ${color_colors_light_blue});
`;

const DashboardCardImage = styled.img`
    width: 50px;
    height: 50px;
`;

const customLoaderStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

const CustomLoader = styled(SecondaryLoader)`
    .spinner {
        margin: initial;
        width: 50px;
        height: 40px;
        text-align: center;
        font-size: 10px;
    }
    .spinner > div {
        background: ${color_colors_ocean};
    }
`;

const ListStatOuterWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const CountType = styled.p`
    font-size: 12px;
    margin-left: 8px;
`;

const StatViewTitleWrapper = styled.div`
    align-items: center;
    margin-bottom: 16px;
`;

const StatViewSubtitle = styled.p`
    font-size: 12px;
    color: ${color_text_light};
`;

const NoDataText = styled.p`
    color: ${color_text_light};
    width: 75%;
    text-wrap: wrap;
`;

const ListStatListWrapper = styled.div``;

const Name = styled.p`
    width: 85%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Count = styled.p`
    color: ${color_colors_ocean};
    font-weight: bold;
    font-size: 18px;
`;

const ListStatWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
`;

const NumberStat = styled.div`
    font-size: 36px;
    color: ${color_colors_ocean};
    font-weight: bold;
`;

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 24px;
    margin-bottom: 16px;
    border-bottom: 1px solid ${color_colors_ocean};
    background: linear-gradient(to right, ${color_colors_ocean}, ${color_colors_light_blue});
    color: white;
    border-radius: 8px 8px 0 0;
`;

const Description = styled.p`
    margin-left: 8px;
    text-wrap: wrap;
    font-weight: 100;
`;

const ListViewTitle = styled.h2`
    font-size: 18px;
`;

const StatViewTitle = styled.h2`
    font-size: 18px;
`;

const TitleIcon = styled.img`
    margin-right: 8px;  
    height: 24px;
    width: 24px;  
`;

const DashboardCardWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
    overflow: hidden;
`;

export default DashboardCard;
