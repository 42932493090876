import React from 'react';
import {css} from '@emotion/css';
import { color_shades_darker } from '../../../constants/colors';

const HeaderSeparator = () => (
    <div className={separatorStyle}/>
)

const separatorStyle = css`
    height: 48px;
    width: 1px;
    background: ${color_shades_darker};
`

export default HeaderSeparator;