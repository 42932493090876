import React from 'react';
import { css } from '@emotion/css';
import moment from 'moment';
import AnalyticsInterval from '../../models/analytics/AnalyticsInterval';
import { ocean_blue, cool_grey, dark_grey_blue_10, silver_three, silver_two, dark_grey_blue, color_shades_dark, color_shades_lighter, color_variants_ocean_light_2, color_text_default, color_text_light, color_colors_ocean, color_variants_ocean_light_1 } from '../../constants/colors';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Button from '../general/Button';
import DateInputField from './DateInputField';
import TimeIntervalPicker from './TimeIntervalPicker';
import ToggleIndicator from '../general/ToggleIndicator';
const datePickImage = require('../../content/images/analytics-icons/date-pick.svg');
interface TimeIntervalPickerProps {
    interval: AnalyticsInterval
    onChange?: (startDate: string, endDate: string, interval: AnalyticsInterval) => void;
    startDate?: string
    endDate?: string
}
interface TimeIntervalPickerState {
    interval: AnalyticsInterval
    temporalStart: string
    temporalEnd: string
    startDate?: string
    endDate?: string
}
const DATE_FORMAT = "YYYY-MM-DD hh:mm a";
class DateRangeIntervalPicker extends React.Component<TimeIntervalPickerProps, TimeIntervalPickerState> {
    constructor(props: TimeIntervalPickerProps) {
        super(props);
        this.state = {
            interval: props.interval,
            startDate: props.startDate,
            temporalStart: props.startDate,
            endDate: props.endDate,
            temporalEnd: props.endDate
        }
    }
    componentDidUpdate(prevProps: TimeIntervalPickerProps) {
        if (prevProps != this.props) {
            this.setState({
                interval: this.props.interval,
                startDate: this.props.startDate,
                temporalStart: this.props.startDate,
                endDate: this.props.endDate,
                temporalEnd: this.props.endDate
            })
        }
    }
    handleIntervalChange(interval) {
        var startDate = moment();
        var endDate = moment();

        // handle the non-moment intervals
        if (interval == 'custom') {
            // do nothing...
            startDate = moment(this.state.startDate, DATE_FORMAT);
            endDate = moment(this.state.endDate, DATE_FORMAT);
        }
        else if (interval == 'past 7 days') {
            startDate = moment().subtract(6, 'days'); // note the 6 and 29 is because it is inclusive of the current date by default
            endDate = moment();
        }
        else if (interval == 'past 30 days') {
            startDate = moment().subtract(29, 'days');
            endDate = moment();
        }
        else {
            startDate = startDate.startOf(interval);
            endDate = endDate.endOf(interval);
        }


        this.setState({
            ...this.state,
            interval,
            startDate: startDate.format(DATE_FORMAT),
            endDate: endDate.format(DATE_FORMAT),
            temporalStart: startDate.format(DATE_FORMAT),
            temporalEnd: endDate.format(DATE_FORMAT)
        })
    }
    handleDateRangeChange(ranges) {
        var startDate = moment(ranges.selection?.startDate);
        var endDate = moment(ranges.selection?.endDate);
        var diff = startDate.diff(endDate, "hours");
        if (diff == 0) {
            startDate = startDate.startOf('day');
            endDate = endDate.endOf('day');
        }

        this.setState({
            startDate: startDate.format(DATE_FORMAT),
            endDate: endDate.format(DATE_FORMAT),
            temporalStart: startDate.format(DATE_FORMAT),
            temporalEnd: endDate.format(DATE_FORMAT),
            interval: 'custom'
        })
    }
    handleConfirmDateChange() {
        this.props.onChange(moment(this.state.startDate, DATE_FORMAT).format(DATE_FORMAT), moment(this.state.endDate, DATE_FORMAT).format(DATE_FORMAT), this.state.interval)
    }
    handleTempStartChange(e) {
        this.setState({
            ...this.state,
            temporalStart: moment(e.target.value, 'YYYY-MM-DD').format(DATE_FORMAT)
        })
    }
    handleTempEndChange(e) {
        this.setState({
            ...this.state,
            temporalEnd: moment(e.target.value, 'YYYY-MM-DD').format(DATE_FORMAT)
        })
    }
    weekOfMonth(input = moment()) {
        const firstDayOfMonth = input.clone().startOf('month');
        const firstDayOfWeek = firstDayOfMonth.clone().startOf('week');

        const offset = firstDayOfMonth.diff(firstDayOfWeek, 'days');

        return Math.ceil((input.date() + offset) / 7);
    }
    getCurrentPeriodLabel() {
        // gets a dynamic label based on the interval and start/end dates
        const startDate = moment(this.state.startDate, DATE_FORMAT);
        if (this.state.interval == "year") {
            return `${startDate.year()} Selected`
        }
        if (this.state.interval == "month") {
            return `${startDate.format("MMMM")} ${startDate.year()} Selected`;
        }
        if (this.state.interval == "week") {
            return `Week ${this.weekOfMonth(startDate)} of ${startDate.format("MMMM")} ${startDate.year()} Selected`;
        }
        var daysBetween = moment(this.state.endDate, DATE_FORMAT).diff(moment(this.state.startDate, DATE_FORMAT), 'days') + 1; // +1 is to be inclusive of the selected date
        return `${daysBetween} Day${daysBetween > 1 ? 's' : ''} Selected`;
    }
    handleDateBlur(e) {
        var startDate = moment(this.state.temporalStart, DATE_FORMAT);
        var endDate = moment(this.state.temporalEnd,  DATE_FORMAT);
        var diff = endDate.diff(startDate, "hours");
        if (diff == 0) {
            startDate = startDate.startOf('day');
            endDate = endDate.endOf('day');
        }
        if (diff < 0) {
            // if they set the start to earlier, flip them
            this.setState({
                startDate: endDate.format(DATE_FORMAT),
                endDate: startDate.format(DATE_FORMAT),
                temporalEnd: startDate.format(DATE_FORMAT),
                temporalStart: endDate.format(DATE_FORMAT)
            })
        } else {
            this.setState({
                startDate: startDate.format(DATE_FORMAT),
                endDate: endDate.format(DATE_FORMAT),
                temporalEnd: endDate.format(DATE_FORMAT),
                temporalStart: startDate.format(DATE_FORMAT)
            })
        }
    }
    handlePreviousPeriod() {
        var startDate = moment(this.state.startDate, DATE_FORMAT);
        var endDate = moment(this.state.endDate, DATE_FORMAT);
        if (this.state.interval == "year" || this.state.interval == "month" || this.state.interval == "week" || this.state.interval == "day") {
            startDate = startDate.subtract(1, this.state.interval);
            endDate = endDate.subtract(1, this.state.interval);
            if(this.state.interval == "month") {
                endDate = endDate.endOf('month'); // without this, shuffling between months with 30 and 31 days will exlcude the 31st
            }
        }
        else {
            var hoursBetween = endDate.diff(startDate, 'hours');
            startDate = startDate.subtract(hoursBetween > 0 ? hoursBetween : 24, 'hours');
            endDate = endDate.subtract(hoursBetween > 0 ? hoursBetween : 24, 'hours');
        }
        this.setState({
            ...this.state,
            startDate: startDate.format(DATE_FORMAT),
            endDate: endDate.format(DATE_FORMAT),
            temporalEnd: endDate.format(DATE_FORMAT),
            temporalStart: startDate.format(DATE_FORMAT)
        })
    }
    handleNextPeriod() {
        var startDate = moment(this.state.startDate, DATE_FORMAT);
        var endDate = moment(this.state.endDate, DATE_FORMAT);
        if (this.state.interval == "year" || this.state.interval == "month" || this.state.interval == "week") {
            startDate = startDate.add(1, this.state.interval);
            endDate = endDate.add(1, this.state.interval);
            if(this.state.interval == "month") {
                endDate = endDate.endOf('month'); // without this, shuffling between months with 30 and 31 days will exlcude the 31st
            }
        }
        else {
            var daysBetween = endDate.diff(startDate, 'days');
            startDate = startDate.add(daysBetween > 0 ? daysBetween : 1, 'days');
            endDate = endDate.add(daysBetween > 0 ? daysBetween : 1, 'days');
        }
        this.setState({
            ...this.state,
            startDate: startDate.format(DATE_FORMAT),
            endDate: endDate.format(DATE_FORMAT),
            temporalEnd: endDate.format(DATE_FORMAT),
            temporalStart: startDate.format(DATE_FORMAT)
        })


    }

    render() {
        const selectionRange = {
            startDate: moment(this.state.startDate, DATE_FORMAT).toDate(),
            endDate: moment(this.state.endDate, DATE_FORMAT).toDate(),
            key: 'selection',
            showDateDisplay: false,
            color: color_variants_ocean_light_2
        }
        return (
            <div className={containerStyle}>
                <div className="triangle"></div>
                <div className="selection-container">
                    <div className="date-picker-header">
                        <div className="field-wrapper ac-analytics-custom-date-input">
                            <DateInputField value={moment(this.state.temporalStart, DATE_FORMAT).format("YYYY-MM-DD")} onChange={this.handleTempStartChange.bind(this)} onBlur={this.handleDateBlur.bind(this)} />
                            <div className="field-separator"></div>
                            <DateInputField value={moment(this.state.temporalEnd, DATE_FORMAT).format("YYYY-MM-DD")} onChange={this.handleTempEndChange.bind(this)} onBlur={this.handleDateBlur.bind(this)} />
                        </div>
                        <button className="period-toggle-button left" onClick={this.handlePreviousPeriod.bind(this)}>
                            <ToggleIndicator direction="left" />
                        </button>
                        <button className="period-toggle-button right" onClick={this.handleNextPeriod.bind(this)}>
                            <ToggleIndicator direction="right" />
                        </button>
                        <p className="period-label ac-analytics-selected-period">
                            {this.getCurrentPeriodLabel()}
                        </p>
                        <Button themes={['primary-small', 'end-tight']} className="confirm-button ac-analytics-set-date-button" type="button" text="Update Timeframe" onClick={this.handleConfirmDateChange.bind(this)} />
                    </div>
                    <div className="date-interval-container">
                        <TimeIntervalPicker interval={this.state.interval} onChange={this.handleIntervalChange.bind(this)} />
                        <DateRangePicker
                            ranges={[selectionRange]}
                            onChange={this.handleDateRangeChange.bind(this)}
                            months={2}
                            direction="horizontal"
                            className="date-picker ac-analytics-calendar-picker" />
                    </div>
                </div>
            </div>
        )
    }
}

const containerStyle = css`
    display: flex;
    position: absolute;
    margin: 12px 288px;
    background: ${color_shades_lighter};
    border: 1px solid ${color_shades_dark};
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(50, 70, 97, 0.1);
    z-index: 8;
    .triangle {
        transform: rotate(45deg);
        position: absolute;
        width: 12px;
        height: 12px;
        margin-top: -7px;
        margin-bottom: -6px;
        border-top: solid 1px ${color_shades_dark};
        border-left: solid 1px ${color_shades_dark};
        background: ${color_shades_lighter};
        margin-left: 72px;
        border-radius: 2px;
        z-index: 5;
    }
    .pick-container {
        margin: 24px 0;
        border-right: 1px solid ${color_shades_dark};
    }
    .selection-container {
        padding: 24px 32px;
        .date-picker-header {
            display: flex;
            align-items: center;
            padding-bottom: 24px;
            border-bottom: 1px solid ${color_shades_dark};
            .confirm-button {
                margin-top: 0;
                margin-bottom: 0;
            }
            .field-wrapper {
                display: flex;
                align-items: center;
                padding-right: 16px;
                border-right: 1px solid ${color_shades_dark};
                .field-separator {
                    background: ${color_shades_dark};
                    height: 1px;
                    width: 8px;
                    margin: 12px;
                }
            }
            .period-toggle-button {
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #FFFFFF;
                border: 1px solid ${color_shades_dark};
                box-sizing: border-box;
                border-radius: 8px;
                cursor: pointer;
                &.left {
                    margin-left: 16px;
                }
                &.right {
                    margin-left: 8px;
                }
            }
            .period-label {
                margin: 0 16px;
                font-size: 16px;
                line-height: 24px;
            }
        }
        .date-interval-container {
            display: flex;
        }
        .date-picker {
            font-size: 14px;
            flex: 1;
            div {
                background: transparent;
            }
            .rdrDay {
                &.rdrDayPassive {
                    > span > span {
                        color: ${color_text_light};
                    }
                }
                &:not(.rdrDayPassive) {
                    .rdrStartEdge + span, .rdrEndEdge + span {
                        display:none;
                    }
                    .rdrStartEdge ~ .rdrDayNumber, .rdrEndEdge ~ .rdrDayNumber  {
                        display: flex;
                        > span {
                            color: white;
                            background: ${color_colors_ocean};
                            border-radius: 16px;
                            width: 36px;
                            height: 24px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }               
            }
            .rdrDayNumber {
                 > span {
                    color: ${color_text_default};
                 }
            }
            .rdrCalendarWrapper, .rdrDefinedRangesWrapper {
                font-size: 14px;
                font-family: Muli;
            }
            .rdrDateDisplayWrapper, .rdrInputRanges, .rdrDefinedRangesWrapper {
                display: none;
            }
        }
    }

`

export default DateRangeIntervalPicker;