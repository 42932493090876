import React from 'react';
import {css} from '@emotion/css';

// this is the layout that replaces the dashboard navigation for full screen pages
// such as creating / editing content items, templates, etc.

const fullPageStyle = css`
    width: 100%;
    height: 100%;
    background:white;
    position: fixed;
    display: flex;
    flex-direction: column;
`
interface FullScreenLayoutProps {
    className?: string
}
const FullScreenLayout: React.SFC<FullScreenLayoutProps> = (props) => (
    <div className={`${fullPageStyle} ${props.className ? props.className : ''}`}>
        {props.children}
    </div>
);

export default FullScreenLayout;