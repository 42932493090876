import React from 'react';
import {css} from '@emotion/css';
import { dark_grey_blue } from '../../constants/colors';

const titleStyle = css`
font-family: Muli;
font-size: 24px;
color: ${dark_grey_blue}; 
font-weight: normal;
font-style: normal;
font-stretch: normal;
line-height: normal;
letter-spacing: normal;
margin: 26px 0;
`
const paddedStyle = css`
    margin: 26px;
`
interface ModalTitleProps {
    padded?: boolean
}

const ModalTitle: React.SFC<ModalTitleProps> = (props) => (
    <h2 className={`${titleStyle} ${props.padded ? paddedStyle : ''}`}>
    {props.children}
    </h2>
)

export default ModalTitle;