import React from 'react';
import {css} from '@emotion/css';
import { dark_grey_blue } from '../../constants/colors';

const cellStyle = css`
    font-family: Muli;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color:${dark_grey_blue};
    display: flex;
    align-items: center;
`;

interface TableCellProps {
    className?: string
}
const TableCell: React.SFC<TableCellProps> = (props) => (
    <div className={`${cellStyle} ${props.className ? props.className : ''}`}>
        {props.children}
    </div>
)

export default TableCell