import React from 'react';
import { css } from '@emotion/css';
import { pale_grey, silver_three, dark_grey_blue, cool_grey } from '../../constants/colors';


interface SwitchProps {
    checked?: boolean
    name?: string
    disabled?: boolean
    onChange?: (checked: boolean) => void
    label?: string
    detail?: string
    id?: string
    tooltip?: string
    className?: string
    leftOption?: string
    icon?: string
}

const Switch: React.FC<SwitchProps> = (props) => (
    <div className={`${containerStyle} ${props.className ? props.className : ''} ${props.disabled ? 'disabled' : ''}`} onClick={props.disabled ? () => { } : () => props.onChange(!props.checked)}>
        {props.leftOption &&
            <div className="label-container left-label">
                <p className="primary-label">
                    {props.leftOption}
                </p>
            </div>
        }
        {props.icon &&
            <div className="label-container left-label">
                <img src={props.icon} />
            </div>
        }
        <div className={`switch ${props.checked ? 'switch-on' : 'switch-off'}`}>
            <div className="switch-container">
            </div>
            <div className="switch-indicator">
            </div>
        </div>
        {(props.label || props.detail) &&
            <div className="label-container">
                <p className="primary-label">
                    {props.label}
                </p>
                {props.detail ?
                    <span className="secondary-label">
                        {props.detail}
                    </span> : null}
            </div>
        }
    </div>
);

const containerStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .switch {
        width: 48px;
        height: 26px;
        position: relative;

        .switch-container {
            border-radius: 13px;
            height: 100%;
            width: 100%;
            position: absolute;
        }

        .switch-indicator {
            width: 25px;
            height: 25px;
            background-color: #ffffff;
            border-radius: 50%;
            border: 1px solid ${silver_three};
            position: absolute;
        }

        &.switch-off {
            .switch-container {
                background: ${silver_three};
            }
            .switch-indicator {
                margin-left: 0;
                transition: margin 200ms;
            }
        }
        &.switch-on {
            .switch-container {
                background: linear-gradient(242deg, #76c3b5, #3ea6ac 72%, #5296b3);
            }

            .switch-indicator {
                margin-left: 25px;
                transition: margin 200ms;
            }
        }
    }

    .label-container {
        margin-left: 12px;
        .primary-label {
            font-family: Muli;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: ${dark_grey_blue};
        }
        .secondary-label {     
            font-family: Muli;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: ${cool_grey};
        }
        &.left-label {
            margin-left: 0px;
            margin-right: 12px;
        }
        &.right-container {
            margin-left: 32px;
        }
    }

    &.disabled {
        cursor: not-allowed;

        .switch {
            .switch-container {
                background: linear-gradient(242deg, #c9c9c9, #a8a8a8 63%, #929292);
            }
            .switch-indicator {
                background: ${pale_grey};
            }
        }
    }
`

export default Switch;