import React from 'react';
import { css } from '@emotion/css';
import Button from './Button';
const closeIcon = require('../../content/images/close-white.svg')

interface BackProps {
    onClick: () => void
}
const BackButton: React.SFC<BackProps> = (props) => (
    <Button themes={['inverse']}  onClick={props.onClick} text="Close" icon={closeIcon} additionalClasses={backStyle}/>
);

const backStyle = css`
    width: 160px
`

export default BackButton;