import React from 'react';
import { css } from '@emotion/css';
import { dark_grey_blue, ocean_blue } from '../../../constants/colors';
import { OverflowOption } from './OverflowMenu';

interface MenuItemProps {
    option: OverflowOption
    className?: string
    onClick?: () => void
}

const MenuSubItem: React.SFC<MenuItemProps> = (props) => (
    <div className={`${menuItemStyle} ${props.option.isDestructive ? destructiveStyle : defaultStyle} ${props.className}`} onClick={props.onClick}>
        {props.option.icon ? <img className="icon" src={props.option.icon} /> : null}
        {props.option.hoverIcon ? <img className="icon-hover" src={props.option.hoverIcon} /> : null}
        <p>
            {props.option.label}
        </p>
    </div>
);

const menuItemStyle = css`
    height: 64px;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    z-index: 99;
    color: ${dark_grey_blue};
    display: flex;
    align-items: center;
    text-align: right;
    cursor: pointer;
    border: 1px solid transparent;
    position: relative;
    padding-left: 24px;
    p {
        margin: 0 12px;
    }
    img {
        width: 24px;
        height: 24px;
        margin-left: 16px;
    }

    .icon-hover {
        display: none;
    }

    &:hover {
        .icon-hover {
            display: block;
        }
        .icon {
            display: none;
        }
    }
`

const destructiveStyle = css`
    &:hover {
        background-color: rgba(221, 64, 74, 0.1);
        border: solid 1px #eb5862;
    }
`;

const defaultStyle = css`
    &:hover {
        background-color: #E5F1F6;
        border: 1px solid ${ocean_blue};
    }
`;

export default MenuSubItem;