import React, { useEffect } from 'react';
import { css } from '@emotion/css';
import { color_text_inverse, color_shades_light } from '../../constants/colors';
import { Link } from 'react-router-dom';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import { BreadcrumbItem } from '../general/Breadcrumb';
import styled from '@emotion/styled';
import { breakpoint_small } from '../../constants/breakpoints';
import SparkContainer from '../../hooks/SparkContainer';

const separatorIcon = require('../../content/images/content-explorer/breadcrumb-separator-white.svg');

interface HeaderTitleViewProps {
    text?: string
    breadcrumb?: BreadcrumbItem[]
    app?: ApplicationModel
    titleImage?: string
}

const HeaderTitleView: React.FC<HeaderTitleViewProps> = (props) => {

    const sparkContainer = SparkContainer.useContainer();
    
    return (
        <div key={props.app?.id} className={titleStyle}>
            <div>
                <div className={breadcrumbContainer}>
                    {props.breadcrumb !== undefined && props.breadcrumb !== null && props.breadcrumb.length > 0 ?
                        props.breadcrumb.map((b, i) => (
                            <div key={i}>
                                <Link to={b.link}>
                                    <span>
                                        {b.title}
                                    </span>
                                </Link>
                                {i < props.breadcrumb.length - 1 ? <img src={separatorIcon} /> : null}
                            </div>
                        )) : null
                    }
                </div>
                <MainTitle>
                    {props.titleImage && <div className="title-image-border ac-header-title-image"><img src={props.titleImage} /></div>}
                    <MainTitleText className={sparkContainer.inSpark ? 'spark-title' : 'ac-title-image'}>{props.text}</MainTitleText>
                </MainTitle>
            </div>
        </div>)
};

const MainTitle = styled.div`
    display: flex;
    flex-direction: row;
    .title-image-border {
        width: 24px;
        height: 24px;
        margin: 6px 8px 0px 0px;
        border: 1px solid white;
        border-radius: 8px;
        box-sizing: border-box;
        text-align: center;
        img {
            height: 17px;
            margin-top: 2px;
        }
    }
`;

const MainTitleText = styled.div`
    font-size: 30px;
    &.spark-title {
        ${breakpoint_small} {
            display: none;
            font-size: 0px;
        }
    }
`;

const titleStyle = css`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 13px 0;
    margin: 0 32px;
    height: 58px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    h1 {
        font-family: Muli, "sans-serif";
        font-size: 30px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: ${color_text_inverse};
        word-wrap: normal;
        text-overflow: ellipsis;
    }

    .title-avatar {   
        margin-right: 12px; 
    }

    span {
        font-family: Muli;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
    }
`;

const breadcrumbContainer = css`
    display: flex;
    align-items: center;
    a {
        color: ${color_shades_light};
        margin: 0 4px;
        &:hover{
            span {
                color: white;
            }
        }
    }
    img {
        margin: 0 10px;
    }
`;

export default HeaderTitleView;