import IResult from "../../models/result/IResult";
import OrganizationModel from "../../models/organizations/api/OrganizationModel";
import { sendDelete, sendGet, sendPost } from "./generic";
import { ACCEPT_INVITATION } from "../../constants/Urls";
import BasicInvitationRequest from "../../models/organizations/api/BasicInvitationRequest";
import InvitationModel from "../../models/organizations/api/InvitationModel";

export const acceptInvitation = (invitationId: string) : Promise<IResult<OrganizationModel>> => {
    return sendPost<OrganizationModel>(`${ACCEPT_INVITATION}/${invitationId}`, null);
}

export const sendInvitation =(organizationId: string, model: BasicInvitationRequest) => {
    return sendPost<InvitationModel>(`/invitation/${organizationId}`, model);
}

export const getInvitations =(organizationId: string): Promise<IResult<InvitationModel[]>> => {
    return sendGet<InvitationModel[]>(`/invitation/${organizationId}`);
}

export const revokeInvitation =(invitationId: string): Promise<IResult<InvitationModel>> => {
    return sendDelete<InvitationModel>(`/invitation/${invitationId}`);
}