import React from 'react';
import { color_variants_main_bg } from '../../../constants/colors';
import styled from '@emotion/styled';

interface BodyContainerPanelProps {
    children: any
}

const BodyContainerPanel: React.FC<BodyContainerPanelProps> = (props) => (
    <BodyContainerPanelWrapper>
        {props.children}
    </BodyContainerPanelWrapper>
)

const BodyContainerPanelWrapper = styled.div`
    height: calc(100% - 80px);
    position: fixed;
    top: 80px;
    width: 100vw;
    background: ${color_variants_main_bg};
`;

export default BodyContainerPanel;