import React from 'react';
import { css } from '@emotion/css';
import { silver_two, dark_grey_blue } from '../../constants/colors';
import HorizontalSeparator from './HorizontalSeparator';
import Loader from '../general/Loader';
import styled from '@emotion/styled';

interface SettingsCardProps {
    title?: string
    icon?: string
    bodyStyle?: string
    isLoading?: boolean
}

const SettingsCard: React.FC<SettingsCardProps> = (props) => {
    return (
        <>
            {props.isLoading ?
                <LoaderWrapper>
                    <StyledLoader />
                </LoaderWrapper>
                :
                <div className={cardStyle}>

                    {props.title !== undefined ? (
                        <>
                            <div className={titleStyle}>
                                {props.icon !== undefined ? <img src={props.icon} alt="icon" /> : null}
                                <h3>{props.title}</h3>
                            </div>
                            <HorizontalSeparator />
                        </>
                    ) : null}
                    <div className={props.bodyStyle ? props.bodyStyle : bodyStyle}>
                        {props.children}
                    </div>
                </div>
            }
        </>
    );
};

const LoaderWrapper = styled.div`
    height: 100%;
`;

const StyledLoader = styled(Loader)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const cardStyle = css`
    border-radius: 10px;
    background-color: #ffffff;
    border: solid 1px ${silver_two};
    margin-bottom: 32px;
    flex-grow: 0;
    font-family: Muli;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${dark_grey_blue};
    h4 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 24px;
    }
    .section {
        padding: 32px 0;
    }
`;

const titleStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
    padding: 32px 32px 12px 32px;
    
    > h3 {
        font-size: 23px;
        margin: 0 10px;
    }

`;

const bodyStyle = css`
    padding: 32px;
`;

export default SettingsCard;