import React from 'react';
import { css } from '@emotion/css';
import AuthContainer from '../../../state/containers/AuthContainer';
import { withFormik, FormikProps } from 'formik';
import * as yup from 'yup';
import TextField from '../../../components/forms/TextField';
import Button from '../../../components/general/Button';
import UserContainer from '../../../state/containers/UserContainer';
import { Link } from 'react-router-dom';
import PageError from '../../../components/general/PageError';

interface LoginFormData {
    email?: string;
    password?: string;
}

interface LoginFormProps {
    stateContainer: AuthContainer;
    userContainer: UserContainer;
    invitationCode?: string;
}

const InnerForm: React.FC<LoginFormProps & FormikProps<LoginFormData>> = ({ handleSubmit, isSubmitting, values, handleChange, handleBlur, stateContainer, userContainer }) => (
    <div>
        <form onSubmit={handleSubmit}>
            <TextField disabled={isSubmitting} name="email" value={values.email} label="Email" placeholder="Enter the email you signed up with" onChange={handleChange} onBlur={handleBlur} />
            <TextField disabled={isSubmitting} name="password" type="password" value={values.password} label="Password" placeholder="Just to be sure it’s really you" onChange={handleChange} onBlur={handleBlur} />
            <Link to="forgotPassword" className="forgot-password">Forgot Password?</Link>
            <Button themes={["primary", "wide"]} type="submit" loading={isSubmitting} disabled={isSubmitting} text="Let's Go!" additionalClasses={buttonSplitStyle} />
        </form>
        <PageError errors={[...stateContainer.state.errors, ...userContainer.state.errors]} />
    </div>
);

const LoginForm = withFormik<LoginFormProps, LoginFormData>({
    mapPropsToValues: props => ({ email: '', password: '' }),
    validationSchema: yup.object().shape({
        email: yup.string().email("Must be a valid email").required("You must enter your email"),
        password: yup.string().required("You must enter your password")
    }),
    handleSubmit: async (values, { props, setSubmitting }) => {
        setSubmitting(true);
        const result = await props.stateContainer.signIn(values.email, values.password)
        setSubmitting(false);
        if (result.resultType == "Ok" && props.invitationCode !== undefined) {
            props.userContainer.acceptInvitation(props.invitationCode);
        }
    }
})(InnerForm);

const buttonSplitStyle = css`
    margin-bottom: -56px;
    margin-top: 72px;
`

export default LoginForm;