import React from "react";
import { Provider, Subscribe } from 'unstated';
import MemberContainer from "../../state/containers/MemberContainer";
import OrganizationMemberListView from "./components/OrganizationMemberListView";
import { RouteComponentProps } from "react-router";
import OrganizationContainer from "../../state/containers/OrganizationContainer";
import PageContainer from "../../components/structure/PageContainer";
import TabPanelBody from "../../components/structure/TabPanelBody";
import ApplicationContainer from "../../state/containers/ApplicationContainer";
import UserContainer from "../../state/containers/UserContainer";
import AuthContainer from "../../state/containers/AuthContainer";

interface OrganizationmembersProps {
    history: any
}
class OrganizationMembers extends React.Component<OrganizationmembersProps> {
    render() {
        return (
            <Subscribe to={[MemberContainer, OrganizationContainer, ApplicationContainer, UserContainer]}>
                {(memberContainer: MemberContainer,
                    orgContainer: OrganizationContainer,
                    appContainer: ApplicationContainer,
                    userContainer: UserContainer,
                    authContainer: AuthContainer
                ) => {

                    return (
                        <OrganizationMemberListView
                            history={this.props.history}
                            memberContainer={memberContainer}
                            organizationId={orgContainer.state.currentOrganization.id}
                            appContainer={appContainer}
                            userContainer={userContainer}
                            organizationContainer={orgContainer}
                            authContainer={authContainer}
                        />
                    );
                }}
            </Subscribe>
        );
    }
};

export default OrganizationMembers;
