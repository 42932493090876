import React from 'react';
import { css } from '@emotion/css';
import { dark_grey_blue } from '../../constants/colors';

const redAlertTriangle = require('../../content/images/alert-triangle-red.svg');
const yellowAlertTriangle = require('../../content/images/alert-triangle-yellow.svg');

interface WarningConfirmationProps {
    text?: string
    showImage?: boolean
    removalTarget?: string
    useWarningColor?: boolean
    className?: string
}

const WarningConfirmation: React.FC<WarningConfirmationProps> = (props) => (
    <div className={`${containerStyle} ${props.className && props.className}`}>
        <p>Are you sure?</p>
        {(props.showImage === undefined || props.showImage === true) ? <img src={props.useWarningColor ? yellowAlertTriangle : redAlertTriangle} /> : null}
        <p>{props.text}</p>       
    </div>
)

const containerStyle = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 64px;
    img {
        width: 82px;
        height: 82px;
        margin: 32px 0;
    }
    p {
        font-family: Muli;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        text-align: center;
        color: ${dark_grey_blue};
    }
`;

export default WarningConfirmation;