import React from "react";
import Tooltip from "rc-tooltip";
import { css } from "@emotion/css";
import "rc-tooltip/assets/bootstrap.css";
import DOMPurify from "dompurify";

export interface TooltipWrapperProps {
    text?: string;
    className?: string;
    place?: 'left' | 'right' | 'top' | 'bottom' | 'topLeft' | 'topRight' |
    'bottomLeft' | 'bottomRight' | 'rightTop' | 'rightBottom' | 'leftTop' | 'leftBottom';
    replacementContentStyle?: string;
}

const TooltipWrapper: React.FC<TooltipWrapperProps> = (props) => (
    <Tooltip
        overlayClassName={
            props.replacementContentStyle
                ? props.replacementContentStyle
                : tooltipContentStyle + " " + props.className
        }
        placement={props.place === undefined ? "top" : props.place}
        trigger={["hover"]}
        overlay={
            <span
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.text) }}
            ></span>
        }
    >
        {props.children}
    </Tooltip>
);

const tooltipContentStyle = css`
  .rc-tooltip-inner {
    font-family: Muli;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0.6px;
    z-index: 120;
    color: white;
    background-color: #304562;
    box-shadow: -1px 4px 10px 0 rgba(48, 69, 98, 0.1);
    padding: 8px;
    min-height: 0;
  }
`;

export default TooltipWrapper;
