// constants for keys
export const VOICIFY_CLIENT_ID = "d3fd0f4d-89ef-4bfd-bc67-14b09f4af2f8";
export const VOICIFY_ACCESS_TOKEN_STORE = "VOICIFY_ACCESS_TOKEN";
export const VOICIFY_REFRESH_TOKEN_STORE = "VOICIFY_REFRESH_TOKEN";
export const VOICIFY_EMAIL_STORE = "VOICIFY_USER";
export const VOICIFY_ACCESS_EXPIRATION = "VOICIFY_ACCESS_EXPIRATION";
export const VOICIFY_NLP_MODEL_TRAINING_STATE = "VOICIFY_NLP_MODEL_TRAINING_STATE";
export const VOICIFY_SPARK_DEPLOYMENT_STATE = "VOICIFY_SPARK_DEPLOYMENT_STATE";
export const ANALYTICS_ORDER_STATE = "ANALYTICS_ORDER_STATE";

export const getVoicifyClientSecret = (): string => {
    if (process.env.VOICIFY_ENVIRONMENT == "dev") {
        return "NWU0YTMwYzktOWI2Ni00YTY0LTlkNzUtMmFhZmM1MjFiYjdjLWVmMzJlMTAwLTZhM2QtNGNjOS04NzdhLTZhYTllMmFiOWQ2Mw==";
    }
    else if (process.env.VOICIFY_ENVIRONMENT == "staging") {
        return "NWU0YTMwYzktOWI2Ni00YTY0LTlkNzUtMmFhZmM1MjFiYjdjLWVmMzJlMTAwLTZhM2QtNGNjOS04NzdhLTZhYTllMmFiOWQ2Mw==";
    }
    else if (process.env.VOICIFY_ENVIRONMENT == "production") {
        return "MjcyY2VjMWUtMWVhYi00ZmJjLTk3Y2QtNTJmMDcxM2RiYTE2LTk4OGNjMWQ2LTQ1OWItNDkyMy1iNTAwLWI2OTRkZjgxMTc3ZA==";
    }

    return null;
};

export const SUPPORT_APPLICATION_ID = "dd805001-e1bf-4107-8e19-1b9ec4db575d"; 
export const SUPPORT_APPLICATION_SECRET = "NjJlYWVhNTYtZWQwMy00MDBhLTlkYmYtZTVjNDhmZjEzMjlk";

